import { initialPagination } from 'data/constants'
import useFilters, { objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'components/modular/Button'
import { Checkbox } from 'components/modular/Checkbox'
import { DataTable } from 'components/modular/DataTable'
import { useEffect, useMemo, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'
import ModalFormUsuario from './components/ModalFormUsuario'
import ModalAssignRoles from './components/ModalAssignRoles'
import ModalFondoRotatorio from './components/ModalFondoRotatorio'
import ModalFondoAvance from './components/ModalFondoAvance'

const filterOptions = { contain: ['username', 'email', 'apellidos', 'nombres'], bool: ['activo'] }

const activoTemplate = ({ value, filterCallback }) => (
    <div className='d-flex justify-content-center'>
        <Checkbox
            value={value}
            onChange={(e) => filterCallback(e.target.value)}
            style={{ fontSize: '1.5rem' }}
            triState
        />
    </div>
)

export default function ListUsuario() {
    const { showHttpToast } = useHttpToast()
    const { filters, handlePage, handlePageSize, handleSort, handleFilter, reloadFilters } = useFilters({
        filterOptions,
    })
    const [usuarios, setUsuarios] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selected, setSelected] = useState(null)
    const [isVisible, setIsVisible] = useState({
        modalForm: false,
        modalAssign: false,
        modalRotatorio: false,
        modalAvance: false,
    })

    useEffect(() => {
        const loadUsuarios = async () => {
            const params = objectToQueryString(filters)
            const { status, data } = await ContabilidadServices.usuario.list(params)
            if (status === 200) {
                setUsuarios(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadUsuarios()
    }, [filters])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData = null) => {
        if (action === 'create') {
            show('modalForm')
        } else if (action === 'update') {
            const rol = rowData.roles.includes('AdministradorContabilidadDA')
                ? 'AdministradorContabilidadDA'
                : rowData.roles.includes('AdministradorPresupuestos')
                ? 'AdministradorPresupuestos'
                : rowData.roles.includes('ReportesPresupuestosDA')
                ? 'ReportesPresupuestosDA'
                : ''
            const roles = {
                beneficiario_fondo_rotatorio: rowData.roles.includes('BeneficiarioFondoRotatorio'),
                beneficiario_fondo_avance: rowData.roles.includes('BeneficiarioFondoAvance'),
            }
            setSelected({
                ...rowData,
                actividad: rowData.codigo_actividad ?? '',
                rol,
                ...roles,
            })
            show('modalForm')
        } else if (action === 'assign') {
            setSelected(rowData)
            show('modalAssign')
        } else if (action === 'rotatorio') {
            setSelected({ id: rowData.id })
            show('modalRotatorio')
        } else if (action === 'avance') {
            setSelected({ id: rowData.id })
            show('modalAvance')
        } else if (action === 'toggleActive') {
            handleToggleActive(rowData)
        }
    }

    const optionsTemplate = ({ data }) => (
        <div className='text-center text-nowrap'>
            <Button
                onClick={() => handleAction('toggleActive', data)}
                variant={data.activo ? 'danger' : 'success'}
                size='sm'
                className='me-2'
                title={data.activo ? 'Inhabilitar' : 'Habilitar'}
            >
                <span className={data.activo ? 'bi-x-circle' : 'bi-check-circle'} />
            </Button>
            <Button onClick={() => handleAction('update', data)} size='sm' className='me-2' title='Editar'>
                <span className='bi-pencil' />
            </Button>
            {/* <Button onClick={() => handleAction('assign', data)} size='sm' className='me-2' title='Editar roles'>
                <span className='bi-person-gear' />
            </Button> */}
            <Button
                onClick={() => handleAction('rotatorio', data)}
                variant='success'
                size='sm'
                className='me-2'
                disabled={!data.actividad_id || !data.roles.includes('BeneficiarioFondoRotatorio')}
                title='Fondo Rotatorio'
            >
                F. Rotatorio
            </Button>
            <Button
                onClick={() => handleAction('avance', data)}
                variant='success'
                size='sm'
                disabled={!data.actividad_id || !data.roles.includes('BeneficiarioFondoAvance')}
                title='Fondo en Avance'
            >
                F. Avance
            </Button>
        </div>
    )
    const columns = useMemo(
        () => [
            { field: 'email', header: 'Correo', sortable: true, filter: true },
            { field: 'apellidos', header: 'Apellidos', sortable: true, filter: true },
            { field: 'nombres', header: 'Nombres', sortable: true, filter: true },
            {
                field: 'actividad',
                header: 'Actividad',
                default: 'NO ASIGNADO',
            },
            {
                field: 'activo',
                header: 'Activo',
                cell: ({ value }) => (value ? 'Si' : 'No'),
                cellClassName: 'text-center',
                sortable: true,
                filter: true,
                filterElement: activoTemplate,
            },
            { field: 'id', header: 'Opciones', cell: optionsTemplate },
        ],
        [],
    )

    const handleToggleActive = async (rowData) => {
        if (rowData?.id) {
            const activo = !rowData.activo
            const { status, data } = await ContabilidadServices.usuario.update(rowData.id, { activo })
            showHttpToast(status, {
                detail:
                    status === 200
                        ? activo
                            ? 'Usuario habilitado correctamente'
                            : 'Usuario inhabilitado correctamente'
                        : data.message,
            })
            if (status === 200) {
                reloadFilters()
            }
        }
    }

    const clearSelected = () => {
        setSelected(null)
    }

    return (
        <div className='h-100 p-4'>
            <div className='h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Lista de Usuarios</h2>
                    <div className='ms-auto'>
                        <Button
                            startIcon={<span className='bi-plus-lg' />}
                            onClick={() => handleAction('create')}
                            variant='light'
                        >
                            Nuevo Usuario
                        </Button>
                    </div>
                </div>
                <div className='p-3'>
                    <DataTable
                        columns={columns}
                        values={usuarios}
                        lazy
                        onSort={handleSort}
                        filterable
                        onFilter={handleFilter}
                        pagination
                        currentPage={pagination.currentPage}
                        totalItems={pagination.totalRecords}
                        pageSize={pagination.pageSize}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        defaultAlignHeader='center'
                    />
                </div>
            </div>
            <ModalFormUsuario
                id={selected?.id}
                show={isVisible.modalForm}
                onHide={() => hide('modalForm')}
                onExited={clearSelected}
                onSuccessCreate={() => {
                    reloadFilters()
                    hide('modalForm')
                }}
                onSuccessUpdate={() => {
                    hide('modalForm')
                    reloadFilters()
                }}
            />
            <ModalFondoRotatorio
                id={selected?.id}
                show={isVisible.modalRotatorio}
                onHide={() => hide('modalRotatorio')}
                onExited={clearSelected}
                onSuccess={() => hide('modalRotatorio')}
            />
            <ModalFondoAvance
                id={selected?.id}
                show={isVisible.modalAvance}
                onHide={() => hide('modalAvance')}
                onExited={clearSelected}
                onSuccess={() => hide('modalAvance')}
            />
            <ModalAssignRoles
                id={selected?.id}
                show={isVisible.modalAssign}
                onHide={() => hide('modalAssign')}
                onCloseModal={clearSelected}
                onSuccess={reloadFilters}
            />
        </div>
    )
}
