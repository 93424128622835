import { Button } from 'components/modular/Button'
import { DataTable } from 'components/modular/DataTable'
import { useEffect, useMemo, useState } from 'react'
import { formatCurrency, formatDate } from 'components/modular/utils'
import useHttpToast from 'hooks/useHttpToast'
import { Confirm, Modal } from 'components/modular/Modal'
import RecaudacionesServices from 'services/recaudaciones.service'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import useRecibo from 'modules/recaudaciones/hooks/useRecibo'

/**
 * Componente para la gestión de deudas.
 *
 * @param {object} props Propiedades del componente.
 * @param {object} props.persona Datos de la persona seleccionada.
 * @param {string} props.preimpreso Número de preimpreso.
 * @param {Function} props.setPreimpreso Función que actualiza el valor del preimpreso.
 * @param {Function} props.onSuccessPay Callback que se ejecuta cuando el pago de una deuda es exitosa.
 * @param {Function} props.onSuccessExpire Callback que se ejecuta cuando se caduca una deuda exitosamente.
 */
export default function CobroDeudas({ persona, preimpreso, setPreimpreso, onSuccessPay, onSuccessExpire, ...props }) {
    const { showHttpToast } = useHttpToast()
    const { payDeuda, expireDeuda } = useRecibo()
    const [deudas, setDeudas] = useState([])
    const [searching, setSearching] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [selected, setSelected] = useState(false)
    const [isVisible, setIsVisible] = useState({ modalConfirmExpire: false, modalView: false })
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (persona?.id) {
            searchDeudas(persona.id)
        } else {
            setDeudas([])
        }
    }, [persona])

    /** Obtiene las deudas de una persona. */
    const searchDeudas = async (personaId) => {
        setSearching(true)
        let length = null
        const { status, data } = await RecaudacionesServices.ventaValores.listDeudas(personaId)
        if (status === 200) {
            setDeudas(data.data)
            length = data.data.length
        } else {
            setDeudas([])
            showHttpToast(status, { detail: data.message })
        }
        setSearching(false)
        return length
    }

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData = null) => {
        if (action === 'expire') {
            setSelected(rowData)
            show('modalConfirmExpire')
        } else if (action === 'pay') {
            handlePay(rowData)
        } else if (action === 'view') {
            setSelected(rowData)
            show('modalView')
        }
    }

    const optionsHeader = () => (
        <FieldGroup label='Nro. Preimpreso:' className='mb-0' inline>
            <Input
                type='number'
                name='preimpreso'
                value={preimpreso}
                onChange={(e) => {
                    setPreimpreso(e.target.value)
                    errors.preimpreso && setErrors({})
                }}
                min={1}
                className='text-end'
                isInvalid={errors.preimpreso}
                size='sm'
            />
        </FieldGroup>
    )
    const detalleCellTemplate = ({ data }) => (
        <div className='text-center'>
            <Button title='Detalles' disabled={submitting} onClick={() => handleAction('view', data)}>
                <span>
                    <i className='fa fa-info-circle fa-lg' />
                </span>
            </Button>
        </div>
    )
    const caducarCellTemplate = ({ data }) => (
        <div className='text-center'>
            <Button variant='danger' title='Caducar' disabled={submitting} onClick={() => handleAction('expire', data)}>
                <span>
                    <i className='bi bi-trash3-fill' />
                </span>
            </Button>
        </div>
    )
    const pagarCellTemplate = ({ data }) => (
        <div className='text-center'>
            <Button variant='success' title='Pagar' disabled={submitting} onClick={() => handleAction('pay', data)}>
                <span>
                    <i className='fa fa-money fa-lg' />
                </span>
            </Button>
        </div>
    )
    const columns = useMemo(
        () => [
            {
                id: 'col_persona',
                header: 'Persona',
                headerClassName: 'bg-secondary-subtle',
                columns: [
                    {
                        field: 'persona_documento',
                        header: 'Documento',
                        headerClassName: 'bg-secondary-subtle',
                        cellClassName: 'text-center',
                    },
                    {
                        field: 'persona_codigo',
                        header: 'Código',
                        headerClassName: 'bg-secondary-subtle',
                        cellClassName: 'text-center',
                    },
                    {
                        field: 'persona',
                        header: 'Apellidos y Nombres',
                        headerClassName: 'bg-secondary-subtle',
                    },
                ],
            },
            {
                id: 'col_cuenta',
                header: 'Cuenta',
                headerClassName: 'bg-secondary-subtle',
                columns: [
                    {
                        field: 'cuenta',
                        header: 'Nombre',
                        headerClassName: 'bg-secondary-subtle',
                    },
                    {
                        field: 'total',
                        header: 'Importe Total (Bs)',
                        headerClassName: 'bg-secondary-subtle',
                        cell: ({ value }) => formatCurrency(value),
                        cellClassName: 'text-end',
                    },
                ],
            },
            {
                id: 'options',
                header: optionsHeader,
                headerClassName: 'bg-secondary-subtle',
                headerStyle: { width: '15rem' },
                columns: [
                    {
                        id: 'detalle',
                        header: 'Detalle',
                        headerClassName: 'bg-secondary-subtle',
                        cell: detalleCellTemplate,
                    },
                    {
                        id: 'caducar',
                        header: 'Caducar',
                        headerClassName: 'bg-secondary-subtle',
                        cell: caducarCellTemplate,
                    },
                    {
                        id: 'pagar',
                        header: 'Pagar',
                        headerClassName: 'bg-secondary-subtle',
                        cell: pagarCellTemplate,
                    },
                ],
            },
        ],
        [preimpreso, errors.preimpreso],
    )

    /** Paga una deuda. */
    const handlePay = async (deuda) => {
        setSubmitting(true)
        if (preimpreso > 0) {
            await payDeuda(deuda.id, { preimpreso }, async (data) => {
                const length = await searchDeudas(persona.id)
                onSuccessPay?.({ ...data, lengthDeudas: length })
            })
        } else {
            setErrors((prev) => ({ ...prev, preimpreso: 'Debe ser mínimo 1' }))
        }
        setSubmitting(false)
    }
    /** Caduca la deuda seleccionada. */
    const handleExpire = async ({ setLoading }) => {
        setLoading(true)
        hide('modalConfirmExpire')
        if (selected.id) {
            await expireDeuda(selected.id, async () => {
                const length = await searchDeudas(persona.id)
                onSuccessExpire?.({ lengthDeudas: length })
            })
        }
        setLoading(false)
    }
    const clearSelected = () => {
        setSelected(null)
    }

    return (
        <div {...props}>
            <div className='bg-blue rounded text-white select-none mb-3' style={{ padding: '.75rem 1rem' }}>
                <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                    DEUDAS
                </span>
            </div>
            <div className='px-3'>
                {searching ? (
                    <div className='text-center'>
                        <div className='spinner-border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </div>
                    </div>
                ) : deudas.length ? (
                    <DataTable
                        values={deudas}
                        columns={columns}
                        defaultAlignHeader='center'
                        tableClassName='table-bordered border-secondary'
                        emptyMessage='Sin deudas encontradas'
                    />
                ) : (
                    <div className='fw-medium text-center'>Sin deudas encontradas</div>
                )}
            </div>
            <Confirm
                show={isVisible.modalConfirmExpire}
                onAccept={handleExpire}
                onReject={() => hide('modalConfirmExpire')}
                onExited={clearSelected}
                style={{ width: '35rem' }}
                className='rounded-4 overflow-hidden'
                headerClassName='bg-secondary-subtle'
                footerClassName='bg-secondary-subtle text-center'
            >
                <span className='fw-medium fs-4' style={{ lineHeight: 1.25 }}>
                    Esta seguro de <span className='text-primary'>CADUCAR</span> la deuda de: "{selected?.persona}"
                </span>
            </Confirm>
            <Modal
                show={isVisible.modalView}
                onHide={() => hide('modalView')}
                onExited={clearSelected}
                style={{ width: '60rem' }}
            >
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Detalle</div>
                <div className='p-3'>
                    <div className='border border-dark rounded'>
                        <div className='bg-blue text-white fw-medium p-2'>DEUDA</div>
                        <div className='p-2'>
                            <div className='row'>
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    FECHA:
                                </div>
                                <div className='col'>
                                    {selected?.fecha ? formatDate(selected.fecha, 'd/m/Y H:i:s') : ''}
                                </div>
                                <div className='fw-medium text-end' style={{ width: '9rem' }}>
                                    IMPORTE (BS):
                                </div>
                                <div className='fw-medium' style={{ width: '10rem' }}>
                                    {selected?.precio ? formatCurrency(selected.precio) : ''}
                                </div>
                            </div>
                            <hr className='my-2' />
                            <div className='row'>
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    PERSONA:
                                </div>
                                <div className='col'>{selected?.persona}</div>
                                <div className='col-auto'>
                                    <span className='fw-medium me-3'>DOCUMENTO:</span>
                                    {selected?.persona_documento}
                                </div>
                                <div className='fw-medium text-end' style={{ width: '6rem' }}>
                                    CODIGO:
                                </div>
                                <div style={{ width: '10rem' }}>{selected?.persona_codigo}</div>
                            </div>
                            <hr className='my-2' />
                            <div className='row'>
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    CUENTA:
                                </div>
                                <div className='col'>{selected?.cuenta}</div>
                                <div className='fw-medium text-end' style={{ width: '6rem' }}>
                                    CODIGO:
                                </div>
                                <div style={{ width: '10rem' }}>{selected?.cuenta_codigo}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        className='me-2'
                        startIcon={<span className='bi-x-lg' />}
                        onClick={() => hide('modalView')}
                    >
                        Cerrar
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
