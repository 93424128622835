import authHeader from './auth-header'
import { BaseRequest } from './request/baseRequest'
import { GenericRequest } from './request/request'

const ContabilidadServices = {
    fondo: {
        list: (params = {}) => GenericRequest.get('contabilidad/list-fondo', params),
        view: (id) => GenericRequest.get('contabilidad/view-fondo', { id }),
        end: (id) => GenericRequest.post('contabilidad/end-fondo', {}, { id }),
        reverseEnd: (id) => GenericRequest.post('contabilidad/reverse-end-fondo', {}, { id }),
        pdf: (id) =>
            BaseRequest.get('contabilidad/fondo-pdf', { params: { id }, responseType: 'blob', headers: authHeader() }),
        details: (id) => GenericRequest.get('contabilidad/fondo-detalles', { id }),
        reportPdf: (params = {}) =>
            BaseRequest.get('contabilidad/fondos-pdf', { params, responseType: 'blob', headers: authHeader() }),
        deactivate: (id) => GenericRequest.post('contabilidad/deactivate-fondos', { fondo_id: id }),
        xlsx: (id) =>
            BaseRequest.get('contabilidad/fondo-xlsx', { params: { id }, responseType: 'blob', headers: authHeader() }),
        searchActividades: (codigo) => GenericRequest.get('contabilidad/fondo-search-actividades', { codigo }),
    },
    fondoRotatorio: {
        list: (params = {}) => GenericRequest.get('contabilidad/list-fondo-rotatorio', params),
        create: (data = {}) => GenericRequest.get('contabilidad/create-fondo-rotatorio', data),
        hasActive: (data = {}) => GenericRequest.get('contabilidad/has-active-fondo-rotatorio', data),
        delete: (id) => GenericRequest.post('contabilidad/delete-fondo-rotatorio', {}, { id }),
        byUsuario: (id) => GenericRequest.get('contabilidad/fondo-rotatorio-by-usuario', { id }),
    },
    fondoAvance: {
        list: (params = {}) => GenericRequest.get('contabilidad/list-fondo-avance', params),
        byUsuario: (id) => GenericRequest.get('contabilidad/fondo-avance-by-usuario', { id }),
        update: (data) => GenericRequest.post('contabilidad/update-fondo-avance', data),
    },
    fondoDetalle: {
        create: (data) => GenericRequest.post('contabilidad/create-fondo-detalle', data),
        update: (id, data) => GenericRequest.post('contabilidad/update-fondo-detalle', data, { id }),
        delete: (id) => GenericRequest.post('contabilidad/delete-fondo-detalle', {}, { id }),
    },
    usuario: {
        list: (params = {}) => GenericRequest.get('contabilidad/list-usuario', params),
        view: (id) => GenericRequest.get('contabilidad/view-usuario', { id }),
        create: (data) => GenericRequest.post('contabilidad/create-usuario', data),
        update: (id, data) => GenericRequest.post('contabilidad/update-usuario', data, { id }),
        buscarFuncionario: (funcionario) => GenericRequest.post('contabilidad/buscar-funcionario', { funcionario }),
        searchActividades: (codigo) => GenericRequest.get('contabilidad/usuario-search-actividades', { codigo }),
    },
    role: {
        getToAssign: (usuarioId) => GenericRequest.get('contabilidad/get-roles-to-assign', { usuarioId }),
        batchUpdate: (usuarioId, data) => GenericRequest.post('contabilidad/update-roles', data, { usuarioId }),
    },
    tipoDocumento: {
        all: () => GenericRequest.get('contabilidad/get-tipos-documento'),
    },
    getConfiguracion: (params) => GenericRequest.get('contabilidad/get-configuracion', params),
    getManual: (filename) =>
        BaseRequest.get('contabilidad/get-file', { params: { filename }, responseType: 'blob', headers: authHeader() }),
    allDa: () => GenericRequest.get('estructura-programatica/all-da'),
}
export default ContabilidadServices
