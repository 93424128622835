import { initialPagination } from 'data/constants'
import useFilters from 'hooks/useFilters'
import { Button } from 'components/modular/Button'
import { Checkbox } from 'components/modular/Checkbox'
import { DataTable } from 'components/modular/DataTable'
import { Input } from 'components/modular/Input'
import { Select } from 'components/modular/Select'
import { formatCurrency, formatDate } from 'components/modular/utils'
import { useMemo, useState } from 'react'

const boolCellTemplate = ({ value }) => {
    return value ? <span className='bi-check-lg text-success' /> : <span className='bi-x-lg text-danger' />
}
const checkFilterElement = ({ value, filterCallback }) => (
    <div className='d-flex justify-content-center'>
        <Checkbox
            value={value}
            onChange={(e) => filterCallback(e.target.value)}
            triState
            style={{ fontSize: '1.5rem' }}
        />
    </div>
)
const dateFilterElement = ({ value, filterCallback }) => (
    <Input type='date' value={value} onChange={filterCallback} style={{ width: '9rem' }} />
)
const codigoFilterElement = ({ value, filterCallback }) => (
    <Input
        value={value}
        onChange={(e) => filterCallback(e.target.value)}
        onKeyDown={(e) => (e.key === ' ' || e.key === 'Spacebar') && e.preventDefault()}
    />
)
const textFilterElement = ({ value, filterCallback }) => (
    <Input value={value} onChange={(e) => filterCallback(e.target.value)} />
)
const currencyFilterElement = ({ value, filterCallback }) => (
    <Input type='currency' value={value} onChange={(e) => filterCallback(e.target.value)} className='text-end' />
)
const tipoMontoFilterElement = ({ value, filterCallback }) => (
    <Select
        options={['V', 'F']}
        value={value}
        onChange={(e) => filterCallback(e.target.value)}
        placeholder='...'
        className='px-2 text-center'
        style={{ backgroundImage: 'none' }}
    />
)

export default function Cuentas() {
    const [cuentas, setCuentas] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const { filters, handlePage, handlePageSize } = useFilters()

    const columns = useMemo(
        () => [
            {
                field: 'codigo',
                header: 'Código',
                filter: true,
                filterElement: codigoFilterElement,
                style: { width: '11rem' },
            },
            {
                field: 'nombre',
                header: 'Nombre',
                filter: true,
                filterElement: textFilterElement,
            },
            {
                field: 'precio',
                header: 'Precio',
                cell: ({ value }) => formatCurrency(value),
                cellClassName: 'text-center',
                filter: true,
                filterElement: currencyFilterElement,
                style: { width: '8rem' },
            },
            {
                field: 'fecha_inicio',
                header: 'Fecha Inicio',
                filter: true,
                filterElement: dateFilterElement,
                style: { width: '10rem' },
            },
            {
                field: 'fecha_limite',
                header: 'Fecha Fin',
                cell: ({ value }) => formatDate(value, 'd/m/Y H:i:s'),
                cellClassName: 'text-center',
                default: 'Indefinido',
                filter: true,
                filterElement: dateFilterElement,
                style: { width: '10rem' },
            },
            {
                field: 'tipo_monto',
                header: (
                    <div className='select-none' title='Tipo de Monto'>
                        TM
                    </div>
                ),
                filter: true,
                filterElement: tipoMontoFilterElement,
                style: { width: '3.5rem' },
            },
            {
                field: 'caja',
                header: (
                    <div className='select-none' title='Caja'>
                        C
                    </div>
                ),
                cell: boolCellTemplate,
                filter: true,
                filterElement: checkFilterElement,
                style: { width: '3.5rem' },
            },
            {
                field: 'electronico',
                header: (
                    <div className='select-none' title='Electrónico'>
                        E
                    </div>
                ),
                cell: boolCellTemplate,
                filter: true,
                filterElement: checkFilterElement,
                style: { width: '3.5rem' },
            },
            {
                field: 'postulante',
                header: (
                    <div className='select-none' title='Deuda'>
                        D
                    </div>
                ),
                cell: boolCellTemplate,
                filter: true,
                filterElement: checkFilterElement,
                style: { width: '3.5rem' },
            },
            {
                field: 'factura',
                header: (
                    <div className='select-none' title='Factura'>
                        F
                    </div>
                ),
                cell: boolCellTemplate,
                filter: true,
                filterElement: checkFilterElement,
                style: { width: '3.5rem' },
            },
            {
                field: 'posgrado',
                header: (
                    <div className='select-none' title='Posgrado'>
                        P
                    </div>
                ),
                cell: boolCellTemplate,
                filter: true,
                filterElement: checkFilterElement,
                style: { width: '3.5rem' },
            },
            {
                field: 'activo',
                header: 'Activo',
                filter: true,
                filterElement: checkFilterElement,
                style: { width: '5rem' },
            },
            {
                id: 'options',
                header: 'Opciones',
                style: { width: '6rem' },
            },
        ],
        [],
    )

    return (
        <div className='h-100 p-4'>
            <div className='h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Lista de Cuentas</h2>
                    <div className='ms-auto'>
                        <Button startIcon={<span className='bi-plus-lg' />} variant='light'>
                            Nuevo
                        </Button>
                    </div>
                </div>
                <div className='p-3'>
                    <DataTable
                        values={cuentas}
                        columns={columns}
                        lazy
                        pagination
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        totalItems={pagination.totalRecords}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        pageSizeOptions={[10, 25, 50]}
                        filterable
                        defaultAlignHeader='center'
                    />
                </div>
            </div>
        </div>
    )
}
