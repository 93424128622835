import { initialPagination } from 'data/constants'
import useFilters from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { DataTable } from 'components/modular/DataTable'
import { useEffect, useState } from 'react'
import PresupuestosServices from 'services/presupuestos.service'
import { omit } from 'components/modular/utils'

const columns = [
    { field: 'codigo', header: 'Partida', sortable: true, filter: true, style: { width: '10rem' } },
    { field: 'nombre', header: 'Detalle', sortable: true, filter: true },
    {
        field: 'clase_gasto',
        header: 'Clase de gasto',
        cell: ({ data }) => `${data.clase_gasto_codigo} - ${data.clase_gasto}`,
        sortable: true,
        filter: true,
    },
]
const filterOptions = { equal: ['codigo', 'nombre', 'clase_gasto'], bool: ['activo'] }

export default function ConsultaItemsGasto() {
    const { showHttpToast } = useHttpToast()
    const { filters, handleFilter, handlePage, handlePageSize, handleSort } = useFilters({
        filterOptions,
    })
    const [items, setItems] = useState([])
    const [pagination, setPagination] = useState(initialPagination)

    useEffect(() => {
        const loadList = async () => {
            const params = { ...omit(filters, ['filter']), ...filters.filter }
            const { status, data } = await PresupuestosServices.itemGasto.consult(params)
            if (status === 200) {
                setItems(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadList()
    }, [filters])

    return (
        <div className='h-100 p-4'>
            <div className='h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Consultar Items de Gasto</h2>
                </div>
                <div className='p-3'>
                    <DataTable
                        values={items}
                        columns={columns}
                        lazy
                        pagination
                        totalItems={pagination.totalRecords}
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        pageSizeOptions={[10, 25, 50, 100]}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        onSort={handleSort}
                        filterable
                        onFilter={handleFilter}
                    />
                </div>
            </div>
        </div>
    )
}
