import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { empty, formatCurrency } from 'components/modular/utils'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

const initialFactura = {
    observacion: '',
}

const validateFactura = (values) => {
    const errors = {}
    if (values.subtotal <= values.descuento) errors.total = 'Debe ser mayor a cero'
    return empty(errors) ? null : errors
}

export default function DatosFactura({ innerRef, inputRefs, saveRef, totales, ...props }) {
    const [factura, setFactura] = useState(initialFactura)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        errors.total && setErrors((prev) => ({ ...prev, total: '' }))
    }, [totales])

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        setFactura((prev) => ({ ...prev, [name]: e.target.value }))
        errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    /** Maneja el evento keyup de los inputs. */
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            inputRefs?.current['buttonTerminar']?.focus()
        }
    }

    /** Valida los datos de la factura. */
    const validate = () => {
        const _errors = validateFactura({
            ...factura,
            subtotal: totales.monto_principal + totales.monto_adicionales,
            descuento: totales.descuento_principal + totales.descuento_adicionales,
        })
        if (_errors) {
            setErrors(_errors)
        }
        return _errors
    }
    /** Establece los datos de la factura. */
    const reset = () => {
        setFactura(initialFactura)
    }
    /** Obtiene los datos de la factura. */
    const get = () => {
        return { ...factura }
    }

    return (
        <div {...props}>
            <div
                className='d-flex align-items-center bg-blue rounded text-white select-none mb-3'
                style={{ padding: '.75rem 1rem' }}
            >
                <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                    FACTURA
                </span>
            </div>
            <div className='px-3'>
                <div className='row g-2'>
                    <FieldGroup
                        label='Aclaración'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6.25rem' }}
                        className='col mb-0'
                    >
                        <Input
                            name='observacion'
                            value={factura.observacion}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            size='sm'
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Importe Subtotal (Bs)'
                        labelClassName='fw-medium'
                        style={{ width: '16rem' }}
                        className='col-4 mb-0'
                    >
                        <Input
                            value={formatCurrency(totales.monto_principal + totales.monto_adicionales)}
                            onChange={() => {}}
                            className='text-end'
                            size='sm'
                            disabled
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Descuento (Bs)'
                        labelClassName='fw-medium'
                        style={{ width: '8rem' }}
                        className='col-4 mb-0'
                    >
                        <Input
                            value={formatCurrency(totales.descuento_principal + totales.descuento_adicionales)}
                            onChange={() => {}}
                            className='text-end'
                            size='sm'
                            disabled
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Importe Total (Bs)'
                        labelClassName='fw-medium'
                        style={{ width: '10rem' }}
                        className='col-4 mb-0'
                    >
                        <Input
                            value={formatCurrency(
                                totales.monto_principal +
                                    totales.monto_adicionales -
                                    totales.descuento_principal -
                                    totales.descuento_adicionales,
                            )}
                            onChange={() => {}}
                            className='text-end'
                            isInvalid={errors.total}
                            size='sm'
                            disabled
                        />
                    </FieldGroup>
                </div>
            </div>
            <DatosFacturaRef ref={innerRef} validate={validate} get={get} reset={reset} />
        </div>
    )
}

const DatosFacturaRef = forwardRef(({ validate, get, reset }, ref) => {
    useImperativeHandle(ref, () => ({
        validateFactura() {
            return validate()
        },
        getFactura() {
            return get()
        },
        resetFactura() {
            reset()
        },
    }))
    return null
})
