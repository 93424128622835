import { fuenteFondoOptions } from 'data/constants'
import useHttpToast from 'hooks/useHttpToast'
import { AdvancedSelect } from 'components/modular/AdvancedSelect'
import { Button } from 'components/modular/Button'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Modal } from 'components/modular/Modal'
import { Select } from 'components/modular/Select'
import { empty } from 'components/modular/utils'
import { useEffect, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'
import { pick } from 'utils'

const initialFondoRotatorio = {
    usuario: '',
    monto_asignado: '0.00',
    fuente_fondo: '',
    numero_devengado: '',
    codigo_sisin: '',
    filter_das: [],
    fondo_activo: false,
}
/** Valida los datos de un Fondo Rotatorio. */
const validate = (values) => {
    const errors = {}
    if (!values.fuente_fondo) errors.fuente_fondo = 'Requerido'
    if (!values.numero_devengado.trim()) errors.numero_devengado = 'Requerido'
    if (values.monto_asignado) {
        if (!(values.monto_asignado > 0)) errors.monto_asignado = 'El monto debe ser mayor a cero'
    } else {
        errors.monto_asignado = 'Requerido'
    }
    return empty(errors) ? null : errors
}

export default function ModalFondoRotatorio({ id, onSuccess, ...props }) {
    const { showHttpToast } = useHttpToast()
    const [submitting, setSubmitting] = useState(false)
    const [direcciones, setDirecciones] = useState([])
    const [values, setValues] = useState(initialFondoRotatorio)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        const loadDirecciones = async () => {
            const { status, data } = await ContabilidadServices.allDa()
            if (status === 200) {
                setDirecciones(data.data)
            }
        }
        loadDirecciones()
    }, [])
    useEffect(() => {
        if (props.show) {
            if (id) {
                const load = async () => {
                    const { status, data } = await ContabilidadServices.fondoRotatorio.byUsuario(id)
                    if (status === 200) {
                        setValues(data.data)
                    } else {
                        showHttpToast(status, { detail: data.message })
                        props.onHide?.()
                    }
                }
                load()
            } else {
                showHttpToast(400, { detail: 'Usuario no seleccionado' })
                props.onHide?.()
            }
        } else {
            setErrors({})
        }
    }, [id, props.show])

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'fuente_fondo' && value !== 'IDH') {
            setValues((prev) => ({ ...prev, [name]: value, codigo_sisin: '' }))
        } else {
            setValues((prev) => ({ ...prev, [name]: value }))
        }
        errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    /** Maneje el evento submit del formulario. */
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (id) {
            setSubmitting(true)
            const _errors = validate(values)
            if (_errors) {
                setErrors(_errors)
            } else {
                const bodyParams = pick(values, [
                    'fuente_fondo',
                    'monto_asignado',
                    'numero_devengado',
                    'filter_das',
                    'codigo_sisin',
                ])
                const { status, data } = await ContabilidadServices.usuario.update(id, bodyParams)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    onSuccess?.()
                }
            }
            setSubmitting(false)
        }
    }

    return (
        <Modal style={{ width: '35rem' }} {...props} closeOnBackdrop={false} keyboard={false}>
            <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Fondo Rotatorio</div>
            <form onSubmit={handleSubmit}>
                <div className='p-3'>
                    <div className='mb-2'>
                        <span className='fw-medium me-2'>Usuario:</span>
                        {values.usuario}
                        <br />
                        <span className='fw-medium me-2'>Fondo Rotatorio activo:</span>
                        {values.fondo_activo ? 'Si' : 'No'}
                    </div>
                    {values.fondo_activo && (
                        <div className='mb-2 text-primary fst-italic' style={{ lineHeight: '1.25' }}>
                            <span className='bi-info-circle-fill' /> Modificar los datos actualizará el Fondo Rotatorio
                            activo del usuario.
                        </div>
                    )}
                    <div className='row g-3'>
                        <FieldGroup
                            label='Número Devengado'
                            className='col-6 mb-0'
                            feedback={errors.numero_devengado}
                            showFeedback={!!errors.numero_devengado}
                        >
                            <Input
                                name='numero_devengado'
                                value={values.numero_devengado}
                                onChange={handleChange}
                                isInvalid={!!errors.numero_devengado}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Monto Asignado'
                            className='col-6 mb-0'
                            feedback={errors.monto_asignado}
                            showFeedback={!!errors.monto_asignado}
                        >
                            <Input
                                name='monto_asignado'
                                type='currency'
                                value={values.monto_asignado}
                                onChange={handleChange}
                                onFocus={(e) => parseInt(values.monto_asignado) === 0 && e.target.select()}
                                isInvalid={errors.monto_asignado}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Fondos'
                            className='col-6 mb-0'
                            feedback={errors.fuente_fondo}
                            showFeedback={!!errors.fuente_fondo}
                        >
                            <Select
                                options={fuenteFondoOptions}
                                name='fuente_fondo'
                                value={values.fuente_fondo}
                                onChange={handleChange}
                                isInvalid={!!errors.fuente_fondo}
                            />
                        </FieldGroup>
                        <FieldGroup label='Código SISIN' className='col-6 mb-0'>
                            <Input
                                type='integer'
                                name='codigo_sisin'
                                value={values.codigo_sisin}
                                onChange={handleChange}
                                disabled={values.fuente_fondo !== 'IDH'}
                            />
                        </FieldGroup>
                        <FieldGroup label="Filtro de Cat. Prog. por DA's" className='col-12 mb-0'>
                            <AdvancedSelect
                                options={direcciones}
                                optionValue='id'
                                optionLabel='descripcion'
                                optionTemplate={(option) => `${option.codigo} - ${option.descripcion}`}
                                className='w-100'
                                menuStyle={{ minWidth: '100%' }}
                                multiSelection
                                name='filter_das'
                                value={values.filter_das}
                                onChange={handleChange}
                            />
                        </FieldGroup>
                    </div>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        startIcon={<span className='bi-x-lg' />}
                        onClick={() => props.onHide?.()}
                        disabled={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type='submit'
                        startIcon={<span className='bi-floppy' />}
                        loading={submitting}
                        disabled={!id}
                    >
                        Guardar
                    </Button>
                </div>
            </form>
        </Modal>
    )
}
