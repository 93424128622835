import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'components/modular/Button'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { clean, debounce } from 'components/modular/utils'
import { useCallback, useEffect, useState } from 'react'
import { pick } from 'utils'
import ModalSelectPersona from './components/ModalSelectPersona'
import { DatosPersonaRef } from './components/DatosPersonaRef'
import { validatePersona } from './helpers/validatePersona'
import RecaudacionesServices from 'services/recaudaciones.service'
import { InputIcon } from 'components/modular/InputIcon'

const initialPersona = {
    documento: '',
    complemento_documento: '',
    codigo: '',
    fecha_nacimiento: '',
    apellido_1: '',
    apellido_2: '',
    nombre_1: '',
    nombre_2: '',
}

/**
 * Componente de llenado de Datos de Persona.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {any} props.innerRef - Referencia del componente.
 * @param {any} props.inputRefs - Referencia los componentes del formulario.
 * @param {any} props.lastPersona - Ultima persona a la que se realizo una venta.
 * @param {any} props.onSelectPersona - Callback que se ejecuta cuando se selecciona una persona.
 */
export default function DatosPersona({ innerRef, inputRefs, saveRef, lastPersona, onSelectPersona, ...props }) {
    const { showHttpToast } = useHttpToast()
    const [persona, setPersona] = useState(initialPersona)
    const [errors, setErrors] = useState({})
    const [isVisible, setIsVisible] = useState({
        modalSelectPersona: false,
        iconFieldDocumento: true,
        iconFieldCodigo: true,
    })
    const [personas, setPersonas] = useState([])

    useEffect(() => {
        onSelectPersona?.(persona.id)
    }, [persona.id])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    /** Almacena los datos de la persona seleccionada. */
    const handleSelectPersona = (persona) => {
        setPersona({
            ...initialPersona,
            ...clean(
                pick(persona, [
                    'id',
                    'documento',
                    'complemento_documento',
                    'codigo',
                    'fecha_nacimiento',
                    'apellido_1',
                    'apellido_2',
                    'nombre_1',
                    'nombre_2',
                ]),
            ),
        })
        onSelectPersona?.(persona.id)
        setErrors({})
        setTimeout(() => {
            inputRefs?.current['cuenta.codigo']?.focus()
        }, 250)
    }

    /**
     * Busca la persona por el campo documento o código sis.
     * @param {object} params Parámetros de búsqueda.
     * @param {string} params.documento Documento.
     * @param {string} params.codigo Código sis.
     */
    const searchPersona = useCallback(
        debounce(async (params) => {
            // Eliminar espacios en blanco del documento
            const { documento, codigo } = params ?? {}
            if (documento || codigo) {
                // Buscar persona
                const searchParams = clean({ documento, codigo })
                const { status, data } = await RecaudacionesServices.ventaValores.searchPersona(searchParams)
                if (status === 200) {
                    if (isVisible.modalSelectPersona) {
                        setPersonas(data.data)
                    } else {
                        if (data.data.length > 1) {
                            setPersonas(data.data)
                            show('modalSelectPersona')
                        } else if (data.data.length === 1) {
                            handleSelectPersona(data.data[0])
                        } else {
                            showHttpToast(100, { detail: 'No se encontró ninguna persona.' })
                            inputRefs?.current['persona.fecha_nacimiento']?.focus()
                        }
                    }
                } else {
                    showHttpToast(status, { detail: data.message })
                }
            }
        }, 300),
        [isVisible.modalSelectPersona],
    )

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value.toUpperCase()
        if (name !== 'codigo' || value.length <= 9) {
            if (persona.id && (name === 'documento' || name === 'codigo')) {
                setPersona({ ...initialPersona, [name]: value })
                setErrors({})
            } else {
                setPersona((prev) => ({ ...prev, [name]: value }))
                errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
            }
        }
    }
    /** Maneja el evento keyup de los inputs. */
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            const { name, value } = e.target
            switch (name) {
                case 'documento':
                    searchPersona({ documento: persona.documento })
                    break
                case 'codigo':
                    searchPersona({ codigo: persona.codigo })
                    break
                case 'complemento_documento':
                    inputRefs?.current['persona.fecha_nacimiento']?.focus()
                    break
                case 'fecha_nacimiento':
                    if (value) {
                        inputRefs?.current['persona.apellido_1']?.focus()
                    }
                    break
                case 'apellido_1':
                    if (value.trim()) {
                        inputRefs?.current['persona.apellido_2']?.focus()
                    }
                    break
                case 'apellido_2':
                    inputRefs?.current['persona.nombre_1']?.focus()
                    break
                case 'nombre_1':
                    if (value.trim()) {
                        inputRefs?.current['persona.nombre_2']?.focus()
                    }
                    break
                case 'nombre_2':
                    inputRefs?.current['cuenta.codigo']?.focus()
                    break

                default:
                    break
            }
        }
    }
    /** Maneja el evento keydown de los inputs. */
    const handleKeyDown = (e) => {
        const name = e.target.name
        if (['documento', 'complemento_documento'].includes(name)) {
            if (e.key === ' ' || e.key === 'Spacebar') {
                e.preventDefault()
            }
        }
    }

    /** Valida los datos de la persona. */
    const validate = () => {
        const _errors = validatePersona(persona)
        if (_errors) {
            setErrors(_errors)
        }
        return _errors
    }
    /** Restablece los datos de la persona a sus valores iniciales. */
    const reset = () => {
        setPersona(initialPersona)
    }
    /** Obtiene los datos de la persona. */
    const get = () => {
        return { ...persona }
    }

    return (
        <div {...props}>
            <div
                className='d-flex align-items-center bg-blue rounded text-white select-none mb-3'
                style={{ padding: '.75rem 1rem' }}
            >
                <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                    PERSONA
                </span>
                <Button
                    variant='secondary'
                    startIcon={<span className='bi-arrow-counterclockwise' />}
                    onClick={() => lastPersona && setPersona(lastPersona)}
                    size='sm'
                    className='ms-auto'
                >
                    Recuperar Documento
                </Button>
            </div>
            <div className='px-3'>
                <div className='row g-3'>
                    <FieldGroup
                        label='Documento:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <InputIcon
                            startIcon={
                                isVisible.iconFieldDocumento && !persona.documento ? (
                                    <span className='bi-search text-secondary' />
                                ) : undefined
                            }
                            onFocus={() => hide('iconFieldDocumento')}
                            onBlur={() => show('iconFieldDocumento')}
                        >
                            <Input
                                ref={(el) => saveRef?.(el, 'persona.documento')}
                                name='documento'
                                value={persona.documento}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onKeyDown={handleKeyDown}
                                isInvalid={errors.documento}
                                size='sm'
                                autoFocus
                            />
                        </InputIcon>
                    </FieldGroup>
                    <FieldGroup
                        label='Comp.:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <Input
                            name='complemento_documento'
                            value={persona.complemento_documento}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            onKeyDown={handleKeyDown}
                            isInvalid={errors.complemento_documento}
                            size='sm'
                            disabled={!!persona.id}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Código:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <InputIcon
                            startIcon={
                                isVisible.iconFieldCodigo && !persona.codigo ? (
                                    <span className='bi-search text-secondary' />
                                ) : undefined
                            }
                            onFocus={() => hide('iconFieldCodigo')}
                            onBlur={() => show('iconFieldCodigo')}
                        >
                            <Input
                                name='codigo'
                                type='integer'
                                value={persona.codigo}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                isInvalid={errors.codigo}
                                size='sm'
                            />
                        </InputIcon>
                    </FieldGroup>
                    <FieldGroup
                        label='Fecha Nac.:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <Input
                            ref={(el) => saveRef?.(el, 'persona.fecha_nacimiento')}
                            name='fecha_nacimiento'
                            type='date'
                            value={persona.fecha_nacimiento}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            isInvalid={errors.fecha_nacimiento}
                            size='sm'
                            disabled={!!persona.id}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Apellido 1:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <Input
                            ref={(el) => saveRef?.(el, 'persona.apellido_1')}
                            name='apellido_1'
                            value={persona.apellido_1}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            isInvalid={errors.apellido_1}
                            size='sm'
                            disabled={!!persona.id}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Apellido 2:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <Input
                            ref={(el) => saveRef?.(el, 'persona.apellido_2')}
                            name='apellido_2'
                            value={persona.apellido_2}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            isInvalid={errors.apellido_2}
                            size='sm'
                            disabled={!!persona.id}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Nombre 1:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <Input
                            ref={(el) => saveRef?.(el, 'persona.nombre_1')}
                            name='nombre_1'
                            value={persona.nombre_1}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            isInvalid={errors.nombre_1}
                            size='sm'
                            disabled={!!persona.id}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Nombre 2:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <Input
                            ref={(el) => saveRef?.(el, 'persona.nombre_2')}
                            name='nombre_2'
                            value={persona.nombre_2}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            isInvalid={errors.nombre_2}
                            size='sm'
                            disabled={!!persona.id}
                        />
                    </FieldGroup>
                </div>
            </div>
            <ModalSelectPersona
                show={isVisible.modalSelectPersona}
                onHide={() => hide('modalSelectPersona')}
                onExited={() => personas.length && setPersonas([])}
                personas={personas}
                onSelectPersona={(selectedPersona) => {
                    handleSelectPersona(selectedPersona)
                    hide('modalSelectPersona')
                }}
                searchPersona={searchPersona}
                initialSearcherValue={persona.documento}
            />
            <DatosPersonaRef ref={innerRef} validate={validate} reset={reset} get={get} />
        </div>
    )
}
