import { fuenteFondoOptions } from 'data/constants'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'components/modular/Button'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Modal } from 'components/modular/Modal'
import { Select } from 'components/modular/Select'
import { clean, empty } from 'components/modular/utils'
import { useEffect, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'
import { pick } from 'utils'

const initialFondoAvance = {
    id: '',
    usuario: '',
    actividad_id: '',
    monto_asignado: '0.00',
    fuente_fondo: '',
    numero_devengado: '',
}
/** Valida los datos de un Fondo en avance. */
const validate = (values) => {
    const _errors = {}
    if (!values.fuente_fondo) _errors.fuente_fondo = 'Requerido'
    if (!values.numero_devengado.trim()) _errors.numero_devengado = 'Requerido'
    if (values.monto_asignado) {
        if (!(values.monto_asignado > 0)) _errors.monto_asignado = 'El monto debe ser mayor a cero'
    } else {
        _errors.monto_asignado = 'Requerido'
    }
    return empty(_errors) ? null : _errors
}

export default function ModalFondoAvance({ id, onSuccess, ...props }) {
    const { showHttpToast } = useHttpToast()
    const [submitting, setSubmitting] = useState(false)
    const [values, setValues] = useState(initialFondoAvance)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (props.show) {
            if (id) {
                const load = async () => {
                    const { status, data } = await ContabilidadServices.fondoAvance.byUsuario(id)
                    if (status === 200) {
                        setValues(data.data)
                    } else {
                        showHttpToast(status, { detail: data.message })
                        props.onHide?.()
                    }
                }
                load()
            } else {
                showHttpToast(400, { detail: 'Usuario no seleccionado' })
                props.onHide?.()
            }
        } else {
            setErrors({})
        }
    }, [id, props.show])

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        setValues((prev) => ({ ...prev, [name]: e.target.value }))
        errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    /** Maneje el evento submit del formulario. */
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (id) {
            setSubmitting(true)
            const _errors = validate(values)
            if (_errors) {
                setErrors(_errors)
            } else {
                const bodyParams = {
                    ...clean(
                        pick(values, ['id', 'monto_asignado', 'fuente_fondo', 'numero_devengado', 'actividad_id']),
                    ),
                    beneficiario_id: id,
                }
                const { status, data } = await ContabilidadServices.fondoAvance.update(bodyParams)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    onSuccess?.()
                }
            }
            setSubmitting(false)
        }
    }

    return (
        <Modal style={{ width: '25rem' }} {...props} closeOnBackdrop={false} keyboard={false}>
            <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Fondo en Avance</div>
            <form onSubmit={handleSubmit}>
                <div className='p-3'>
                    <div className='mb-2'>
                        <span className='fw-medium me-2'>Usuario:</span>
                        {values.usuario}
                        <br />
                        <span className='fw-medium me-2'>Fondo en Avance asignado:</span>
                        {values.id ? 'Si' : 'No'}
                    </div>
                    {values.id && (
                        <div className='mb-2 text-primary fst-italic' style={{ lineHeight: '1.25' }}>
                            <span className='bi-info-circle-fill' /> Modificar los datos actualizará el Fondo en Avance
                            asignado.
                        </div>
                    )}
                    <FieldGroup label='Fondos' feedback={errors.fuente_fondo} showFeedback={!!errors.fuente_fondo}>
                        <Select
                            options={fuenteFondoOptions}
                            name='fuente_fondo'
                            value={values.fuente_fondo}
                            onChange={handleChange}
                            isInvalid={!!errors.fuente_fondo}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Monto Asignado'
                        feedback={errors.monto_asignado}
                        showFeedback={!!errors.monto_asignado}
                    >
                        <Input
                            name='monto_asignado'
                            type='currency'
                            value={values.monto_asignado}
                            onChange={handleChange}
                            onFocus={(e) => parseInt(values.monto_asignado) === 0 && e.target.select()}
                            isInvalid={errors.monto_asignado}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Número Devengado'
                        feedback={errors.numero_devengado}
                        showFeedback={!!errors.numero_devengado}
                    >
                        <Input
                            name='numero_devengado'
                            value={values.numero_devengado}
                            onChange={handleChange}
                            isInvalid={!!errors.numero_devengado}
                        />
                    </FieldGroup>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        startIcon={<span className='bi-x-lg' />}
                        onClick={() => props.onHide?.()}
                        disabled={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type='submit'
                        startIcon={<span className='bi-floppy' />}
                        loading={submitting}
                        disabled={!id}
                    >
                        Guardar
                    </Button>
                </div>
            </form>
        </Modal>
    )
}
