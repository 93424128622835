import useHttpToast from 'hooks/useHttpToast'
import RecaudacionesServices from 'services/recaudaciones.service'

export default function useFactura() {
    const { showHttpToast } = useHttpToast()

    /**
     * Realiza la emisión de una factura.
     * @param {object} bodyData Datos de la factura.
     * @param {Function} onSuccess Callback que se ejecuta cuando la operación es exitosa.
     */
    const issue = async (bodyData, onSuccess = undefined) => {
        const { status, data } = await RecaudacionesServices.facturacion.issue(bodyData)
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            openPdf(data.data)
            onSuccess?.(data.data)
        }
    }

    /**
     * Abre el pdf de una factura en un nuevo tab.
     * @param {object} data Datos del pdf de la factura.
     * @param {string} data.mensaje Estado de la factura.
     * @param {string} data.ver_factura URL del pdf de la factura.
     */
    const openPdf = async (data) => {
        if (data) {
            if (['validada', 'offline'].includes(data.mensaje.toLowerCase())) {
                const url = data.ver_factura
                if (url) {
                    window.open(url, '_blank')
                } else {
                    showHttpToast(500, { detail: 'PDF de la factura no encontrado' })
                }
            } else {
                showHttpToast(500, { detail: 'La factura esta pendiente de validación' })
            }
        } else {
            showHttpToast(500, { detail: 'PDF de la factura no generado' })
        }
    }

    /**
     * Anula una factura.
     * @param {number} id ID de la factura.
     * @param {object} bodyData Datos de la anulación.
     * @param {Function} onSuccess Callback que se ejecuta cuando la operación es exitosa.
     */
    const cancel = async (id, bodyData, onSuccess) => {
        const { status, data } = await RecaudacionesServices.facturacion.cancel(id, bodyData)
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            onSuccess?.()
        }
    }

    /**
     * Paga la deuda de una factura.
     * @param {number} id ID de la deuda.
     * @param {Function} onSuccess Callback que se ejecuta cuando la operación es exitosa.
     */
    const payDeuda = async (id, onSuccess) => {
        const { status, data } = await RecaudacionesServices.facturacion.payDeuda(id)
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            openPdf(data.data)
            onSuccess?.()
        }
    }

    return { issue, openPdf, cancel, payDeuda }
}
