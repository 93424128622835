import useHttpToast from 'hooks/useHttpToast'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { clean, debounce, empty } from 'components/modular/utils'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { formatCurrency, pick } from 'utils'
import RecaudacionesServices from 'services/recaudaciones.service'
import ModalSelectCuenta from 'modules/recaudaciones/components/ModalSelectCuenta/ModalSelectCuenta'
import { InputIcon } from 'components/modular/InputIcon'

function validateCuenta(values) {
    const errors = {}

    if (!values.codigo.trim()) errors.codigo = 'Required'
    if (!values.id) {
        errors.nombre = 'Required'
        errors.unidad = 'Required'
    }
    if (!(values.precio > 0)) errors.precio = 'Debe ser mayor a cero'
    if (!(values.cantidad > 0)) errors.cantidad = 'Debe ser mayor a cero'
    if (values.cantidad > 2000) errors.cantidad = 'El valor máximo es 2000'

    return empty(errors) ? null : errors
}
const initialCuenta = {
    codigo: '',
    nombre: '',
    unidad: '',
    precio: '0.00',
    cantidad: '1',
}

export default function DatosCuenta({ innerRef, inputRefs, saveRef, updateTotales, ...props }) {
    const { showHttpToast } = useHttpToast()
    const [cuenta, setCuenta] = useState(initialCuenta)
    const [errors, setErrors] = useState({})
    const [cuentas, setCuentas] = useState([])
    const [isVisible, setIsVisible] = useState({ modalSelectCuenta: false, iconFieldCodigo: true })

    useEffect(() => {
        updateTotales?.((prev) => ({ ...prev, cuenta: cuenta.precio * cuenta.cantidad }))
    }, [cuenta.precio, cuenta.cantidad])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    /** Almacena los datos de la cuenta seleccionada. */
    const selectCuenta = async (cuenta) => {
        setCuenta({
            ...initialCuenta,
            ...clean(pick(cuenta, ['id', 'codigo', 'nombre', 'unidad', 'precio', 'tipo_monto'])),
        })
        setErrors({})
        setTimeout(() => {
            if (cuenta.tipo_monto === 'V') {
                inputRefs?.current['cuenta.precio']?.focus()
            } else {
                inputRefs?.current['cuenta.cantidad']?.focus({ focusVisible: true })
            }
        }, 250)
    }
    /**
     * Busca la cuenta para venta por caja.
     * @param {object} params Parámetros de búsqueda.
     * @param {string} params.codigo Código de cuenta.
     * @param {string} params.nombre Nombre de la cuenta.
     */
    const searchCuenta = useCallback(
        debounce(async (params = {}) => {
            const _params = clean(params)
            const { status, data } = await RecaudacionesServices.ventaPreValorados.listCuentas(_params)
            if (status === 200) {
                if (isVisible.modalSelectCuenta) {
                    setCuentas(data.data)
                } else {
                    if (data.data.length === 1 && !empty(_params)) {
                        selectCuenta(data.data[0])
                    } else {
                        setCuentas(data.data)
                        show('modalSelectCuenta')
                    }
                }
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }, 300),
        [isVisible.modalSelectCuenta],
    )

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value.toUpperCase()
        if (name === 'codigo' && cuenta.id) {
            setCuenta({ ...initialCuenta, [name]: value })
            setErrors({})
        } else {
            setCuenta((prev) => ({ ...prev, [name]: value }))
            errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
        }
    }
    /** Maneja el evento keyup de los inputs. */
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            const { name } = e.target
            if (name === 'codigo') {
                searchCuenta({ codigo: cuenta.codigo })
            } else if (['precio', 'cantidad'].includes(name)) {
                if (cuenta[name] > 0) {
                    inputRefs?.current['buttonTerminar']?.focus({ focusVisible: true })
                }
            }
        }
    }
    /** Maneja el evento keydown de los inputs. */
    const handleKeyDown = (e) => {
        if (e.key === ' ' || e.key === 'Spacebar') {
            if (e.target.name === 'codigo') {
                e.preventDefault()
            }
        }
    }
    /** Maneja la selección de una cuenta. */
    const handleSelectCuenta = async (selection) => {
        selectCuenta(selection)
        hide('modalSelectCuenta')
    }

    /** Valida los datos de la cuenta. */
    const validate = () => {
        const _errors = validateCuenta(cuenta)
        if (_errors) {
            setErrors(_errors)
        }
        return _errors
    }
    /** Restablece los datos de la cuenta a sus valores iniciales. */
    const reset = () => {
        setCuenta(initialCuenta)
    }
    /** Obtiene los datos de la cuenta. */
    const get = () => {
        return { ...cuenta }
    }

    return (
        <div {...props}>
            <div>
                <div className='bg-blue rounded text-white select-none mb-3' style={{ padding: '.75rem 1rem' }}>
                    <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                        CUENTA
                    </span>
                </div>
                <div className='px-3'>
                    <div className='row g-3'>
                        <FieldGroup
                            label='Código'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '10rem' }}
                        >
                            <InputIcon
                                startIcon={
                                    isVisible.iconFieldCodigo && !cuenta.codigo ? (
                                        <span className='bi-search text-secondary' />
                                    ) : undefined
                                }
                                onFocus={() => hide('iconFieldCodigo')}
                                onBlur={() => show('iconFieldCodigo')}
                            >
                                <Input
                                    ref={(el) => el && saveRef?.(el, `cuenta.${el.name}`)}
                                    name='codigo'
                                    value={cuenta.codigo}
                                    onChange={handleChange}
                                    onKeyUp={handleKeyUp}
                                    onKeyDown={handleKeyDown}
                                    isInvalid={errors.codigo}
                                    size='sm'
                                    autoFocus
                                />
                            </InputIcon>
                        </FieldGroup>
                        <FieldGroup label='Nombre' labelClassName='fw-medium' className='col mb-0'>
                            <Input defaultValue={cuenta.nombre} isInvalid={errors.nombre} size='sm' disabled />
                        </FieldGroup>
                        <FieldGroup label='Unidad' labelClassName='fw-medium' className='col-2 mb-0'>
                            <Input defaultValue={cuenta.unidad} isInvalid={errors.unidad} size='sm' disabled />
                        </FieldGroup>
                        <FieldGroup
                            label='Cantidad'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '7rem' }}
                        >
                            <Input
                                ref={(el) => el && saveRef?.(el, `cuenta.${el.name}`)}
                                type='number'
                                name='cantidad'
                                value={cuenta.cantidad}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onFocus={(e) => e.target.select()}
                                min={1}
                                className='text-end'
                                isInvalid={errors.cantidad}
                                size='sm'
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Precio Unit. (Bs)'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '9rem' }}
                        >
                            <Input
                                ref={(el) => el && saveRef?.(el, `cuenta.${el.name}`)}
                                type='currency'
                                name='precio'
                                value={cuenta.precio}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onFocus={(e) => e.target.select()}
                                className='text-end'
                                isInvalid={errors.precio}
                                size='sm'
                                disabled={cuenta.tipo_monto === 'F'}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Importe Total (Bs)'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '10rem' }}
                        >
                            <Input
                                name='total'
                                value={formatCurrency(cuenta.precio * cuenta.cantidad)}
                                onChange={() => {}}
                                isInvalid={errors.total}
                                className='text-end'
                                size='sm'
                                disabled
                            />
                        </FieldGroup>
                    </div>
                </div>
            </div>
            <ModalSelectCuenta
                show={isVisible.modalSelectCuenta}
                onHide={() => hide('modalSelectCuenta')}
                onExited={() => cuentas.length && setCuentas([])}
                cuentas={cuentas}
                onSelectCuenta={handleSelectCuenta}
                searchCuenta={searchCuenta}
                initialSearcherValue={cuenta.codigo}
            />
            <DatosCuentaRef ref={innerRef} validate={validate} reset={reset} get={get} />
        </div>
    )
}

const DatosCuentaRef = forwardRef(({ validate, reset, get }, ref) => {
    useImperativeHandle(ref, () => ({
        validateCuenta() {
            return validate()
        },
        resetCuenta() {
            reset()
        },
        getCuenta() {
            return get()
        },
    }))
    return null
})
