import { Button } from 'components/modular/Button'
import { DataTable } from 'components/modular/DataTable'
import { useMemo, useState } from 'react'
import { formatCurrency, formatDate } from 'components/modular/utils'
import useHttpToast from 'hooks/useHttpToast'
import { Confirm, Modal } from 'components/modular/Modal'
import RecaudacionesServices from 'services/recaudaciones.service'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { InputIcon } from 'components/modular/InputIcon'
import useFactura from 'modules/recaudaciones/hooks/useFactura'

/**
 * Componente para la gestión de deudas.
 *
 * @param {object} props Propiedades del componente.
 * @param {Function} props.onSuccessPay Callback que se ejecuta cuando el pago de una deuda es exitosa.
 * @param {Function} props.onSuccessExpire Callback que se ejecuta cuando se caduca una deuda exitosamente.
 */
export default function CobroDeudas({ onSuccessPay, onSuccessExpire, ...props }) {
    const { showHttpToast } = useHttpToast()
    const { payDeuda } = useFactura()
    const [deudas, setDeudas] = useState([])
    const [selected, setSelected] = useState(false)
    const [isVisible, setIsVisible] = useState({
        modalConfirmExpire: false,
        modalView: false,
        iconFieldDocumento: true,
        listDeudas: false,
    })
    const [searchValue, setSearchValue] = useState('')
    const [isLoading, setIsLoading] = useState({ searchingDeudas: false, payingDeuda: false })

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))
    const start = (name) => setIsLoading((prev) => ({ ...prev, [name]: true }))
    const end = (name) => setIsLoading((prev) => ({ ...prev, [name]: false }))

    /** Obtiene las deudas de una persona. */
    const searchDeudas = async (documento) => {
        start('searchingDeudas')
        show('listDeudas')
        const params = {}
        if (/^[a-zA-Z0-9]+-[a-zA-Z0-9]{2}$/.test(documento)) {
            const parts = documento.split('-')
            params['documento'] = parts[0]
            params['complemento'] = parts[1]
        } else {
            params['documento'] = documento
        }
        let length = null
        const { status, data } = await RecaudacionesServices.facturacion.listDeudas(params)
        if (status === 200) {
            setDeudas(data.data)
            length = data.data.length
        } else {
            setDeudas([])
            showHttpToast(status, { detail: data.message })
        }
        end('searchingDeudas')
        return length
    }

    const handleAction = (action, rowData = null) => {
        if (action === 'expire') {
            setSelected(rowData)
            show('modalConfirmExpire')
        } else if (action === 'pay') {
            handlePay(rowData)
        } else if (action === 'view') {
            setSelected(rowData)
            show('modalView')
        }
    }

    const pagarCellTemplate = ({ data }) => (
        <div className='text-center'>
            <Button
                variant='success'
                title='Pagar'
                disabled={isLoading.payingDeuda}
                onClick={() => handleAction('pay', data)}
            >
                <span>
                    <i className='fa fa-money fa-lg' />
                </span>
            </Button>
        </div>
    )
    const columns = useMemo(
        () => [
            {
                id: 'col_cuenta',
                header: 'Cuenta',
                headerClassName: 'bg-secondary-subtle',
                columns: [
                    {
                        field: 'cuenta',
                        header: 'Nombre',
                        headerClassName: 'bg-secondary-subtle',
                    },
                ],
            },
            {
                id: 'col_persona',
                header: 'Persona',
                headerClassName: 'bg-secondary-subtle',
                columns: [
                    {
                        field: 'persona_documento',
                        header: 'Documento',
                        headerClassName: 'bg-secondary-subtle',
                        cellClassName: 'text-center',
                    },
                    {
                        field: 'persona',
                        header: 'Apellidos y Nombres',
                        headerClassName: 'bg-secondary-subtle',
                    },
                ],
            },
            {
                id: 'options',
                header: 'Varios',
                headerClassName: 'bg-secondary-subtle',
                columns: [
                    {
                        field: 'periodo',
                        header: 'Periodo',
                        headerClassName: 'bg-secondary-subtle',
                        cellClassName: 'text-center',
                    },
                    {
                        field: 'total',
                        header: 'Importe Total (Bs)',
                        headerClassName: 'bg-secondary-subtle',
                        cell: ({ value }) => formatCurrency(value),
                        cellClassName: 'text-end',
                    },
                    {
                        id: 'pagar',
                        header: 'Pagar',
                        headerClassName: 'bg-secondary-subtle',
                        cell: pagarCellTemplate,
                    },
                ],
            },
        ],
        [],
    )

    /** Paga una deuda. */
    const handlePay = async (deuda) => {
        start('payingDeuda')
        await payDeuda({ movimiento_id: deuda.id }, async () => {
            searchDeudas(deuda.persona_documento)
            onSuccessPay?.()
        })
        end('payingDeuda')
    }
    /** Caduca la deuda seleccionada. */
    const handleExpire = async ({ setLoading }) => {
        setLoading(true)
        hide('modalConfirmExpire')
        // if (selected.id) {
        //     await expire(selected.id, async () => {
        //         const length = await searchDeudas(persona.id)
        //         onSuccessExpire?.({ lengthDeudas: length })
        //     })
        // }
        setLoading(false)
    }
    const clearSelected = () => {
        setSelected(null)
    }

    return (
        <div {...props}>
            <div className='bg-blue rounded text-white select-none mb-3' style={{ padding: '.75rem 1rem' }}>
                <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                    COBRO DE DEUDAS
                </span>
            </div>
            <div className='px-3'>
                <FieldGroup label='Documento:' labelClassName='fw-bold' className='mb-0' inline>
                    <InputIcon
                        className='w-100'
                        startIcon={
                            isVisible.iconFieldDocumento && !searchValue ? (
                                <span className='bi-search text-secondary' />
                            ) : undefined
                        }
                        onFocus={() => hide('iconFieldDocumento')}
                        onBlur={() => show('iconFieldDocumento')}
                    >
                        <Input
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter' && !isLoading.searchingDeudas) {
                                    searchDeudas(searchValue)
                                }
                            }}
                            size='sm'
                        />
                    </InputIcon>
                </FieldGroup>
                {isVisible.listDeudas && (
                    <div className='mb-3'>
                        {isLoading.searchingDeudas ? (
                            <div className='text-center'>
                                <div className='spinner-border' role='status'>
                                    <span className='visually-hidden'>Loading...</span>
                                </div>
                            </div>
                        ) : deudas.length ? (
                            <DataTable
                                values={deudas}
                                columns={columns}
                                defaultAlignHeader='center'
                                tableClassName='table-bordered border-secondary'
                                emptyMessage='Sin deudas encontradas'
                            />
                        ) : (
                            <div className='fw-medium text-center'>Sin deudas encontradas</div>
                        )}
                    </div>
                )}
            </div>
            <Confirm
                show={isVisible.modalConfirmExpire}
                onAccept={handleExpire}
                onReject={() => hide('modalConfirmExpire')}
                onExited={clearSelected}
                style={{ width: '35rem' }}
                className='rounded-4 overflow-hidden'
                headerClassName='bg-secondary-subtle'
                footerClassName='bg-secondary-subtle text-center'
            >
                <span className='fw-medium fs-4' style={{ lineHeight: 1.25 }}>
                    Esta seguro de <span className='text-primary'>CADUCAR</span> la deuda de: "{selected?.persona}"
                </span>
            </Confirm>
            <Modal
                show={isVisible.modalView}
                onHide={() => hide('modalView')}
                onExited={clearSelected}
                style={{ width: '60rem' }}
            >
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Detalle</div>
                <div className='p-3'>
                    <div className='border border-dark rounded'>
                        <div className='bg-blue text-white fw-medium p-2'>DEUDA</div>
                        <div className='p-2'>
                            <div className='row'>
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    FECHA:
                                </div>
                                <div className='col'>
                                    {selected?.fecha ? formatDate(selected.fecha, 'd/m/Y H:i:s') : ''}
                                </div>
                                <div className='fw-medium text-end' style={{ width: '9rem' }}>
                                    IMPORTE (BS):
                                </div>
                                <div className='fw-medium' style={{ width: '10rem' }}>
                                    {selected?.precio ? formatCurrency(selected.precio) : ''}
                                </div>
                            </div>
                            <hr className='my-2' />
                            <div className='row'>
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    PERSONA:
                                </div>
                                <div className='col'>{selected?.persona}</div>
                                <div className='col-auto'>
                                    <span className='fw-medium me-3'>DOCUMENTO:</span>
                                    {selected?.documento}
                                </div>
                                <div className='fw-medium text-end' style={{ width: '6rem' }}>
                                    CODIGO:
                                </div>
                                <div style={{ width: '10rem' }}>{selected?.codigo}</div>
                            </div>
                            <hr className='my-2' />
                            <div className='row'>
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    CUENTA:
                                </div>
                                <div className='col'>{selected?.cuenta}</div>
                                <div className='fw-medium text-end' style={{ width: '6rem' }}>
                                    CODIGO:
                                </div>
                                <div style={{ width: '10rem' }}>{selected?.cuenta_codigo}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        startIcon={<span className='bi-x-lg' />}
                        onClick={() => hide('modalView')}
                    >
                        Cerrar
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
