import Header from 'Component/Header'
import Loader from 'components/Loader'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'components/modular/Button'
import { Checkbox } from 'components/modular/Checkbox'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Select } from 'components/modular/Select'
import { classNames, clean, empty } from 'components/modular/utils'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import RecaudacionesServices from 'services/recaudaciones.service'
import { formatDate, pick } from 'utils'
import { verifyAccess } from 'utils/verifyAccess'
import useReportes from '../hooks/useReportes'

const reporteOptions = [
    {
        label: 'Informe Consolidado por Unidad y Cuenta',
        value: 'reportPorUnidadCuenta',
        required: ['reportPorUnidadCuentaValores', 'reportPorUnidadCuentaFacturas'],
        operator: 'OR',
    },
    {
        label: 'Informe Consolidado por Concepto',
        value: 'reportPorConcepto',
        required: ['reportPorConceptoValores', 'reportPorConceptoFacturas'],
        operator: 'OR',
    },
    {
        label: 'Informe Consolidado por Unidad',
        value: 'reportPorUnidad',
        required: ['reportPorUnidadValores', 'reportPorUnidadFacturas'],
        operator: 'OR',
    },
    {
        label: 'Informe Consolidado Institucional',
        value: 'reportInstitucional',
        required: ['reportInstitucionalValores', 'reportInstitucionalFacturas'],
        operator: 'OR',
    },
    {
        label: 'Informe Cobro Comisión Bancaria',
        value: 'reportComisionBancaria',
        required: ['reportComisionBancariaValores', 'reportComisionBancariaFacturas'],
        operator: 'OR',
    },
]
const reporteValoresOptions = [
    {
        label: 'Informe Distribución Aportes',
        value: 'reportAportesEstudiantes',
        required: ['reportAportesEstudiantes'],
    },
    {
        label: 'Detalle de Recaudaciones según Recibos',
        value: 'reportRecibosPorCajero',
        required: ['reportRecibosPorCajero'],
    },
    {
        label: 'Detalle de Recaudaciones UTIs',
        value: 'reportRecibosPorCuenta',
        required: ['reportRecibosPorCuenta'],
    },
    {
        label: 'Detalle de Recaudaciones Tipos de Estudiante',
        value: 'reportTiposEstudiantes',
        required: ['reportTiposEstudiantes'],
    },
    {
        label: 'Recibos Anulados',
        value: 'reportRecibosAnulados',
        required: ['reportRecibosAnulados'],
    },
    {
        label: 'Informe Resumen Recibos',
        value: 'reportRecibosDetallados',
        required: ['reportRecibosDetallados'],
    },
    {
        label: 'Pre Valorados',
        value: 'reportPreValorados',
        required: ['reportPreValorados'],
    },
]
const reporteFacturasOptions = [
    {
        label: 'Libro Ventas',
        value: 'reportLibroVentas',
        required: ['reportLibroVentas'],
    },
    {
        label: 'Detalle de Recaudaciones según Facturas',
        value: 'reportFacturasPorCajero',
        required: ['reportFacturasPorCajero'],
    },
    {
        label: 'Informe Resumen Facturas',
        value: 'reportFacturasDetallados',
        required: ['reportFacturasDetallados'],
    },
]
const reporteSettings = {
    reportPorUnidadCuenta: {
        tipo: 'B',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: true,
        },
    },
    reportPorConcepto: {
        tipo: 'B',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: true,
        },
    },
    reportPorUnidad: {
        tipo: 'B',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: true,
        },
    },
    reportInstitucional: {
        tipo: 'B',
        isEnabled: {
            advancedFilters: false,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: false,
        },
    },
    reportComisionBancaria: {
        tipo: 'B',
        isEnabled: {
            advancedFilters: false,
            checkCaja: false,
            checkElectronico: false,
            buttonExcel: false,
        },
        filters: {
            electronico: true,
            caja: false,
        },
    },
    reportAportesEstudiantes: {
        tipo: 'V',
        isEnabled: {
            advancedFilters: false,
            checkCaja: false,
            checkElectronico: false,
            buttonExcel: false,
        },
        filters: {
            electronico: false,
            caja: false,
        },
    },
    reportRecibosPorCajero: {
        tipo: 'V',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: true,
        },
    },
    reportRecibosPorCuenta: {
        tipo: 'V',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: true,
        },
    },
    reportTiposEstudiantes: {
        tipo: 'V',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: true,
        },
    },
    reportRecibosAnulados: {
        tipo: 'V',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: true,
        },
    },
    reportPreValorados: {
        tipo: 'V',
        isEnabled: {
            advancedFilters: true,
            checkCaja: false,
            checkElectronico: false,
            buttonExcel: true,
        },
        filters: {
            electronico: false,
            caja: false,
        },
    },
    reportRecibosDetallados: {
        tipo: 'V',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: false,
        },
    },
    reportLibroVentas: {
        tipo: 'F',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: false,
        },
    },
    reportFacturasPorCajero: {
        tipo: 'F',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: false,
        },
    },
    reportFacturasDetallados: {
        tipo: 'F',
        isEnabled: {
            advancedFilters: true,
            checkCaja: true,
            checkElectronico: true,
            buttonExcel: false,
        },
    },
}
const currentDate = formatDate(new Date())
const initialFilters = {
    caja: false,
    electronico: false,
    valores: false,
    facturas: false,
    estructura_id: '',
    usuario_id: '',
    fecha_inicio: currentDate,
    fecha_fin: currentDate,
    rubro_id: '',
    unidad_id: '',
    concepto_id: '',
    cuenta_id: '',
}
const initialIsEnable = {
    fieldEstructura: false,
    fieldUsuario: false,
    advancedFilters: false,
    checkValores: false,
    checkFacturas: false,
    checkCaja: false,
    checkElectronico: false,
}

export default function Reportes() {
    const { showHttpToast } = useHttpToast()
    const reportes = useReportes()
    const lastEstructura = useRef('') // Almacena la ultima estructura seleccionada antes que los filtros avanzados se oculten.
    const initAdvancedFilters = useRef(false) // Especifica si los filtros avanzados ya se mostraron por lo menos una vez.
    const authSettings = useRef({})
    const { user, permissions } = useSelector((state) => state.auth)
    const [selectedReporte, setSelectedReporte] = useState('')
    const [filters, setFilters] = useState(initialFilters)
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
    const [excelFileName, setExcelFileName] = useState('')
    const [isEnabled, setIsEnabled] = useState(initialIsEnable)
    const [isLoading, setIsLoading] = useState({ buttonPdf: false, buttonExcel: false, initialSettings: true })
    const [isVisible, setIsVisible] = useState({ checksTipos: false })
    const [errors, setErrors] = useState({})

    const [estructuras, setEstructuras] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const [rubros, setRubros] = useState([])
    const [unidades, setUnidades] = useState([])
    const [conceptos, setConceptos] = useState([])
    const [cuentas, setCuentas] = useState([])

    useEffect(() => {
        let valores = false
        let facturas = false
        let _selectedReporte = ''

        for (const report in reporteSettings) {
            let settings = {}
            if (!_selectedReporte) _selectedReporte = report
            const tipo = reporteSettings[report].tipo
            if (tipo === 'B') {
                const canValores = permissions[`${report}Valores`]
                const canFacturas = permissions[`${report}Facturas`]
                const permissionLevel = Math.max(canValores?.level ?? 1, canFacturas?.level ?? 1)
                if (canValores) valores = true
                if (canFacturas) facturas = true
                settings = {
                    permissionLevel,
                    canValores: !!canValores,
                    canFacturas: !!canFacturas,
                }
            } else if (tipo === 'V') {
                const permission = permissions[report]
                const permissionLevel = permission?.level ?? 1
                if (permission) valores = true
                settings = {
                    permissionLevel,
                    canValores: !!permission,
                    canFacturas: false,
                }
            } else if (tipo === 'F') {
                const permission = permissions[report]
                const permissionLevel = permission?.level ?? 1
                if (permission) facturas = true
                settings = {
                    permissionLevel,
                    canValores: false,
                    canFacturas: !!permission,
                }
            }
            authSettings.current[report] = settings
        }

        setIsVisible((prev) => ({ ...prev, checksTipos: valores && facturas }))
        lastEstructura.current = authSettings.current[_selectedReporte]?.permissionLevel < 3 ? user.estructura : ''
        updateSelectedReport(_selectedReporte)
        console.log(_selectedReporte)
        console.log(authSettings.current)
        console.log(lastEstructura.current)
    }, [user])

    /** Actualiza el reporte seleccionado. */
    const updateSelectedReport = (report) => {
        const lastSettings = selectedReporte ? authSettings.current[selectedReporte] : { permissionLevel: 3 }
        const newSettings = authSettings.current[report]
        setSelectedReporte(report)

        const estructuraId = lastSettings.permissionLevel > newSettings.permissionLevel ? user.estructura : null
        const usuarioId =
            lastSettings.permissionLevel > newSettings.permissionLevel
                ? newSettings.permissionLevel === 1
                    ? user.id
                    : newSettings.permissionLevel === 2
                    ? ''
                    : null
                : null
        const _filters = clean(
            {
                ...reporteSettings[report].filters,
                estructura_id: estructuraId,
                usuario_id: usuarioId,
                valores: newSettings.canValores ? null : false,
                facturas: newSettings.canFacturas ? null : false,
            },
            [null],
        )
        // Actualizar valores de filtros
        setFilters((prev) => ({ ...prev, ..._filters }))
        const _isEnabled = {
            ...reporteSettings[report].isEnabled,
            fieldEstructura: newSettings.permissionLevel >= 3,
            fieldUsuario: newSettings.permissionLevel >= 2,
            checkValores: newSettings.canValores,
            checkFacturas: newSettings.canFacturas,
        }
        // Actualizar filtros habilitados
        setIsEnabled((prev) => ({ ...prev, ..._isEnabled }))

        // Actualizar datos de los filtros
        if (report) {
            Promise.all([
                loadEstructuras({ action: report.toUpperCase() }),
                loadUsuarios({ action: report.toUpperCase() }),
            ]).finally(() => {
                isLoading.initialSettings && setIsLoading((prev) => ({ ...prev, initialSettings: false }))
            })
        } else {
            estructuras && setEstructuras([])
            usuarios && setUsuarios([])
        }
    }

    /** Carga la lista de estructuras para los filtros. */
    const loadEstructuras = async (params = {}) => {
        const { status, data } = await RecaudacionesServices.filter.listEstructuras(params)
        if (status === 200) {
            setEstructuras(data.data)
        } else {
            estructuras.length && setEstructuras([])
        }
    }
    /**
     * Carga la lista de usuarios para los filtros.
     * @param {number | null} estructura_id ID de estructura.
     */
    const loadUsuarios = async (params = {}) => {
        const _params = clean(params)
        const { status, data } = await RecaudacionesServices.filter.listUsuarios(_params)
        if (status === 200) {
            setUsuarios(data.data)
        } else {
            usuarios.length && setUsuarios([])
        }
    }
    /** Carga la lista de rubros para los filtros. */
    const loadRubros = async () => {
        const { status, data } = await RecaudacionesServices.reportes.listRubros({
            sort: 'descripcion',
            all: 1,
        })
        if (status === 200) {
            setRubros(data.data)
        } else {
            rubros.length && setRubros([])
        }
    }
    /**
     * Carga la lista de unidades para los filtros.
     * @param {number | null} estructura_id ID de estructura.
     */
    const loadUnidades = async (params = {}) => {
        const _params = clean(params)
        const { status, data } = await RecaudacionesServices.filter.listUnidades(_params)
        if (status === 200) {
            setUnidades(data.data)
        } else {
            unidades.length && setUnidades([])
        }
    }
    /**
     * Carga la lista de rubros para los filtros.
     * @param {number | null} rubro_id ID de rubro.
     */
    const loadConceptos = async (rubro_id = null) => {
        const params = clean({ 'filter[rubro_id]': rubro_id, sort: 'descripcion', all: 1 })
        const { status, data } = await RecaudacionesServices.reportes.listConceptos(params)
        if (status === 200) {
            setConceptos(data.data)
        } else {
            conceptos.length && setConceptos([])
        }
    }
    /**
     * Carga la lista de cuentas para los filtros.
     * @param {object | null} params Filtros de búsqueda, acepta los siguientes filtros:
     * - estructura_id {number}: ID de estructura.
     * - unidad_id {number}: ID de unidad.
     * - concepto_id {number}: ID de concepto.
     * Si es null obtiene todas las cuentas.
     */
    const loadCuentas = async (params = null) => {
        const _params = clean(params)
        const { status, data } = await RecaudacionesServices.filter.listCuentas(_params)
        if (status === 200) {
            setCuentas(data.data)
        } else {
            cuentas.length && setCuentas([])
        }
    }

    const handleChangeFilters = (e) => {
        const { name, value } = e.target
        if (name === 'estructura_id') {
            setFilters((prev) => ({ ...prev, [name]: value, usuario_id: '', unidad_id: '', cuenta_id: '' }))
            loadUsuarios({ action: selectedReporte.toUpperCase(), estructura_id: value })
            if (showAdvancedFilters) {
                loadUnidades({ action: selectedReporte.toUpperCase(), estructura_id: value })
                loadCuentas({
                    ...pick(filters, ['unidad_id', 'concepto_id']),
                    estructura_id: value,
                    action: selectedReporte.toUpperCase(),
                })
            }
        } else if (['unidad_id', 'concepto_id'].includes(name)) {
            setFilters((prev) => ({ ...prev, [name]: value, cuenta_id: '' }))
            loadCuentas({
                ...pick(filters, ['estructura_id', 'unidad_id', 'concepto_id']),
                [name]: value,
                action: selectedReporte.toUpperCase(),
            })
        } else if (name === 'rubro_id') {
            setFilters((prev) => ({ ...prev, [name]: value, concepto_id: '' }))
            loadConceptos(value)
        } else if (name === 'caja') {
            setFilters((prev) => ({ ...prev, [name]: value, electronico: value ? !value : prev.electronico }))
        } else if (name === 'electronico') {
            setFilters((prev) => ({ ...prev, [name]: value, caja: value ? !value : prev.caja }))
        } else if (name === 'valores') {
            setFilters((prev) => ({ ...prev, [name]: value, facturas: value ? !value : prev.facturas }))
        } else if (name === 'facturas') {
            setFilters((prev) => ({ ...prev, [name]: value, valores: value ? !value : prev.valores }))
        } else {
            setFilters((prev) => ({ ...prev, [name]: value }))
        }
    }
    const handleChangeAdvancedFilters = (e) => {
        setShowAdvancedFilters(e.target.checked)
        if (e.target.checked) {
            /* 
                La primera vez que se muestre los filtros avanzados o si la estructura seleccionada cambio
                cargar unidades y cuentas
            */
            if (!initAdvancedFilters.current || filters.estructura_id !== lastEstructura.current) {
                lastEstructura.current = filters.estructura_id
                loadUnidades({ action: selectedReporte.toUpperCase(), estructura_id: filters.estructura_id })
                loadCuentas({
                    ...pick(filters, ['estructura_id', 'unidad_id', 'concepto_id']),
                    action: selectedReporte.toUpperCase(),
                })
            }
            // La primera vez que se muestre los filtros avanzados cargar rubros y conceptos
            if (!initAdvancedFilters.current) {
                loadRubros()
                loadConceptos()
                initAdvancedFilters.current = true
            }
        }
    }

    const validateFilters = (values) => {
        const _errors = {}
        if (!values.fecha_inicio) _errors.fecha_inicio = 'Requerido'
        if (!values.fecha_fin) _errors.fecha_fin = 'Requerido'
        if (values.fecha_inicio && values.fecha_fin) {
            if (values.fecha_fin < values.fecha_inicio) {
                _errors.fecha_inicio = 'Fecha invalida'
                _errors.fecha_fin = 'Fecha invalida'
            }
        }
        return empty(_errors) ? null : _errors
    }

    const handleGeneratePdf = async () => {
        setIsLoading((prev) => ({ ...prev, buttonPdf: true }))
        const _errors = validateFilters(filters)
        if (_errors) {
            setErrors(_errors)
        } else {
            const estructura = filters.estructura_id
                ? estructuras.find((e) => String(e.id) === filters.estructura_id)
                : null
            const usuario = filters.usuario_id ? usuarios.find((u) => String(u.id) === filters.usuario_id) : null
            const rubro = filters.rubro_id ? rubros.find((r) => String(r.id) === filters.rubro_id) : null
            const unidad = filters.unidad_id ? unidades.find((u) => String(u.id) === filters.unidad_id) : null
            const concepto = filters.concepto_id ? conceptos.find((c) => String(c.id) === filters.concepto_id) : null
            const cuenta = filters.cuenta_id ? cuentas.find((c) => String(c.id) === filters.cuenta_id) : null
            const reportParams = {
                usuario: user.name,
                unidad: user.unidad,
                isAdmin: isEnabled.fieldEstructura,
                modePrint: false,
                showAdvancedFilters,
                filters: {
                    fechaInicio: filters.fecha_inicio.split('-').reverse().join('-'),
                    fechaFin: filters.fecha_fin.split('-').reverse().join('-'),
                    electronico: filters.electronico,
                    caja: filters.caja,
                    usuario: usuario ? `${usuario.apellidos} ${usuario.nombres}` : 'Todos',
                    estructura: estructura?.descripcion ?? 'Todos',
                    rubro: rubro?.descripcion ?? 'Todos',
                    unidad: unidad?.descripcion ?? 'Todos',
                    concepto: concepto?.descripcion ?? 'Todos',
                    cuenta: cuenta?.nombre ?? 'Todos',
                },
            }
            try {
                switch (selectedReporte) {
                    case 'reportPorUnidadCuenta':
                        await reportes.consolidado.porUnidadCuenta(filters, {
                            reportParams,
                            diezmo: estructura?.diezmo,
                        })
                        break
                    case 'reportPorConcepto':
                        await reportes.consolidado.porConcepto(filters, { reportParams })
                        break
                    case 'reportPorUnidad':
                        await reportes.consolidado.porUnidad(filters, { reportParams })
                        break
                    case 'reportInstitucional':
                        await reportes.consolidado.institucional(filters, { reportParams })
                        break
                    case 'reportComisionBancaria':
                        await reportes.cobroComisionBancaria(filters, { reportParams })
                        break
                    case 'reportAportesEstudiantes':
                        await reportes.distribucionAportes(filters, { reportParams })
                        break
                    case 'reportRecibosPorCajero':
                        await reportes.recibos.porCajero(filters, { reportParams })
                        break
                    case 'reportRecibosPorCuenta':
                        await reportes.recibos.porCuenta(filters, { reportParams })
                        break
                    case 'reportTiposEstudiantes':
                        await reportes.recibos.deTiposEstudiante(filters, { reportParams })
                        break
                    case 'reportRecibosAnulados':
                        await reportes.recibos.anulados(filters, { reportParams })
                        break
                    case 'reportPreValorados':
                        await reportes.preValorados(filters, { reportParams })
                        break
                    case 'reportRecibosDetallados':
                        await reportes.recibos.detallados(filters, { reportParams })
                        break
                    case 'reportLibroVentas':
                        await reportes.libroVentas(filters, { reportParams })
                        break
                    case 'reportFacturasPorCajero':
                        await reportes.facturas.porCajero(filters, { reportParams })
                        break
                    case 'reportFacturasDetallados':
                        await reportes.facturas.detallados(filters, { reportParams })
                        break

                    default:
                        break
                }
            } catch (error) {
                showHttpToast(500, { detail: 'Ocurrió un error al generar el PDF' })
            }
        }
        setIsLoading((prev) => ({ ...prev, buttonPdf: false }))
    }

    const handleGenerateExcel = async () => {
        setIsLoading((prev) => ({ ...prev, buttonPdf: true }))
        const _errors = validateFilters(filters)
        if (_errors) {
            setErrors(_errors)
        } else {
            const estructura = filters.estructura_id
                ? estructuras.find((e) => String(e.id) === filters.estructura_id)
                : null
            const usuario = filters.usuario_id ? usuarios.find((u) => String(u.id) === filters.usuario_id) : null
            const rp = {
                filters: {
                    usuario: usuario ? `${usuario.apellidos} ${usuario.nombres}` : 'Todos',
                    fechaInicio: filters.fecha_inicio.split('-').reverse().join('-'),
                    fechaFin: filters.fecha_fin.split('-').reverse().join('-'),
                },
            }
            const reportParams = [rp.filters.usuario, rp.filters.fechaInicio, rp.filters.fechaFin]
            try {
                switch (selectedReporte) {
                    case 'reportPorUnidadCuenta':
                        await reportes.consolidado.porUnidadCuenta(filters, {
                            type: 'excel',
                            reportParams,
                            diezmo: estructura?.diezmo,
                            fileName: excelFileName,
                        })
                        break
                    case 'reportPorConcepto':
                        await reportes.consolidado.porConcepto(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'reportPorUnidad':
                        await reportes.consolidado.porUnidad(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'reportRecibosPorCajero':
                        await reportes.recibos.porCajero(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'reportRecibosPorCuenta':
                        await reportes.recibos.porCuenta(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'reportTiposEstudiantes':
                        await reportes.recibos.deTiposEstudiante(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'reportRecibosAnulados':
                        await reportes.recibos.anulados(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'reportPreValorados':
                        await reportes.preValorados(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break

                    default:
                        break
                }
            } catch (error) {
                showHttpToast(500, { detail: 'Ocurrió un error al generar el Excel' })
            }
        }
        setIsLoading((prev) => ({ ...prev, buttonPdf: false }))
    }

    if (isLoading.initialSettings) {
        return <Loader />
    }

    return (
        <div>
            <Header title='REPORTE DE MOVIMIENTOS' />
            <div className='d-flex justify-content-center'>
                <div className='container shadow-sm pt-5 p-4 bg-body rounded'>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='w-75'>
                            <div
                                className='position-relative rounded mb-5'
                                style={{ border: '1px solid #ccc', padding: '2.5rem 3rem 1.5rem 3rem' }}
                            >
                                <span
                                    className='fw-medium fs-5 bg-white px-4 py-1 position-absolute'
                                    style={{ top: '-1.25rem', left: '1.5rem' }}
                                >
                                    Escoja el Tipo de Reporte
                                </span>
                                <div className='d-flex gap-3'>
                                    <label className='d-flex gap-2 align-items-center'>
                                        <Checkbox
                                            name='electronico'
                                            value={filters.electronico}
                                            onChange={handleChangeFilters}
                                            className='border-gray'
                                            style={{ fontSize: '1.25rem' }}
                                            disabled={!isEnabled.checkElectronico}
                                        />{' '}
                                        <span style={{ userSelect: 'none' }}>Reporte de Cobros Electrónicos</span>
                                    </label>
                                    <label className='d-flex gap-2 align-items-center'>
                                        <Checkbox
                                            name='caja'
                                            value={filters.caja}
                                            onChange={handleChangeFilters}
                                            className='border-gray'
                                            style={{ fontSize: '1.25rem' }}
                                            disabled={!isEnabled.checkCaja}
                                        />{' '}
                                        <span style={{ userSelect: 'none' }}>Reporte de Cobros en Caja Efectivo</span>
                                    </label>
                                </div>
                                <hr />
                                {isVisible.checksTipos && (
                                    <div className='d-flex gap-3 mb-3'>
                                        <label className='d-flex gap-2 align-items-center'>
                                            <Checkbox
                                                name='valores'
                                                value={filters.valores}
                                                onChange={handleChangeFilters}
                                                className='border-gray'
                                                style={{ fontSize: '1.25rem' }}
                                                disabled={!isEnabled.checkValores}
                                            />{' '}
                                            <span style={{ userSelect: 'none' }}>Reporte de Valores</span>
                                        </label>
                                        <label className='d-flex gap-2 align-items-center'>
                                            <Checkbox
                                                name='facturas'
                                                value={filters.facturas}
                                                onChange={handleChangeFilters}
                                                className='border-gray'
                                                style={{ fontSize: '1.25rem' }}
                                                disabled={!isEnabled.checkFacturas}
                                            />{' '}
                                            <span style={{ userSelect: 'none' }}>Reporte de Facturas</span>
                                        </label>
                                    </div>
                                )}
                                {reporteOptions
                                    .filter(
                                        (option) =>
                                            empty(option.required) || verifyAccess(option.required, option.operator),
                                    )
                                    .map((option) => (
                                        <div key={option.value}>
                                            <label className='d-inline-flex gap-2 align-items-center mb-1 select-none'>
                                                <input
                                                    type='radio'
                                                    name='reporte'
                                                    value={option.value}
                                                    checked={selectedReporte === option.value}
                                                    onChange={(e) => updateSelectedReport(e.target.value)}
                                                    className='form-check-input border-gray m-0'
                                                    style={{ height: '1.25rem', width: '1.25rem' }}
                                                />
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                <hr />
                                <div>
                                    {reporteValoresOptions
                                        .filter(
                                            (option) =>
                                                empty(option.required) ||
                                                verifyAccess(option.required, option.operator),
                                        )
                                        .map((option) => (
                                            <div key={option.value}>
                                                <label className='d-inline-flex gap-2 align-items-center mb-1 select-none'>
                                                    <input
                                                        type='radio'
                                                        name='reporte'
                                                        value={option.value}
                                                        checked={selectedReporte === option.value}
                                                        onChange={(e) => updateSelectedReport(e.target.value)}
                                                        className='form-check-input border-gray m-0'
                                                        style={{ height: '1.25rem', width: '1.25rem' }}
                                                    />
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    {isVisible.checksTipos && <hr />}
                                    {reporteFacturasOptions
                                        .filter(
                                            (option) =>
                                                empty(option.required) ||
                                                verifyAccess(option.required, option.operator),
                                        )
                                        .map((option) => (
                                            <div key={option.value}>
                                                <label className='d-inline-flex gap-2 align-items-center mb-1 select-none'>
                                                    <input
                                                        type='radio'
                                                        name='reporte'
                                                        value={option.value}
                                                        checked={selectedReporte === option.value}
                                                        onChange={(e) => updateSelectedReport(e.target.value)}
                                                        className='form-check-input border-gray m-0'
                                                        style={{ height: '1.25rem', width: '1.25rem' }}
                                                    />
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className='row g-1 mb-3'>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Dirección Administrativa:
                                        </div>
                                    }
                                    className='col-12 mb-0'
                                    inline
                                >
                                    <Select
                                        options={estructuras}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.codigo} ${option.descripcion}`}
                                        name='estructura_id'
                                        value={filters.estructura_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                        disabled={!isEnabled.fieldEstructura}
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Usuario:
                                        </div>
                                    }
                                    className='col-12 mb-0'
                                    inline
                                >
                                    <Select
                                        options={usuarios}
                                        optionValue='id'
                                        optionTemplate={(option) =>
                                            `${option.apellidos} ${option.nombres}${
                                                option.electronico ? ' - ' + option.estructura : ''
                                            }`
                                        }
                                        name='usuario_id'
                                        value={filters.usuario_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                        disabled={!isEnabled.fieldUsuario}
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Fecha desde:
                                        </div>
                                    }
                                    className='col-6 mb-0'
                                    inline
                                >
                                    <Input
                                        type='date'
                                        name='fecha_inicio'
                                        value={filters.fecha_inicio}
                                        onChange={handleChangeFilters}
                                        className='form-control-sm'
                                        isInvalid={!!errors.fecha_inicio}
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Fecha hasta:
                                        </div>
                                    }
                                    className='col-6 mb-0'
                                    inline
                                >
                                    <Input
                                        type='date'
                                        name='fecha_fin'
                                        value={filters.fecha_fin}
                                        onChange={handleChangeFilters}
                                        className='form-control-sm'
                                        isInvalid={!!errors.fecha_fin}
                                    />
                                </FieldGroup>
                            </div>
                            <label
                                className='d-flex gap-2 align-items-center p-1 mb-3 rounded'
                                style={{ backgroundColor: '#ccc', userSelect: 'none' }}
                            >
                                <input
                                    type='checkbox'
                                    className='form-check-input mt-0'
                                    style={{ height: '1.25rem', width: '1.25rem' }}
                                    checked={showAdvancedFilters}
                                    onChange={handleChangeAdvancedFilters}
                                    disabled={!isEnabled.advancedFilters}
                                />
                                <span className={isEnabled.advancedFilters ? undefined : 'text-secondary'}>
                                    Filtros de Reporte Avanzado
                                </span>
                            </label>
                            <div className={classNames('collapse mb-5', showAdvancedFilters && 'show')}>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Rubro:
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <Select
                                        options={rubros}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.descripcion} - ${option.codigo}`}
                                        name='rubro_id'
                                        value={filters.rubro_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Concepto:
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <Select
                                        options={conceptos}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.descripcion} - ${option.codigo}`}
                                        name='concepto_id'
                                        value={filters.concepto_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Unidad:
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <Select
                                        options={unidades}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.descripcion} - ${option.codigo}`}
                                        name='unidad_id'
                                        value={filters.unidad_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Cuenta:
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <Select
                                        options={cuentas}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.nombre} - ${option.codigo}`}
                                        name='cuenta_id'
                                        value={filters.cuenta_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                    />
                                </FieldGroup>
                                <div className='row'>
                                    <FieldGroup
                                        label={
                                            <div className='text-end' style={{ width: '12rem' }}>
                                                Nombre del archivo:
                                            </div>
                                        }
                                        className='col-7 mb-0'
                                        inline
                                    >
                                        <Input
                                            value={excelFileName}
                                            onChange={(e) => setExcelFileName(e.target.value)}
                                            className='form-control-sm'
                                            placeholder='Nombre para el archivo excel'
                                        />
                                    </FieldGroup>
                                    <div className='col-5'>
                                        Dirección por defecto C:\Descargas\fechaActual_Report.xlsx
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center bg-blue rounded py-3'>
                        <Button
                            onClick={handleGeneratePdf}
                            startIcon={<span className='bi-printer' />}
                            loading={isLoading.buttonPdf}
                            disabled={isLoading.buttonExcel}
                        >
                            IMPRIMIR
                        </Button>
                        {showAdvancedFilters && (
                            <Button
                                variant='success'
                                onClick={handleGenerateExcel}
                                loading={isLoading.buttonExcel}
                                disabled={!isEnabled.buttonExcel || isLoading.buttonPdf}
                                className='ms-3'
                            >
                                EXPORTAR A EXCEL
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
