import { Button } from 'components/modular/Button'
import { DataTable } from 'components/modular/DataTable'
import { Confirm } from 'components/modular/Modal'
import { formatCurrency, formatDate } from 'components/modular/utils'
import useFactura from 'modules/recaudaciones/hooks/useFactura'
import { useMemo, useState } from 'react'

export default function LastFacturas({ facturas, reloadFacturas }) {
    const { openPdf, cancel } = useFactura()
    const [selected, setSelected] = useState(null)
    const [isVisible, setIsVisible] = useState({ modalConfirmCancel: false })

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData = null) => {
        if (action === 'cancel') {
            setSelected(rowData)
            show('modalConfirmCancel')
        } else if (action === 'print') {
            handleReprint(rowData)
        }
    }

    const optionsCellTemplate = ({ data }) => (
        <div className='text-center text-nowrap'>
            <Button
                variant='danger'
                size='sm'
                startIcon={<span className='bi-x-circle' />}
                onClick={() => handleAction('cancel', data)}
            >
                Anular
            </Button>
            <Button
                variant='success'
                size='sm'
                startIcon={<span className='bi-printer' />}
                onClick={() => handleAction('print', data)}
                className='ms-2'
            >
                Reimprimir
            </Button>
        </div>
    )
    const columns = useMemo(() => [
        {
            field: 'fecha_pago',
            header: 'Fecha',
            headerClassName: 'bg-blue text-white',
            cell: ({ value }) => formatDate(value, 'd/m/Y'),
            cellClassName: 'text-center',
            style: { width: '10rem' },
        },
        {
            field: 'numero_factura',
            header: 'Nro. Factura',
            headerClassName: 'bg-blue text-white',
            cellClassName: 'text-center',
            default: '-',
            style: { width: '10rem' },
        },
        { field: 'persona', header: 'Persona', headerClassName: 'bg-blue text-white' },
        {
            field: 'monto',
            header: 'Total (Bs)',
            headerClassName: 'bg-blue text-white',
            cell: ({ value }) => formatCurrency(value),
            cellClassName: 'text-end',
            style: { width: '10rem' },
        },
        {
            id: 'options',
            header: 'Opciones',
            headerClassName: 'bg-blue text-white',
            cell: optionsCellTemplate,
            style: { width: '15rem' },
        },
    ])

    /** Genera la factura en pdf. */
    const handleReprint = (factura) => {
        openPdf(factura.numero_factura ? factura : null)
    }
    /** Anula la factura seleccionado. */
    const handleCancel = () => {
        hide('modalConfirmCancel')
        if (selected) {
            cancel(selected.movimiento_id, {}, () => reloadFacturas?.())
        }
    }

    const clearSelected = () => {
        setSelected(null)
    }

    return (
        <>
            <div className='fw-medium fs-5'>ULTIMA FACTURA VALIDA</div>
            <div className='container shadow-sm p-0 bg-body rounded overflow-hidden'>
                <DataTable
                    values={facturas}
                    columns={columns}
                    defaultAlignHeader='center'
                    emptyMessage={<div className='fw-medium text-center'>Sin facturas emitidas el día de hoy</div>}
                />
                <Confirm
                    show={isVisible.modalConfirmCancel}
                    onAccept={handleCancel}
                    onReject={() => hide('modalConfirmCancel')}
                    onExited={clearSelected}
                    style={{ width: '35rem' }}
                    className='rounded-4 overflow-hidden'
                    headerClassName='bg-secondary-subtle'
                    footerClassName='bg-secondary-subtle text-center'
                >
                    <span className='fw-medium fs-4' style={{ lineHeight: 1.25 }}>
                        Esta seguro de <span className='text-primary'>ANULAR</span> la factura de: "{selected?.persona}"
                    </span>
                </Confirm>
            </div>
        </>
    )
}
