import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_USER } from '../actions/types'

/**
 * Verifica si existe el token de un usuario.
 * @returns {boolean} Retorna true si existe el token de usuario.
 */
function isLogged() {
    const _user = localStorage.getItem('user')
    if (_user) {
        try {
            const data = JSON.parse(_user)
            if (data.accessToken) {
                return true
            }
        } catch (error) {
            localStorage.removeItem('user')
        }
    }
    return false
}

const initialState = {
    /* Especifica si existe una sesión activa */
    isLoggedIn: isLogged(),
    /* Datos del usuario */
    user: null,
    /* Array de roles de usuario */
    roles: [],
    /*
        Objeto de permisos del usuario, el objeto tiene como claves los
        nombres de los permisos y como valor el nivel del permiso.
    */
    permissions: {},
}

/**
 * El reductor de autenticación actualizará el estado de la aplicación,
 * si existe un usuario logueado o no
 */
export default function reducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...initialState,
                isLoggedIn: true,
            }
        case LOGIN_FAIL:
            return {
                ...initialState,
                isLoggedIn: false,
            }
        case LOGOUT:
            return {
                ...initialState,
                isLoggedIn: false,
            }
        case SET_USER:
            return {
                ...state,
                user: payload.user,
                roles: payload.roles,
                permissions: payload.permissions,
            }
        default:
            return state
    }
}
