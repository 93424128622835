import { classNames } from '../utils'
export function InputIcon({ children, startIcon, endIcon, px = '.75rem', ps, pe, className, ...props }) {
    return (
        <div {...props} className={classNames('modular-input-icon', className)}>
            {startIcon && (
                <div className='mod-ii-start-icon' style={{ left: ps ?? px }}>
                    {startIcon}
                </div>
            )}
            {children}
            {endIcon && (
                <div className='mod-ii-end-icon' style={{ right: pe ?? px }}>
                    {endIcon}
                </div>
            )}
        </div>
    )
}
