import Header from 'Component/Header'
import Loader from 'components/Loader'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'components/modular/Button'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Select } from 'components/modular/Select'
import { clean, pick } from 'components/modular/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReportAccountPlan from 'Reports/ReportAccountPlan'
import ExcelFileDataAccount from 'ReportsExcel/ExcelFileDataAccount'
import RecaudacionesServices from 'services/recaudaciones.service'

export default function TarifarioCuentas() {
    const { showHttpToast } = useHttpToast()
    const { user, permissions } = useSelector((state) => state.auth)
    const [filters, setFilters] = useState({ estructura_id: '', unidad_id: '', filename: '' })
    const [estructuras, setEstructuras] = useState([])
    const [unidades, setUnidades] = useState([])
    const [isEnable, setIsEnable] = useState({ fieldEstructura: false })
    const [isLoading, setIsLoading] = useState({
        gettingInitialData: true,
        generatingPdf: false,
        generatingExcel: false,
    })

    useEffect(() => {
        console.log('asd')
        const canValorados = permissions.getTarifarioCuentasValores
        const canFacturas = permissions.getTarifarioCuentasFacturas
        const unidadParams = {}
        if (canValorados?.level >= 3 || canFacturas?.level >= 3) {
            setIsEnable((prev) => ({ ...prev, fieldEstructura: true }))
        } else {
            setFilters((prev) => ({ ...prev, estructura_id: user.estructura }))
            unidadParams.estructura_id = user.estructura
        }
        Promise.all([
            loadUnidades({ action: 'GET_TARIFARIO', ...unidadParams }),
            loadEstructuras({ action: 'GET_TARIFARIO' }),
        ]).then(() => {
            isLoading.gettingInitialData && end('gettingInitialData')
        })
    }, [user])

    /** Obtiene la lista de estructuras para los filtros. */
    const loadEstructuras = async (params) => {
        const { status, data } = await RecaudacionesServices.filter.listEstructuras(params)
        if (status === 200) {
            setEstructuras(data.data)
        }
    }
    /** Obtiene la lista de unidades para los filtros. */
    const loadUnidades = async (params) => {
        const { status, data } = await RecaudacionesServices.filter.listUnidades(params)
        if (status === 200) {
            setUnidades(data.data)
        }
    }

    const start = (name) => setIsLoading((prev) => ({ ...prev, [name]: true }))
    const end = (name) => setIsLoading((prev) => ({ ...prev, [name]: false }))

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'estructura_id') {
            loadUnidades({ action: 'GET_TARIFARIO', estructura_id: value })
        }
        setFilters((prev) => ({ ...prev, [name]: value }))
    }
    /** Genera el tarifario de cuentas en pdf. */
    const handleGeneratePdf = async () => {
        start('generatingPdf')
        const params = clean(pick(filters, ['estructura_id', 'unidad_id']))
        const { status, data } = await RecaudacionesServices.getTarifarioCuentas(params)
        if (status === 200) {
            const report = new ReportAccountPlan()
            report.generateReportPDF(data.data, `${user.apellidos} ${user.nombres}`, false, user.unidad)
        } else {
            showHttpToast(status, { detail: data.message })
        }
        end('generatingPdf')
    }
    /** Genera el tarifario de cuentas en excel. */
    const handleGenerateExcel = async () => {
        start('generatingExcel')
        const params = clean(pick(filters, ['estructura_id', 'unidad_id']))
        const { status, data } = await RecaudacionesServices.getTarifarioCuentas(params)
        if (status === 200) {
            const report = new ExcelFileDataAccount()
            report.generateExcel(data.data, 'Plan-Cuentas', filters.filename.trim())
        } else {
            showHttpToast(status, { detail: data.message })
        }
        end('generatingExcel')
    }

    if (isLoading.gettingInitialData) {
        return <Loader />
    }

    return (
        <div>
            <Header title='TARIFARIO DE CUENTAS' />
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <div className='container shadow-sm bg-body p-4 rounded'>
                    <div style={{ padding: '0 6.5rem' }}>
                        <FieldGroup
                            label='Dirección Administrativa:'
                            labelClassName='fw-medium'
                            labelStyle={{ width: '12rem' }}
                            inline
                        >
                            <Select
                                options={estructuras}
                                optionLabel='descripcion'
                                optionValue='id'
                                name='estructura_id'
                                value={filters.estructura_id}
                                onChange={handleChange}
                                placeholder='TODOS'
                                disabled={!isEnable.fieldEstructura}
                            />
                        </FieldGroup>
                        <FieldGroup label='Unidad:' labelClassName='fw-medium' labelStyle={{ width: '12rem' }} inline>
                            <Select
                                options={unidades}
                                optionLabel='descripcion'
                                optionValue='id'
                                name='unidad_id'
                                value={filters.unidad_id}
                                onChange={handleChange}
                                placeholder='TODOS'
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Nombre del Archivo:'
                            labelClassName='fw-medium'
                            labelStyle={{ width: '12rem' }}
                            inline
                        >
                            <Input
                                name='filename'
                                value={filters.filename}
                                onChange={handleChange}
                                placeholder='Nombre para el archivo excel'
                            />
                        </FieldGroup>
                        <p className='text-center'>
                            Dirección predeterminada de descarga del archivo excel
                            C:\Descargas\fechaActual_tarifario.xlsx
                        </p>
                    </div>
                    <div className='py-3 rounded bg-blue text-center'>
                        <Button
                            startIcon={<span className='bi-file-earmark-pdf' />}
                            loading={isLoading.generatingPdf}
                            disabled={isLoading.generatingExcel}
                            onClick={handleGeneratePdf}
                        >
                            GENERAR PDF
                        </Button>
                        <Button
                            variant='success'
                            className='ms-3'
                            startIcon={<span className='bi-file-earmark-excel' />}
                            loading={isLoading.generatingExcel}
                            disabled={isLoading.generatingPdf}
                            onClick={handleGenerateExcel}
                        >
                            GENERAR EXCEL
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
