import Header from 'Component/Header'
import { useEffect, useRef, useState } from 'react'
import DatosCliente from './components/DatosCliente'
import DatosFactura from './components/DatosFactura'
import { Button } from 'components/modular/Button'
import DatosCuenta from './components/DatosCuenta'
import RecaudacionesServices from 'services/recaudaciones.service'
import useHttpToast from 'hooks/useHttpToast'
import { pick } from 'components/modular/utils'
import useFactura from 'modules/recaudaciones/hooks/useFactura'
import LastFacturas from './components/LastFacturas'
import CobroDeudas from './components/CobroDeudas'
import { useSelector } from 'react-redux'

export default function Facturar() {
    const inputRefs = useRef({})
    const { showHttpToast } = useHttpToast()
    const { issue } = useFactura()
    const datosClienteRef = useRef(null)
    const datosCuentaRef = useRef(null)
    const datosFacturaRef = useRef(null)
    const { user, permissions } = useSelector((state) => state.auth)
    const [totales, setTotales] = useState({
        monto_principal: 0,
        descuento_principal: 0,
        monto_adicionales: 0,
        descuento_adicionales: 0,
    })
    const [submitting, setSubmitting] = useState(false)
    const [lastFacturas, setLastFacturas] = useState([])
    const [isEnable, setIsEnable] = useState({ issueFacturas: false, collectDeudas: false })

    useEffect(() => {
        loadLastFacturas()
    }, [])
    useEffect(() => {
        const canSellValores = permissions.issueFacturas
        const canCollectDeudas = permissions.collectDeudasFacturas
        setIsEnable((prev) => ({ ...prev, issueFacturas: !!canSellValores, collectDeudas: !!canCollectDeudas }))
    }, [user])

    /** Obtiene las ultimas facturas emitidas. */
    const loadLastFacturas = async () => {
        const { status, data } = await RecaudacionesServices.facturacion.lastValidas()
        if (status === 200) {
            setLastFacturas(data.data)
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)
        await handleIssue()
        setSubmitting(false)
    }

    const handleIssue = async () => {
        if (!datosClienteRef.current || !datosFacturaRef.current || !datosCuentaRef.current) {
            return
        }
        const hasErrorsCliente = datosClienteRef.current.validateCliente()
        const hasErrorsFactura = datosFacturaRef.current.validateFactura()
        const hasErrorsCuenta = datosCuentaRef.current.validateCuenta()
        console.log(hasErrorsCuenta)
        if (hasErrorsCliente || hasErrorsFactura || hasErrorsCuenta) {
            return
        }
        const cliente = datosClienteRef.current.getCliente()
        const cuenta = datosCuentaRef.current.getCuenta()
        const factura = datosFacturaRef.current.getFactura()
        console.log(cliente)
        console.log(cuenta)
        console.log(factura)
        const { status, data } = await RecaudacionesServices.facturacion.cliente.create(cliente)
        if (status !== 200) {
            showHttpToast(status, { detail: data.message })
            return
        }
        const bodyParams = {
            cliente: pick(cliente, ['nit', 'nombre', 'correo', 'tipo_documento']),
            cuenta_id: cuenta.id,
            precio: cuenta.precio,
            cantidad: cuenta.cantidad,
            descuento: cuenta.descuento,
            cuentas_adicionales: cuenta.cuentas_adicionales.map((c) =>
                pick(c, ['id', 'precio', 'cantidad', 'descuento']),
            ),
            observacion: factura.observacion,
        }
        console.log(bodyParams)
        await issue(bodyParams, () => {
            loadLastFacturas()
            resetForm()
        })
    }

    const resetForm = () => {
        datosClienteRef.current?.resetCliente()
        datosCuentaRef.current?.resetCuenta()
        datosFacturaRef.current?.resetFactura({})
        inputRefs.current['cliente.nit']?.focus()
    }

    const saveRef = (el, name) => {
        inputRefs.current[name] = el
    }

    return (
        <div>
            <Header title='FACTURAR' />
            <div className='d-flex flex-column align-items-center'>
                <div className='container shadow-sm p-4 bg-body rounded'>
                    {isEnable.collectDeudas && <CobroDeudas onSuccessPay={loadLastFacturas} className='mb-3' />}
                    {isEnable.issueFacturas && (
                        <form
                            onSubmit={handleSubmit}
                            onKeyDown={(e) => {
                                if (e.target.tagName !== 'BUTTON') {
                                    e.key === 'Enter' && e.preventDefault()
                                }
                            }}
                            noValidate
                        >
                            <DatosCliente
                                innerRef={datosClienteRef}
                                inputRefs={inputRefs}
                                saveRef={saveRef}
                                className='mb-3'
                            />
                            <DatosCuenta
                                innerRef={datosCuentaRef}
                                inputRefs={inputRefs}
                                saveRef={saveRef}
                                updateTotales={setTotales}
                                className='mb-3'
                            />
                            <DatosFactura
                                innerRef={datosFacturaRef}
                                inputRefs={inputRefs}
                                totales={totales}
                                className='mb-3'
                            />
                            <div className='bg-blue py-3 text-center rounded'>
                                <Button
                                    ref={(el) => saveRef(el, 'buttonTerminar')}
                                    type='submit'
                                    startIcon={<span className='bi-floppy' />}
                                    loading={submitting}
                                >
                                    Grabar
                                </Button>
                                <Button
                                    variant='secondary'
                                    onClick={resetForm}
                                    className='ms-3'
                                    startIcon={<span className='bi-x-lg' />}
                                    disabled={submitting}
                                >
                                    Limpiar
                                </Button>
                            </div>
                        </form>
                    )}
                </div>
                <LastFacturas facturas={lastFacturas} reloadFacturas={loadLastFacturas} />
            </div>
        </div>
    )
}
