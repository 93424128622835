import Loader from 'components/Loader'
import useHttpToast from 'hooks/useHttpToast'
import { useEffect, useState } from 'react'
import RecaudacionesServices from 'services/recaudaciones.service'

export default function ControlCierre({ children }) {
    const { showHttpToast } = useHttpToast()
    const [close, setClose] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        verifyClose()
    }, [])

    const verifyClose = async () => {
        const { status, data } = await RecaudacionesServices.ventaValores.isClose()
        if (status === 200) {
            setClose(data.data)
            setLoading(false)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al verificar el cierre del dia' })
        }
    }

    if (loading) {
        return <Loader />
    }
    if (close) {
        return (
            <div className='container shadow-sm p-4 bg-body rounded flex-1'>
                <div className='h-100 d-flex justify-content-center align-items-center'>
                    <span className='fw-medium fs-3'>
                        La venta de valores se encuentra cerrada
                        {close.tipo_cierre === 'G' ? ' para la presente gestión' : ' por el día de hoy'}.
                    </span>
                </div>
            </div>
        )
    }

    return children
}
