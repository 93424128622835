export const links = [
    {
        path: 'recaudaciones/venta-valores',
        label: 'Nuevo-Valores',
        icon: 'fa fa-shopping-cart',
        required: ['sellValores', 'collectDeudasValores'],
        operator: 'OR',
    },
    {
        path: 'recaudaciones/venta-pre-valorados',
        label: 'Nuevo-Pre-Valorados',
        icon: 'fa fa-shopping-cart',
        required: ['sellPreValorados'],
    },
    {
        path: 'recaudaciones/facturar',
        label: 'Nuevo-Facturar',
        icon: 'fa fa-shopping-cart',
        required: ['issueFacturas', 'collectDeudasFacturas'],
        operator: 'OR',
    },
    {
        path: 'recaudaciones/recibo/acciones',
        label: 'Nuevo-Anular-Reimprimir',
        icon: 'fa fa-file-text',
        required: ['cancelRecibo', 'reprintRecibo'],
        operator: 'OR',
    },
    {
        path: 'recaudaciones/factura/acciones',
        label: 'Nuevo-Anular-Reimprimir-F',
        icon: 'fa fa-file-text',
        required: ['cancelFactura', 'reprintFactura'],
        operator: 'OR',
    },
    {
        path: 'recaudaciones/reportes',
        label: 'Nuevo-Reportes',
        icon: 'fa fa-file-pdf-o',
        required: [
            'reportPorUnidadCuentaValores',
            'reportPorUnidadCuentaFacturas',
            'reportPorConceptoValores',
            'reportPorConceptoFacturas',
            'reportPorUnidadValores',
            'reportPorUnidadFacturas',
            'reportInstitucionalValores',
            'reportInstitucionalFacturas',
            'reportRecibosPorCajero',
            'reportRecibosPorCuenta',
            'reportTiposEstudiantes',
            'reportRecibosAnulados',
            'reportRecibosDetallados',
            'reportComisionBancaria',
            'reportAportesEstudiantes',
            'reportPreValorados',
            'reportLibroVenta',
            'reportFacturasPorCajero',
            'reportFacturasDetallados',
        ],
        operator: 'OR',
    },
    {
        path: 'recaudaciones/tarifario-cuentas',
        label: 'Nuevo-Tarifario',
        icon: 'fa fa-list',
        required: ['getTarifarioCuentasValores', 'getTarifarioCuentasFacturas'],
        operator: 'OR',
    },
    {
        path: 'recaudaciones/cierre',
        label: 'Nuevo-Cierre',
        icon: 'fa fa-list',
        required: ['closeSellValores'],
    },

    {
        path: 'recaudaciones/persona/update',
        label: 'Modificar-Persona',
        icon: 'bi-person-fill',
        required: ['admin'],
    },
]