import Loader from 'components/Loader'
import { AdvancedSelect } from 'components/modular/AdvancedSelect'
import { Button } from 'components/modular/Button'
import { Checkbox } from 'components/modular/Checkbox'
import { EditableSelect } from 'components/modular/EditableSelect'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { PickList } from 'components/modular/PickList'
import { Select } from 'components/modular/Select'
import { classNames } from 'components/modular/utils'
import { useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const actividadOptionTemplate = (actividad) => (
    <span title={actividad.descripcion}>
        {`${actividad.codigo_actividad} / ${actividad.abreviacion_da} -  ${actividad.descripcion}`}
    </span>
)
const popoverCorreo = (
    <Popover>
        <Popover.Header as='h3'>Correo / CI</Popover.Header>
        <Popover.Body className='py-2'>
            Ejemplos de valores aceptados:
            <br />
            <span className='bi-envelope-at-fill' /> Correo Institucional
            <br />
            <span className='bi-check-lg text-success' /> p.perez@umss.edu
            <br />
            <span className='bi-check-lg text-success' /> p.perez@umss.edu.bo
            <br />
            <span className='bi-person-vcard-fill' /> Carnet de Identidad
            <br />
            <span className='bi-check-lg text-success' /> 9876543
            <br />
            <span className='bi-check-lg text-success' /> 9876543-2A
        </Popover.Body>
    </Popover>
)

const initialUsuario = {
    nombres: '',
    apellidos: '',
    ci: '',
    email: '',
    username: '',
    activo: true,
    electronico: false,
    estructura_id: '',
    sucursal_id: '',
    das: [],
    rol_contabilidad: '',
    rol_presupuestos: '',
    beneficiario_fondo_rotatorio: false,
    beneficiario_fondo_avance: false,
}

export default function FormUsuario() {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [searching, setSearching] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [selectedTab, setSelectedTab] = useState('')
    const [values, setValues] = useState(initialUsuario)
    const [errors, setErrors] = useState({})
    const [isVisible, setIsVisible] = useState({
        fieldValuePassword: false,
        fieldValueConfirmar: false,
    })
    const [isEnable, setIsEnable] = useState({ fieldNombres: false, fieldApellidos: false })

    useEffect(() => {
        if (id) {
            loadUsuario()
        } else {
            loading && setLoading(false)
        }
    }, [id])

    const loadUsuario = async () => {
        !loading && setLoading(true)
        setLoading(false)
    }

    const toggleVisibility = (name) => setIsVisible((prev) => ({ ...prev, [name]: !prev[name] }))

    const handleChange = (e) => {
        const name = e.target.name
        setValues((prev) => ({ ...prev, [name]: e.target.value }))
        errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    const handleKeyDown = (e) => {
        if (e.key === ' ' || e.key === 'Spacebar') {
            e.preventDefault()
        }
    }

    const searchFuncionario = async () => {
        if (searchValue) {
            setSearching(true)
            // Buscar funcionario
            setSearching(false)
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div className='h-100 p-4'>
            <div className='container p-0 mx-auto my-0 h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>{id ? 'Editar Usuario' : 'Crear Usuario'}</h2>
                </div>
                <div className='p-3'>
                    <form>
                        <div className='row g-3 mb-3'>
                            {!id && (
                                <FieldGroup
                                    label={
                                        <span className='text-nowrap'>
                                            Buscar
                                            <OverlayTrigger placement='top' overlay={popoverCorreo}>
                                                <span className='bi-info-circle-fill mx-1 text-primary' />
                                            </OverlayTrigger>
                                            :
                                        </span>
                                    }
                                    feedback={errors.funcionario}
                                    showFeedback={!!errors.funcionario}
                                    inline
                                    className='col-12 mb-0'
                                >
                                    <div className='d-flex gap-3'>
                                        <Input
                                            name='funcionario'
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                            onKeyUp={(e) => e.key === 'Enter' && searchFuncionario()}
                                            placeholder='Correo Institucional o CI'
                                        />
                                        <Button
                                            onClick={searchFuncionario}
                                            className='py-0 px-1 lh-1'
                                            style={{ fontSize: '1.25rem', width: '4rem' }}
                                        >
                                            {searching ? (
                                                <div className='spinner-border spinner-border-sm' role='status'>
                                                    <span className='visually-hidden'>Loading...</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <span className='bi-person-fill' />
                                                    <span className='bi-list' />
                                                </div>
                                            )}
                                        </Button>
                                    </div>
                                </FieldGroup>
                            )}
                            <FieldGroup label='Nombres' className='col-sm-6 mb-0'>
                                <Input
                                    name='nombres'
                                    value={values.nombres}
                                    onChange={handleChange}
                                    disabled={!isEnable.fieldNombres}
                                />
                            </FieldGroup>
                            <FieldGroup label='Apellidos' className='col-sm-6 mb-0'>
                                <Input
                                    name='apellidos'
                                    value={values.apellidos}
                                    onChange={handleChange}
                                    disabled={!isEnable.fieldApellidos}
                                />
                            </FieldGroup>
                            <FieldGroup label='CI' className='col-sm-4 mb-0'>
                                <div
                                    className={classNames('form-control', errors.ci && 'is-invalid')}
                                    style={{ backgroundColor: '#e9ecef' }}
                                >
                                    {values.ci || <span className='text-transparent'>_</span>}
                                </div>
                            </FieldGroup>
                            <FieldGroup label='Correo' className='col-sm-4 mb-0'>
                                <Input
                                    name='email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </FieldGroup>
                            <FieldGroup label='Usuario' className='col-sm-4 mb-0'>
                                <Input
                                    name='username'
                                    value={values.username}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </FieldGroup>
                            {!id && (
                                <>
                                    <FieldGroup
                                        label='Contraseña'
                                        className='col-sm-6 mb-0'
                                        feedback={errors.password}
                                        showFeedback={!!errors.password}
                                    >
                                        <div className='position-relative'>
                                            <Input
                                                name='password'
                                                type={isVisible.fieldValuePassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={handleChange}
                                                isInvalid={errors.password}
                                                className='bg-img-none'
                                                style={{ paddingRight: '2.5rem' }}
                                            />
                                            <div
                                                className='position-absolute cursor-pointer'
                                                style={{
                                                    fontSize: '1.25rem',
                                                    lineHeight: '1.5rem',
                                                    right: '.75rem',
                                                    top: '.375rem',
                                                }}
                                                onClick={() => toggleVisibility('fieldValuePassword')}
                                            >
                                                <span
                                                    className={isVisible.fieldValuePassword ? 'bi-eye-slash' : 'bi-eye'}
                                                />
                                            </div>
                                        </div>
                                    </FieldGroup>
                                    <FieldGroup
                                        label='Confirmar Contraseña'
                                        className='col-sm-6 mb-0'
                                        feedback={errors.confirmar}
                                        showFeedback={!!errors.confirmar}
                                    >
                                        <div className='position-relative'>
                                            <Input
                                                name='confirmar'
                                                type={isVisible.fieldValueConfirmar ? 'text' : 'password'}
                                                value={values.confirmar}
                                                onChange={handleChange}
                                                isInvalid={errors.confirmar}
                                                className='bg-img-none'
                                                style={{ paddingRight: '2.5rem' }}
                                            />
                                            <div
                                                className='position-absolute cursor-pointer'
                                                style={{
                                                    fontSize: '1.25rem',
                                                    lineHeight: '1.5rem',
                                                    right: '.75rem',
                                                    top: '.375rem',
                                                }}
                                                onClick={() => toggleVisibility('fieldValueConfirmar')}
                                            >
                                                <span
                                                    className={
                                                        isVisible.fieldValueConfirmar ? 'bi-eye-slash' : 'bi-eye'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </FieldGroup>
                                </>
                            )}
                            <div className='col-12'>
                                {!id && (
                                    <>
                                        <div className='text-primary fst-italic mb-2' style={{ lineHeight: '1.25' }}>
                                            <span className='bi-info-circle-fill' /> La contraseña debe contener al
                                            menos una mayúscula, una minúscula, un número y un carácter especial.
                                        </div>
                                        <label className='d-inline-flex align-items-center'>
                                            <Checkbox
                                                name='send_account'
                                                value={values.send_account}
                                                onChange={handleChange}
                                                style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                                className='me-2'
                                            />
                                            <span className='fw-medium select-none'>
                                                Enviar datos de la cuenta de usuario por correo.
                                            </span>
                                        </label>
                                        <br />
                                        <label className='d-inline-flex align-items-center'>
                                            <Checkbox
                                                name='default_password'
                                                value={values.default_password}
                                                onChange={handleChange}
                                                style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                                className='me-2'
                                            />
                                            <span className='fw-medium select-none'>
                                                Solicitar cambio de contraseña al iniciar sesión por primera vez.
                                            </span>
                                        </label>
                                        <br />
                                    </>
                                )}
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='activo'
                                        value={values.activo}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Activo</span>
                                </label>
                            </div>
                        </div>
                        <ul className='nav nav-tabs mb-3'>
                            <li className='nav-item'>
                                <div
                                    className={classNames(
                                        'nav-link select-none cursor-pointer',
                                        selectedTab === 'R' && 'active',
                                    )}
                                    onClick={() => setSelectedTab('R')}
                                >
                                    Recaudaciones
                                </div>
                            </li>
                            <li className='nav-item'>
                                <div
                                    className={classNames(
                                        'nav-link select-none cursor-pointer',
                                        selectedTab === 'C' && 'active',
                                    )}
                                    onClick={() => setSelectedTab('C')}
                                >
                                    Contabilidad
                                </div>
                            </li>
                            <li className='nav-item'>
                                <div
                                    className={classNames(
                                        'nav-link select-none cursor-pointer',
                                        selectedTab === 'P' && 'active',
                                    )}
                                    onClick={() => setSelectedTab('P')}
                                >
                                    Presupuestos
                                </div>
                            </li>
                            <li className='nav-item'>
                                <div
                                    className={classNames(
                                        'nav-link select-none cursor-pointer',
                                        selectedTab === 'B' && 'active',
                                    )}
                                    onClick={() => setSelectedTab('B')}
                                >
                                    Beneficiario-Fondos
                                </div>
                            </li>
                        </ul>
                        <div className='row g-3 mb-3'>
                            {selectedTab === 'R' ? (
                                <>
                                    <FieldGroup
                                        label='DA'
                                        className='col-sm-6 mb-0'
                                        feedback={errors.estructura_id}
                                        showFeedback={!!errors.estructura_id}
                                    >
                                        <AdvancedSelect
                                            options={[]}
                                            className={classNames('w-100', errors.estructura_id && 'is-invalid')}
                                            menuStyle={{ minWidth: '100%' }}
                                            name='estructura_id'
                                            value={values.estructura_id}
                                            onChange={handleChange}
                                            filterBy='descripcion'
                                        />
                                    </FieldGroup>
                                    <FieldGroup
                                        label='Sucursal'
                                        className='col-sm-6 mb-0'
                                        feedback={errors.sucursal_id}
                                        showFeedback={!!errors.sucursal_id}
                                    >
                                        <AdvancedSelect
                                            options={[]}
                                            className={classNames('w-100', errors.sucursal_id && 'is-invalid')}
                                            menuStyle={{ minWidth: '100%' }}
                                            name='sucursal_id'
                                            value={values.sucursal_id}
                                            onChange={handleChange}
                                            filterBy='direccion'
                                        />
                                    </FieldGroup>
                                    <div className='col-sm-6'>
                                        <label className='d-inline-flex align-items-center'>
                                            <Checkbox
                                                name='electronico'
                                                value={values.electronico}
                                                onChange={handleChange}
                                                style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                                className='me-2'
                                            />
                                            <span className='fw-medium select-none'>Electrónico</span>
                                        </label>
                                    </div>
                                    <FieldGroup label='Roles' className='col-12 mb-0'>
                                        <PickList
                                            source={[]}
                                            sourceHeader='Disponibles'
                                            target={[]}
                                            targetHeader='Asignados'
                                            filterBy='name'
                                            style={{ height: '25rem' }}
                                        />
                                    </FieldGroup>
                                </>
                            ) : selectedTab === 'C' ? (
                                <>
                                    <FieldGroup label='Rol' className='col-sm-6 mb-0'>
                                        <Select
                                            options={[]}
                                            name='rol_contabilidad'
                                            value={values.rol_contabilidad}
                                            onChange={handleChange}
                                        />
                                    </FieldGroup>
                                    <FieldGroup
                                        label="DA's"
                                        className='col-sm-6 mb-0'
                                        feedback={errors.das}
                                        showFeedback={!!errors.das}
                                    >
                                        <AdvancedSelect
                                            options={[]}
                                            optionValue='id'
                                            optionLabel='descripcion'
                                            optionTemplate={(option) => `${option.codigo} - ${option.descripcion}`}
                                            className={classNames('w-100', errors.das && 'is-invalid')}
                                            menuStyle={{ minWidth: '100%' }}
                                            multiSelection
                                            name='das'
                                            value={values.das}
                                            onChange={handleChange}
                                        />
                                    </FieldGroup>
                                </>
                            ) : selectedTab === 'P' ? (
                                <>
                                    <FieldGroup label='Rol' className='col-sm-6 mb-0'>
                                        <Select
                                            options={[]}
                                            name='rol_presupuestos'
                                            value={values.rol_presupuestos}
                                            onChange={handleChange}
                                        />
                                    </FieldGroup>
                                    <FieldGroup
                                        label="DA's"
                                        className='col-sm-6 mb-0'
                                        feedback={errors.das}
                                        showFeedback={!!errors.das}
                                    >
                                        <AdvancedSelect
                                            options={[]}
                                            optionValue='id'
                                            optionLabel='descripcion'
                                            optionTemplate={(option) => `${option.codigo} - ${option.descripcion}`}
                                            className={classNames('w-100', errors.das && 'is-invalid')}
                                            menuStyle={{ minWidth: '100%' }}
                                            multiSelection
                                            name='das'
                                            value={values.das}
                                            onChange={handleChange}
                                        />
                                    </FieldGroup>
                                </>
                            ) : selectedTab === 'B' ? (
                                <>
                                    <FieldGroup
                                        label='Actividad'
                                        className='col-sm-6 mb-0'
                                        feedback={errors.actividad}
                                        showFeedback={!!errors.actividad}
                                    >
                                        <EditableSelect
                                            options={[]}
                                            optionValue='codigo_actividad'
                                            optionTemplate={actividadOptionTemplate}
                                            valueAsOption
                                            name='actividad'
                                            value={values.actividad}
                                            onChange={handleChange}
                                            isInvalid={!!errors.actividad}
                                            placeholder='Buscar'
                                            className='w-100'
                                            menuStyle={{ minWidth: '100%' }}
                                            lazy={true}
                                            onSearch={() => {}}
                                        />
                                    </FieldGroup>
                                    <div className='col-sm-6'>
                                        <div className='h-100 d-flex flex-column justify-content-evenly'>
                                            <label className='d-flex align-items-center'>
                                                <Checkbox
                                                    name='beneficiario_fondo_rotatorio'
                                                    value={values.beneficiario_fondo_rotatorio}
                                                    onChange={handleChange}
                                                    style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                                    className='me-2'
                                                />
                                                Beneficiario de Fondo Rotatorio
                                            </label>
                                            <label className='d-flex align-items-center'>
                                                <Checkbox
                                                    name='beneficiario_fondo_avance'
                                                    value={values.beneficiario_fondo_avance}
                                                    onChange={handleChange}
                                                    style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                                    className='me-2'
                                                />
                                                Beneficiario de Fondo en Avance
                                            </label>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className='text-center'>
                            <Button
                                variant='secondary'
                                startIcon={<span className='bi-x-lg' />}
                                disabled={submitting}
                                className='me-3'
                            >
                                Cancelar
                            </Button>
                            <Button startIcon={<span className='bi-floppy' />} loading={submitting}>
                                Guardar
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
