import useHttpToast from 'hooks/useHttpToast'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { debounce, empty } from 'components/modular/utils'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import RecaudacionesServices from 'services/recaudaciones.service'
import { Select } from 'components/modular/Select'
import { InputIcon } from 'components/modular/InputIcon'

const initialCliente = {
    nit: '',
    tipo_documento: '1',
    nombre: '',
    correo: '',
}

function validateCliente(values) {
    if (values.id) {
        return null
    }
    const errors = {}
    if (!values.nit) errors.nit = 'Requerido'
    if (!values.tipo_documento) errors.tipo_documento = 'Requerido'
    if (!values.nombre.trim()) errors.nombre = 'Requerido'
    if (!values.correo) errors.correo = 'Requerido'
    return empty(errors) ? null : errors
}

/**
 * Componente de llenado de Datos de Cliente.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {any} props.innerRef - Referencia del componente.
 * @param {any} props.inputRefs - Referencia los componentes del formulario.
 */
export default function DatosCliente({ innerRef, inputRefs, saveRef, ...props }) {
    const { showHttpToast } = useHttpToast()
    const [tipos, setTipos] = useState([])
    const [cliente, setCliente] = useState(initialCliente)
    const [errors, setErrors] = useState({})
    const [isVisible, setIsVisible] = useState({ iconFieldNit: true })

    useEffect(() => {
        loadTipos()
    }, [])

    /** Carga los tipos de documento. */
    const loadTipos = async () => {
        const { status, data } = await RecaudacionesServices.facturacion.listTiposDocumento()
        if (status === 200) {
            setTipos(data.data)
        }
    }

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    /** Almacena los datos del cliente seleccionado. */
    const handleSelectCliente = (cliente) => {
        setCliente(cliente)
        setErrors({})
        setTimeout(() => {
            inputRefs?.current['cuenta.codigo']?.focus()
        }, 250)
    }

    /**
     * Busca el cliente por el campo nit.
     * @param {object} params Parámetros de búsqueda.
     * @param {string} params.nit NIT.
     */
    const searchCliente = useCallback(
        debounce(async (params) => {
            if (params.nit) {
                // Buscar cliente
                const { status, data } = await RecaudacionesServices.facturacion.cliente.search(params.nit)
                if (status === 200) {
                    if (data.data) {
                        handleSelectCliente(data.data)
                    } else {
                        inputRefs?.current['cliente.nombre']?.focus()
                    }
                } else {
                    showHttpToast(status, { detail: data.message })
                }
            }
        }, 300),
        [],
    )

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        const value = name === 'nit' ? e.target.value.toUpperCase() : e.target.value
        if (cliente.id && name === 'nit') {
            setCliente({ ...initialCliente, [name]: value })
            setErrors({})
        } else {
            setCliente((prev) => ({ ...prev, [name]: value }))
            errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
        }
    }
    /** Maneja el evento keyup de los inputs. */
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            const { name, value } = e.target
            switch (name) {
                case 'nit':
                    searchCliente({ nit: cliente.nit })
                    break
                case 'tipo_documento':
                    inputRefs?.current['cliente.nombre']?.focus()
                    break
                case 'nombre':
                    if (value.trim()) {
                        inputRefs?.current['cliente.correo']?.focus()
                    }
                    break
                case 'correo':
                    if (value) {
                        inputRefs?.current['cuenta.codigo']?.focus()
                    }
                    break

                default:
                    break
            }
        }
    }
    /** Maneja el evento keydown de los inputs. */
    const handleKeyDown = (e) => {
        if (e.key === ' ' || e.key === 'Spacebar') {
            if (['nit', 'correo'].includes(e.target.name)) {
                e.preventDefault()
            }
        }
    }

    /** Valida los datos de la cliente. */
    const validate = () => {
        const _errors = validateCliente(cliente)
        if (_errors) {
            setErrors(_errors)
        }
        return _errors
    }
    /** Restablece los datos del cliente a sus valores iniciales. */
    const reset = () => {
        setCliente(initialCliente)
    }
    /** Obtiene los datos del cliente. */
    const get = () => {
        return { ...cliente }
    }

    return (
        <div {...props}>
            <div
                className='d-flex align-items-center bg-blue rounded text-white select-none mb-3'
                style={{ padding: '.75rem 1rem' }}
            >
                <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                    CLIENTE
                </span>
            </div>
            <div className='px-3'>
                <div className='row g-3'>
                    <FieldGroup
                        label='Documento:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-6 mb-0'
                        inline
                    >
                        <InputIcon
                            className='w-100'
                            startIcon={
                                isVisible.iconFieldNit && !cliente.nit ? (
                                    <span className='bi-search text-secondary' />
                                ) : undefined
                            }
                            onFocus={() => hide('iconFieldNit')}
                            onBlur={() => show('iconFieldNit')}
                        >
                            <Input
                                ref={(el) => el && saveRef?.(el, `cliente.${el.name}`)}
                                name='nit'
                                value={cliente.nit}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onKeyDown={handleKeyDown}
                                isInvalid={errors.nit}
                                size='sm'
                                autoFocus
                            />
                        </InputIcon>
                    </FieldGroup>
                    <FieldGroup
                        label='Tipo Doc.:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-6 mb-0'
                        inline
                    >
                        <Select
                            ref={(el) => el && saveRef?.(el, `cliente.${el.name}`)}
                            options={tipos}
                            optionValue='id'
                            optionTemplate={(o) => `${o.abreviacion} - ${o.descripcion}`}
                            name='tipo_documento'
                            value={cliente.tipo_documento}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            onKeyDown={handleKeyDown}
                            isInvalid={errors.tipo_documento}
                            size='sm'
                            placeholder={null}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Razón Social:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-12 mb-0'
                        inline
                    >
                        <Input
                            ref={(el) => el && saveRef?.(el, `cliente.${el.name}`)}
                            name='nombre'
                            value={cliente.nombre}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            isInvalid={errors.nombre}
                            size='sm'
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Correo:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6rem' }}
                        className='col-12 mb-0'
                        inline
                    >
                        <Input
                            ref={(el) => el && saveRef?.(el, `cliente.${el.name}`)}
                            name='correo'
                            value={cliente.correo}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            onKeyDown={handleKeyDown}
                            isInvalid={errors.correo}
                            size='sm'
                        />
                    </FieldGroup>
                </div>
            </div>
            <DatosClienteRef ref={innerRef} validate={validate} reset={reset} get={get} />
        </div>
    )
}

const DatosClienteRef = forwardRef(({ validate, reset, get }, ref) => {
    useImperativeHandle(ref, () => ({
        validateCliente() {
            return validate()
        },
        resetCliente() {
            reset()
        },
        getCliente() {
            return get()
        },
    }))
    return null
})
