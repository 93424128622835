import UpdatePersona from 'Pages/recaudaciones/UpdatePersona'
import FacturaActions from './pages/FacturaActions'
import ReciboActions from './pages/ReciboActions'
import Reportes from './pages/Reportes'
import TarifarioCuentas from './pages/TarifarioCuentas'
import VentaValores from './pages/VentaValores/VentaValores'
import Cierre from './pages/Cierre'
import Cuentas from 'Pages/recaudaciones/Cuentas'
import FormCuenta from 'Pages/recaudaciones/FormCuenta'
import FormUsuario from 'Pages/administracion/FormUsuario'
import Facturar from './pages/Facturar/Facturar'
import VentaPreValorados from './pages/VentaPreValorados/VentaPreValorados'

export const routes = [
    {
        path: 'recaudaciones',
        children: [
            {
                path: 'venta-valores',
                element: <VentaValores />,
                expect: true,
                required: ['sellValores', 'collectDeudasValores'],
                operator: 'OR',
            },
            {
                path: 'venta-pre-valorados',
                element: <VentaPreValorados />,
                expect: true,
                required: ['sellPreValorados'],
            },
            {
                path: 'facturar',
                element: <Facturar />,
                expect: true,
                required: ['issueFacturas', 'collectDeudasFacturas'],
                operator: 'OR',
            },
            {
                path: 'recibo/acciones',
                element: <ReciboActions />,
                expect: true,
                required: ['cancelRecibo', 'reprintRecibo'],
                operator: 'OR',
            },
            {
                path: 'factura/acciones',
                element: <FacturaActions />,
                expect: true,
                required: ['cancelFactura', 'reprintFactura'],
                operator: 'OR',
            },
            {
                path: 'reportes',
                element: <Reportes />,
                expect: true,
                required: [
                    'reportPorUnidadCuentaValores',
                    'reportPorUnidadCuentaFacturas',
                    'reportPorConceptoValores',
                    'reportPorConceptoFacturas',
                    'reportPorUnidadValores',
                    'reportPorUnidadFacturas',
                    'reportInstitucionalValores',
                    'reportInstitucionalFacturas',
                    'reportRecibosPorCajero',
                    'reportRecibosPorCuenta',
                    'reportTiposEstudiantes',
                    'reportRecibosAnulados',
                    'reportRecibosDetallados',
                    'reportComisionBancaria',
                    'reportAportesEstudiantes',
                    'reportPreValorados',
                    'reportLibroVenta',
                    'reportFacturasPorCajero',
                    'reportFacturasDetallados',
                ],
                operator: 'OR',
            },
            {
                path: 'cierre',
                element: <Cierre />,
                expect: true,
                required: ['closeSellValores'],
            },
            {
                path: 'tarifario-cuentas',
                element: <TarifarioCuentas />,
                expect: true,
                required: ['getTarifarioCuentasValores', 'getTarifarioCuentasFacturas'],
                operator: 'OR',
            },

            {
                path: 'persona/update',
                element: <UpdatePersona />,
                expect: true,
                required: ['admin'],
            },
            {
                path: 'cuenta',
                element: <Cuentas />,
                expect: true,
            },
            {
                path: 'cuenta/create',
                element: <FormCuenta />,
                expect: true,
            },
        ],
    },
    {
        path: 'usuario/create',
        element: <FormUsuario />,
        expect: true,
    },
]
