/**
 * Crea un nuevo objeto sin las propiedades especificadas en `keys`.
 *
 * La función `omit` elimina las claves indicadas en el arreglo `keys` del objeto original.
 *
 * @param object El objeto original del cual se eliminarán las propiedades.
 * @param keys Un arreglo de claves que deben ser omitidas del objeto original.
 * @returns Un nuevo objeto que contiene todas las propiedades de `object`, excepto aquellas presentes en `keys`.
 *
 * @template T - El tipo del objeto original, el cual debe ser un objeto.
 * @template K - Las claves que serán omitidas del objeto.
 *
 * @example
 * const person = { name: 'John', age: 30, country: 'USA' };
 * const result = omit(person, ['age']);
 * console.log(result);  // { name: 'John', country: 'USA' }
 */
export function omit(obj, keys) {
    const _keys = Array.isArray(keys) ? keys : [keys]
    return Object.keys(obj).reduce((carry, key) => {
        if (!_keys.includes(key)) {
            carry[key] = obj[key]
        }
        return carry
    }, {})
}
