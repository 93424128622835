import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'components/modular/Button'
import { EditableSelect } from 'components/modular/EditableSelect'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Select } from 'components/modular/Select'
import { useEffect, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'
import PresupuestosServices from 'services/presupuestos.service'
import { formatCurrency, formatDate, pick } from 'utils'

const mapTiposDocumento = {}
const currentDate = formatDate(new Date())

const initialDocumento = {
    fecha: currentDate,
    tipo_documento: '',
    numero_documento: '',
}
const initialDetalle = {
    partida_presupuestaria: '',
    monto_total: '',
    rciva: 0,
    it: 0,
    iue: 0,
    liquido_pagable: 0,
    actividad: '',
}

const actividadOptionTemplate = (actividad) => {
    return (
        <span title={actividad.descripcion}>
            {`${actividad.codigo_actividad} / ${actividad.abreviacion_da} -  ${actividad.descripcion}`}
        </span>
    )
}

export default function FormDetalleFondo({ fondo, selected, onCancel, onSubmit }) {
    const { showHttpToast } = useHttpToast()
    const [documento, setDocumento] = useState(initialDocumento)
    const [detalle, setDetalle] = useState(initialDetalle)
    const [step, setStep] = useState(1)
    const [errors, setErrors] = useState({})
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [partidas, setPartidas] = useState([])
    const [actividades, setActividades] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const load = async () => {
            const { status, data } = await ContabilidadServices.tipoDocumento.all()
            if (status === 200) setTiposDocumento(data.data)
        }
        load()
    }, [])
    useEffect(() => {
        if (selected) {
            // Cargado de datos para edición
            const {
                fecha,
                tipo_documento,
                numero_documento,
                detalle,
                partida_presupuestaria_id,
                partida,
                categoria_programatica,
                ...values
            } = selected
            setDocumento({ fecha, tipo_documento, numero_documento })
            setDetalle({
                ...values,
                partida_presupuestaria: {
                    id: partida_presupuestaria_id,
                    nombre: detalle,
                    codigo: partida,
                },
                actividad: categoria_programatica,
            })
            searchItems(detalle)
        } else {
            // Cargado de datos para creación
            setDocumento(initialDocumento)
            // Para fondos en avance la actividad de los detalles de gasto debe ser la misma que el del fondo
            const _detalle = fondo.tipo === 'R'
                ? { ...initialDetalle, actividad: fondo.categoria_programatica }
                : { ...initialDetalle, actividad: fondo.actividad_codigo, actividad_id: fondo.actividad_id }
            setDetalle(_detalle)
        }
    }, [selected])
    useEffect(() => {
        if (fondo.tipo === 'R') {
            searchActividades(fondo.categoria_programatica)
        }
    }, [fondo])

    /** Handler que se encarga de controlar los campos de la parte de documento del formulario. */
    const handleChangeDocumento = (e) => {
        const { name, value } = e.target
        setDocumento((prev) => ({ ...prev, [name]: value }))
        if (name === 'tipo_documento') setDetalle((prev) => ({ ...prev, ...getRetenciones({ [name]: value }) }))
        handleErrors(name)
    }
    /** Handler que se encarga de controlar los campos de la parte de detalle del formulario. */
    const handleChangeDetalle = (e) => {
        const { name, value } = e.target
        const retenciones = name === 'monto_total' ? getRetenciones({ [name]: value }) : {}
        setDetalle((prev) => ({ ...prev, [name]: value, ...retenciones }))
        handleErrors(name)
    }
    /** Maneja los errores al cambiar los valores de los campos del formulario de detalle. */
    const handleErrors = (fieldName) => {
        let _errors = null
        if (errors[fieldName]) {
            _errors = { ...errors, [fieldName]: false }
        }
        if (fieldName === 'monto_total' && errors.liquido_pagable) {
            _errors = { ..._errors, liquido_pagable: false }
        }
        if (_errors) {
            setErrors(_errors)
        }
    }
    /** Handler que se encarga de cambiar de la parte de documento a la parte de detalle del formulario. */
    const handleNextStep = () => {
        const _errors = validateForm('documento')
        if (Object.values(_errors).length > 0) {
            setErrors(_errors)
        } else {
            setStep(2)
        }
    }
    /** Función que valida los campos del formulario de adición/edición de detalle de reembolso. */
    const validateForm = (fields = 'all') => {
        const _errors = {}
        if (['all', 'documento'].includes(fields)) {
            if (!documento.fecha) {
                _errors.fecha = 'Requerido'
            }
            if (!documento.tipo_documento) {
                _errors.tipo_documento = 'Requerido'
            }
            if (!documento.numero_documento.toString().trim()) {
                _errors.numero_documento = 'Requerido'
            }
        }
        if (['all', 'detalle'].includes(fields)) {
            if (!detalle.partida_presupuestaria?.id) {
                _errors.partida_presupuestaria = 'Requerido'
            }
            if (!(detalle.monto_total > 0)) {
                _errors.monto_total = 'Requerido'
            }
            if (!detalle.actividad_id && !detalle.actividad?.id) {
                _errors.actividad = 'Requerido'
            }
        }
        return _errors
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const _errors = validateForm()
        if (Object.values(_errors).length > 0) {
            setErrors(_errors)
            setLoading(false)
        } else {
            const { partida_presupuestaria, actividad, ...values } = detalle
            const _detalle = {
                ...values,
                detalle: partida_presupuestaria.nombre,
                partida_presupuestaria_id: partida_presupuestaria.id,
                categoria_programatica: actividad?.codigo_actividad ?? detalle.actividad,
                actividad_id: actividad?.id ?? detalle.actividad_id,
                fondo_id: fondo.id,
            }

            onSubmit?.({ documento, detalle: _detalle, setErrors, setLoading, resetDetalle })
        }
    }
    const resetDetalle = () => {
        setDetalle({ ...initialDetalle, actividad: fondo.categoria_programatica })
    }

    const searchItems = async (item) => {
        if (item) {
            const { status, data } = await PresupuestosServices.itemGasto.search({ item, activos: 1 })
            if (status === 200) {
                setPartidas(data.data.map((partida) => pick(partida, ['id', 'nombre', 'codigo'])))
            } else {
                showHttpToast(status, { detail: 'Ocurrió un error al buscar los items de gasto' })
            }
        } else {
            partidas.length && setPartidas([])
        }
    }
    /**
     * Busca las actividades de la estructura programática que coinciden con el código pasado como parametro.
     * @param {string} codigo Código de la actividad con el siguiente formato:
     * - 01-02-000-000-10 -> DAF, 2 dígitos DA + 2 dígitos UE + 3 dígitos Programa + 3 dígitos Proyecto + 2 dígitos Actividad
     */
    const searchActividades = async (codigo) => {
        if (codigo.length > 0) {
            if (codigo.length < 17) {
                const { status, data } = await ContabilidadServices.fondo.searchActividades(codigo)
                if (status === 200) {
                    setActividades(data.data)
                }
            }
        } else {
            setActividades([])
        }
    }

    /** Calcula las retenciones del detalle en registro/edición. */
    const getRetenciones = (values = {}) => {
        let rciva = 0
        let it = 0
        let iue = 0
        const monto = values.monto_total ?? detalle.monto_total
        const tipoDoc = values.tipo_documento ?? documento.tipo_documento
        if (tipoDoc && monto > 0) {
            const tipo = mapTiposDocumento[tipoDoc] ?? tiposDocumento.filter((td) => td.nombre === tipoDoc)?.[0]
            if (tipo) {
                if (tipo.rciva) rciva = (monto * 0.13).toFixed(2)
                if (tipo.it) it = (monto * 0.03).toFixed(2)
                if (tipo.iue) iue = (monto * 0.05).toFixed(2)
                mapTiposDocumento[tipoDoc] = tipo
            }
        }
        const lp = (monto - rciva - it - iue).toFixed(2)
        return { rciva, it, iue, liquido_pagable: lp }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='p-3'>
                {step === 1 ? (
                    <div key='step-1'>
                        <p className='fw-medium fs-5'>Información del documento</p>
                        <FieldGroup label={<div style={{ width: '8rem' }}>Fecha:</div>} inline>
                            <Input
                                type='date'
                                name='fecha'
                                value={documento.fecha}
                                onChange={handleChangeDocumento}
                                isInvalid={!!errors.fecha}
                            />
                        </FieldGroup>
                        <FieldGroup label={<div style={{ width: '8rem' }}>Tipo Documento:</div>} inline>
                            <Select
                                options={tiposDocumento}
                                optionLabel='nombre'
                                optionValue='nombre'
                                name='tipo_documento'
                                value={documento.tipo_documento}
                                onChange={handleChangeDocumento}
                                isInvalid={!!errors.tipo_documento}
                            />
                        </FieldGroup>
                        <FieldGroup label={<div style={{ width: '8rem' }}>Nro. Documento:</div>} inline>
                            <Input
                                name='numero_documento'
                                value={documento.numero_documento}
                                onChange={handleChangeDocumento}
                                isInvalid={!!errors.numero_documento}
                            />
                        </FieldGroup>
                    </div>
                ) : (
                    <div key='step-2'>
                        <p className='fw-medium fs-5'>Información del gasto</p>
                        <FieldGroup label={<div style={{ width: '7rem' }}>Detalle:</div>} inline>
                            <EditableSelect
                                options={partidas}
                                optionValue='nombre'
                                valueAsOption
                                lazy
                                onSearch={searchItems}
                                name='partida_presupuestaria'
                                value={detalle.partida_presupuestaria}
                                onChange={handleChangeDetalle}
                                isInvalid={!!errors.partida_presupuestaria}
                                className='w-100'
                                menuStyle={{ minWidth: '100%' }}
                            />
                        </FieldGroup>
                        <FieldGroup label={<div style={{ width: '7rem' }}>Partida Pres.:</div>} inline>
                            <div className='form-control bg-body-secondary' style={{ height: '2.5em' }}>
                                {detalle.partida_presupuestaria.codigo}
                            </div>
                        </FieldGroup>
                        <FieldGroup label={<div style={{ width: '7rem' }}>Monto Total:</div>} inline>
                            <Input
                                name='monto_total'
                                type='currency'
                                value={detalle.monto_total}
                                onChange={handleChangeDetalle}
                                isInvalid={!!errors.monto_total}
                                className='text-end'
                            />
                        </FieldGroup>
                        <FieldGroup label={<div style={{ width: '7rem' }}>Retenciones.-</div>} inline>
                            <div>
                                <FieldGroup
                                    label={
                                        <div style={{ width: '7rem' }} className='text-end'>
                                            RC-IVA (13%):
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <div className='form-control text-end bg-body-secondary'>
                                        {formatCurrency(detalle.rciva)}
                                    </div>
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div style={{ width: '7rem' }} className='text-end'>
                                            I.T. (3%):
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <div className='form-control text-end bg-body-secondary'>
                                        {formatCurrency(detalle.it)}
                                    </div>
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div style={{ width: '7rem' }} className='text-end'>
                                            I.U.E (5%):
                                        </div>
                                    }
                                    className='mb-0'
                                    inline
                                >
                                    <div className='form-control text-end bg-body-secondary'>
                                        {formatCurrency(detalle.iue)}
                                    </div>
                                </FieldGroup>
                            </div>
                        </FieldGroup>
                        <FieldGroup
                            label={<div style={{ width: '7rem' }}>Liq. Pagable:</div>}
                            inline
                            showFeedback={!!errors.liquido_pagable}
                            feedback={errors.liquido_pagable}
                        >
                            <div
                                className='form-control  bg-body-secondary text-end'
                                style={
                                    errors.liquido_pagable
                                        ? { borderColor: 'var(--bs-form-invalid-border-color)' }
                                        : undefined
                                }
                            >
                                {formatCurrency(detalle.liquido_pagable)}
                            </div>
                        </FieldGroup>
                        <FieldGroup label={<div style={{ width: '7rem' }}>Cat. Prog.:</div>} inline className='mb-0'>
                            {fondo.tipo === 'R' ? (
                                <EditableSelect
                                    options={actividades}
                                    optionValue='codigo_actividad'
                                    optionTemplate={actividadOptionTemplate}
                                    valueAsOption
                                    lazy
                                    name='actividad'
                                    value={detalle.actividad}
                                    onChange={handleChangeDetalle}
                                    onSearch={searchActividades}
                                    isInvalid={!!errors.actividad}
                                    placeholder='Buscar'
                                    className='w-100'
                                    menuStyle={{ minWidth: '100%' }}
                                />
                            ) : (
                                <div className='form-control'>
                                    {selected ? selected.categoria_programatica : fondo.actividad_codigo}
                                </div>
                            )}
                        </FieldGroup>
                    </div>
                )}
            </div>
            <div className='px-3 py-2 border-top text-end'>
                <Button
                    className='me-2'
                    variant='outline-primary'
                    onClick={onCancel}
                    startIcon={<span className='bi-x-lg' />}
                    disabled={loading}
                >
                    Cancelar
                </Button>
                {step === 1 ? (
                    <Button onClick={handleNextStep} startIcon={<span className='bi-chevron-right' />}>
                        Siguiente
                    </Button>
                ) : (
                    <>
                        <Button
                            className='me-2'
                            onClick={() => setStep(1)}
                            startIcon={<span className='bi-chevron-left' />}
                            disabled={loading}
                        >
                            Anterior
                        </Button>
                        <Button type='submit' startIcon={<span className='bi-floppy' />} loading={loading}>
                            Guardar
                        </Button>
                    </>
                )}
            </div>
        </form>
    )
}
