import useHttpToast from 'hooks/useHttpToast'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { clean, debounce, empty } from 'components/modular/utils'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { formatCurrency, pick } from 'utils'
import RecaudacionesServices from 'services/recaudaciones.service'
import CuentasAdicionales from './CuentasAdicionales'
import ModalSelectCuenta from 'modules/recaudaciones/components/ModalSelectCuenta/ModalSelectCuenta'
import { InputIcon } from 'components/modular/InputIcon'

function validateCuenta(values) {
    const errors = {}
    if (!values.codigo.trim()) errors.codigo = 'Required'
    if (!values.id) {
        errors.nombre = 'Required'
        errors.unidad = 'Required'
    }
    if (!(values.precio > 0)) errors.precio = 'Debe ser mayor a cero'
    if (!(values.cantidad > 0)) errors.cantidad = 'Debe ser mayor a cero'
    if (values.descuento >= values.precio * values.cantidad) errors.descuento = 'Debe ser menor al total de la cuenta'

    if (
        values.cuentas_adicionales?.find(
            (c) => !(c.cantidad > 0 && c.precio > 0 && c.descuento < c.precio * c.cantidad),
        )
    ) {
        errors.cuentas_adicionales = 'Hay errores en las cuentas adicionales'
    }
    return empty(errors) ? null : errors
}
const initialCuenta = {
    codigo: '',
    nombre: '',
    unidad: '',
    precio: '0.00',
    cantidad: '1',
    descuento: '0.00',
}

export default function DatosCuenta({ innerRef, inputRefs, saveRef, updateTotales, ...props }) {
    const { showHttpToast } = useHttpToast()
    const [cuenta, setCuenta] = useState(initialCuenta)
    const [cuentasAdicionales, setCuentasAdicionales] = useState([])
    const [errors, setErrors] = useState({})
    const [cuentas, setCuentas] = useState([])
    const [isVisible, setIsVisible] = useState({ modalSelectCuenta: false, iconFieldCodigo: true })
    const [typeCuenta, setTypeCuenta] = useState('main')

    useEffect(() => {
        updateTotales?.((prev) => ({
            ...prev,
            monto_principal: cuenta.precio * cuenta.cantidad,
            descuento_principal: cuenta.descuento ? parseInt(cuenta.descuento) : 0,
        }))
    }, [cuenta.precio, cuenta.cantidad, cuenta.descuento])
    useEffect(() => {
        updateTotales?.((prev) => ({
            ...prev,
            ...cuentasAdicionales.reduce(
                (carry, c) => {
                    carry.monto_adicionales += c.precio * c.cantidad
                    carry.descuento_adicionales += c.descuento ? parseInt(c.descuento) : 0
                    return carry
                },
                { monto_adicionales: 0, descuento_adicionales: 0 },
            ),
        }))
        errors.cuentas_adicionales && setErrors((prev) => ({ ...prev, cuentas_adicionales: '' }))
    }, [cuentasAdicionales])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    /** Almacena los datos de la cuenta seleccionada. */
    const selectCuenta = async (cuenta) => {
        setCuenta({
            ...initialCuenta,
            ...clean(pick(cuenta, ['id', 'codigo', 'nombre', 'unidad', 'precio', 'tipo_monto'])),
        })
        setErrors({})
        setTimeout(() => {
            if (cuenta.tipo_monto === 'V') {
                inputRefs?.current['cuenta.precio']?.focus()
            } else {
                inputRefs?.current['buttonTerminar']?.focus({ focusVisible: true })
            }
        }, 250)
    }
    /** Adiciona la cuenta a las cuentas adicionales. */
    const addCuenta = (cuenta) => {
        setCuentasAdicionales((prev) => [
            ...prev,
            {
                ...cuenta,
                cantidad: 1,
                descuento: 0,
            },
        ])
    }
    /**
     * Busca la cuenta para venta por caja.
     * @param {object} params Parámetros de búsqueda.
     * @param {string} params.codigo Código de cuenta.
     * @param {string} params.nombre Nombre de la cuenta.
     */
    const searchCuenta = useCallback(
        debounce(async (params = {}) => {
            const _params = clean(params)
            const { status, data } = await RecaudacionesServices.facturacion.listCuentas(_params)
            if (status === 200) {
                if (isVisible.modalSelectCuenta) {
                    setCuentas(data.data)
                } else {
                    if (data.data.length === 1 && !empty(_params)) {
                        selectCuenta(data.data[0])
                    } else {
                        setCuentas(data.data)
                        show('modalSelectCuenta')
                    }
                }
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }, 300),
        [isVisible.modalSelectCuenta],
    )

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value.toUpperCase()
        if (name === 'codigo' && cuenta.id) {
            setCuenta({ ...initialCuenta, [name]: value })
            cuentasAdicionales.length && setCuentasAdicionales([])
            setErrors({})
        } else {
            setCuenta((prev) => ({ ...prev, [name]: value }))
            errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
        }
    }
    /** Maneja el evento keyup de los inputs. */
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            const { name } = e.target
            if (name === 'codigo') {
                searchCuenta({ codigo: cuenta.codigo })
            } else if (['precio', 'cantidad'].includes(name)) {
                if (cuenta[name] > 0) {
                    inputRefs?.current['buttonTerminar']?.focus({ focusVisible: true })
                }
            }
        }
    }
    /** Maneja el evento keydown de los inputs. */
    const handleKeyDown = (e) => {
        if (e.key === ' ' || e.key === 'Spacebar') {
            if (e.target.name === 'codigo') {
                e.preventDefault()
            }
        }
    }
    /** Maneja la selección de una cuenta. */
    const handleSelectCuenta = async (selection) => {
        if (typeCuenta === 'additional') {
            addCuenta(selection)
        } else {
            selectCuenta(selection)
        }
        hide('modalSelectCuenta')
    }

    /** Valida los datos de la cuenta. */
    const validate = () => {
        const _errors = validateCuenta({ ...cuenta, cuentas_adicionales: cuentasAdicionales })
        if (_errors) {
            setErrors(_errors)
        }
        return _errors
    }
    /** Restablece los datos de la cuenta a sus valores iniciales. */
    const reset = () => {
        setCuenta(initialCuenta)
        cuentasAdicionales.length && setCuentasAdicionales([])
    }
    /** Obtiene los datos de la cuenta. */
    const get = () => {
        return { ...cuenta, cuentas_adicionales: cuentasAdicionales }
    }

    return (
        <div {...props}>
            <div>
                <div className='bg-blue rounded text-white select-none mb-3' style={{ padding: '.75rem 1rem' }}>
                    <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                        CUENTA
                    </span>
                </div>
                <div className='px-3'>
                    <div className='row g-2'>
                        <FieldGroup
                            label='Código'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '10rem' }}
                        >
                            <InputIcon
                                startIcon={
                                    isVisible.iconFieldCodigo && !cuenta.codigo ? (
                                        <span className='bi-search text-secondary' />
                                    ) : undefined
                                }
                                onFocus={() => hide('iconFieldCodigo')}
                                onBlur={() => show('iconFieldCodigo')}
                            >
                                <Input
                                    ref={(el) => el && saveRef?.(el, `cuenta.${el.name}`)}
                                    name='codigo'
                                    value={cuenta.codigo}
                                    onChange={handleChange}
                                    onKeyUp={handleKeyUp}
                                    onKeyDown={handleKeyDown}
                                    isInvalid={errors.codigo}
                                    size='sm'
                                />
                            </InputIcon>
                        </FieldGroup>
                        <FieldGroup label='Nombre' labelClassName='fw-medium' className='col mb-0'>
                            <Input defaultValue={cuenta.nombre} isInvalid={errors.nombre} size='sm' disabled />
                        </FieldGroup>
                        <FieldGroup label='Unidad' labelClassName='fw-medium' className='col-2 mb-0'>
                            <Input defaultValue={cuenta.unidad} isInvalid={errors.unidad} size='sm' disabled />
                        </FieldGroup>
                        <FieldGroup
                            label='Cantidad'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '7rem' }}
                        >
                            <Input
                                type='number'
                                name='cantidad'
                                value={cuenta.cantidad}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                min={1}
                                className='text-end'
                                isInvalid={errors.cantidad}
                                size='sm'
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Precio Unit. (Bs)'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '9rem' }}
                        >
                            <Input
                                ref={(el) => el && saveRef?.(el, `cuenta.${el.name}`)}
                                type='currency'
                                name='precio'
                                value={cuenta.precio}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onFocus={(e) => e.target.select()}
                                className='text-end'
                                isInvalid={errors.precio}
                                size='sm'
                                disabled={cuenta.tipo_monto === 'F'}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Descuento (Bs)'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '8rem' }}
                        >
                            <Input
                                ref={(el) => el && saveRef?.(el, `cuenta.${el.name}`)}
                                type='currency'
                                name='descuento'
                                value={cuenta.descuento}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onFocus={(e) => e.target.select()}
                                className='text-end'
                                isInvalid={errors.descuento}
                                size='sm'
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Importe (Bs)'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '10rem' }}
                        >
                            <Input
                                name='total'
                                value={formatCurrency(cuenta.precio * cuenta.cantidad - cuenta.descuento)}
                                onChange={() => {}}
                                isInvalid={errors.total}
                                className='text-end'
                                size='sm'
                                disabled
                            />
                        </FieldGroup>
                    </div>
                </div>
            </div>
            {cuenta.id && (
                <div className='mt-3'>
                    <CuentasAdicionales
                        cuentas={cuentasAdicionales}
                        setCuentas={setCuentasAdicionales}
                        onClickAdd={() => {
                            setTypeCuenta('additional')
                            const _cuentasAdicionales = cuentasAdicionales.map((c) => c.id)
                            searchCuenta({ exclude: [...(cuenta.id ? [cuenta.id] : []), ..._cuentasAdicionales] })
                            show('modalSelectCuenta')
                        }}
                    />
                    {errors.cuentas_adicionales && (
                        <div className='text-center text-danger mt-1'>{errors.cuentas_adicionales}</div>
                    )}
                </div>
            )}
            <ModalSelectCuenta
                show={isVisible.modalSelectCuenta}
                onHide={() => hide('modalSelectCuenta')}
                onExited={() => {
                    cuentas.length && setCuentas([])
                    typeCuenta === 'additional' && setTypeCuenta('main')
                }}
                cuentas={cuentas}
                onSelectCuenta={handleSelectCuenta}
                searchCuenta={(params) => {
                    const _cuentasAdicionales = cuentasAdicionales.map((c) => c.id)
                    searchCuenta({ ...params, exclude: [...(cuenta.id ? [cuenta.id] : []), ..._cuentasAdicionales] })
                }}
                initialSearcherValue={typeCuenta === 'additional' ? '' : cuenta.codigo}
            />
            <DatosCuentaRef ref={innerRef} validate={validate} reset={reset} get={get} />
        </div>
    )
}

const DatosCuentaRef = forwardRef(({ validate, reset, get }, ref) => {
    useImperativeHandle(ref, () => ({
        validateCuenta() {
            return validate()
        },
        resetCuenta() {
            reset()
        },
        getCuenta() {
            return get()
        },
    }))
    return null
})
