import saveAs from "file-saver"
const Excel = require('exceljs')

export default class ExcelTiposEstudiante {
    totalReport = 0.00

    /**
     * Metodo que genera el reporte Detalle de Recaudaciones Segun Recibos en formato excel
     * 
     * @param JSON details 
     * @param string userName 
     * @param string initDate 
     * @param string endDate 
     * @param string fileName nombre que tendra el archivo al descargar 
     */
    excelReport(details, userName, initDate, endDate, fileName) {
        //const report = new ExcelFile
        this.totalReport = 0.00

        const date = new Date()
        const outputDate = date.getFullYear().toString() + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, '0')
        const outputTime = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Det-Utis", { views: { showGridlines: false } });

        worksheet.columns = [
            { width: 15, alignmen: 'left' },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 60 },
            { width: 20, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' } },
            { width: 50 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 }
        ]

        worksheet.mergeCells(`A1:F1`)
        worksheet.getCell("A1").value = "Detalle de Recaudaciones Segun Tipos de Estudiante"
        worksheet.getCell("A1").alignment = { horizontal: "center", vertical: "middle" }
        worksheet.getCell("A1").font = {
            bold: true,
            size: 14
        }
        worksheet.getRow(1).height = 25

        worksheet.getCell("A2").value = "Usuario"
        worksheet.getCell("B2").value = userName

        worksheet.getCell("A3").value = "Fecha"
        worksheet.getCell("B3").value = `Del ${initDate} al ${endDate}`

        var indexCell = 5

        for (var i = 0; i < details.length; i++) {
            
            worksheet.getCell(`A${indexCell}`).value = "Usuario:"
            worksheet.getCell(`A${indexCell}`).font = {
                bold: true,
                size: 14
            }
            worksheet.getCell(`B${indexCell}`).value = details[i].usuario
            worksheet.getCell(`B${indexCell}`).font = {
                bold: true,
                size: 14
            }
            worksheet.mergeCells(`B${indexCell}:D${indexCell}`)

            ++indexCell
            //tabla items
            const auxTable = {
                name: `table${i}`,
                ref: `A${indexCell}`,
                headerRow: true,
                totalsRow: true,
                columns: [
                    { name: "Fecha" },
                    { name: "Nro.Rec." },
                    { name: "Preimpreso" },
                    { name: "Tipo_Estudiante" },
                    { name: "Plan" },
                    { name: "Código" },
                    { name: "Documento" },
                    { name: "Descripcion" },
                    { name: "Total", totalsRowFunction: 'sum' },
                    { name: "Observacion" },
                    { name: "Complemento" },
                    { name: "apellido 1" },
                    { name: "apellido 2" },
                    { name: "nombre 1" },
                    { name: "nombre 2" },
                    { name: "username" }
                ],
                rows: [...this.listItems(details, i)]
            }

            indexCell += (details[i].detalle.length + 4)

            worksheet.addTable(auxTable)

            worksheet.getCell(`A${(indexCell - 3)}`).value = "Total Usuario"
            worksheet.getCell(`A${(indexCell - 3)}`).font = {
                bold: true,
            }
            worksheet.getCell(`D${(indexCell - 3)}`).font = {
                bold: true,
            }

            worksheet.getCell(`A${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                left: { style: "medium" },
                bottom: { style: "medium" }
            }
            worksheet.getCell(`B${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                bottom: { style: "medium" }
            }
            worksheet.getCell(`C${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                bottom: { style: "medium" }
            }
            worksheet.getCell(`D${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                bottom: { style: "medium" }
            }

            worksheet.getCell(`E${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                bottom: { style: "medium" }
            }
            worksheet.getCell(`F${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                bottom: { style: "medium" }
            }
            worksheet.getCell(`G${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                bottom: { style: "medium" }
            }
            worksheet.getCell(`H${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                bottom: { style: "medium" }
            }

            worksheet.getCell(`I${(indexCell - 3)}`).border = {
                top: { style: "medium" },
                right: { style: "medium" },
                bottom: { style: "medium" }
            }
        }

        worksheet.getCell(`E${indexCell}`).value = "Total Reporte"
        worksheet.getCell(`E${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`E${indexCell}`).border = {
            top: { style: "medium" },
            left: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`E${indexCell}`).alignment = { horizontal: "left", vertical: "middle" }

        worksheet.getCell(`I${indexCell}`).value = this.totalReport
        worksheet.getCell(`I${indexCell}`).font = {
            bold: true,
            size: 14
        }
        worksheet.getCell(`I${indexCell}`).border = {
            top: { style: "medium" },
            right: { style: "medium" },
            bottom: { style: "medium" }
        }
        worksheet.getCell(`I${indexCell}`).alignment = { horizontal: "right", vertical: "middle" }

        worksheet.getRow(indexCell).height = 25

        workbook.xlsx.writeBuffer().then(function (buffer) {
            if (fileName === '') {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${outputDate}${outputTime}_Report.xlsx`
                );
            } else {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${fileName}.xlsx`
                );
            }
        });
    }

    listItems(items, i) {
        var observation = ''
        var auxTotal = 0.00

        const list = items[i].detalle.map((item) => {
                observation = " "
                this.totalReport += parseFloat(item.total)
                auxTotal = parseFloat(item.total)

            
            return [
                item.fecha,
                item.nRec,
                item.preimpreso,
                item.tipo_estudiante,
                item.plan_estudio,
                item.codigo,
                item.documento,
                item.descripcion,
                auxTotal,
                item.observacion,
                item.complemento_documento,
                item.apellido_1,
                item.apellido_2,
                item.nombre_1,
                item.nombre_2,
                item.username
            ];
        });
        return list;
    }
}