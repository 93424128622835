import { Button } from 'components/modular/Button'
import { DataTable } from 'components/modular/DataTable'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { useEffect, useRef, useState } from 'react'

const columns = [
    {
        field: 'codigo',
        header: 'Código',
        headerClassName: 'bg-secondary-subtle',
    },
    {
        field: 'nombre',
        header: 'Nombre',
        headerClassName: 'bg-secondary-subtle',
    },
    {
        field: 'precio',
        header: 'Precio',
        cellClassName: 'text-end',
        headerClassName: 'bg-secondary-subtle',
    },
    {
        field: 'unidad',
        header: 'Unidad',
        headerClassName: 'bg-secondary-subtle',
    },
]

/**
 * Selector de cuenta.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array<Object>} props.cuentas - Lista de cuentas disponibles.
 * @param {Function} props.onSelectCuenta - Función que se ejecuta cuando se confirma la selección de una cuenta.
 * @param {Function} props.onCancel - Función que se ejecuta al cancelar la selección de cuenta.
 * @param {Function} props.searchCuenta - Función que buscar cuentas por código o nombre.
 * @param {string} props.initialSearcherValue - Valor inicial que se mostrara en el input de búsqueda.
 */
export default function SelectCuenta({ cuentas, onSelectCuenta, onCancel, searchCuenta, initialSearcherValue }) {
    const tableRef = useRef()
    const searcherInputRef = useRef()
    const buttonSeleccionarRef = useRef(null)
    const [cuenta, setCuenta] = useState(null)
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if (typeof initialSearcherValue === 'string') {
            setSearchValue(initialSearcherValue)
        }
    }, [])

    /** Maneja la confirmación de selección de una cuenta. */
    const handleSelectCuenta = () => {
        if (cuenta) {
            onSelectCuenta?.(cuenta)
        }
    }

    return (
        <div className='p-3'>
            {searchCuenta && (
                <FieldGroup label='Buscar:' labelClassName='fw-medium' inline>
                    <Input
                        ref={searcherInputRef}
                        value={searchValue}
                        onChange={(e) => {
                            const value = e.target.value.toUpperCase()
                            setSearchValue(value)
                            searchCuenta({ codigo: value.replace(/\s+/g, ''), nombre: value })
                            cuenta && setCuenta(null)
                        }}
                        onKeyUp={(e) => e.key === 'Enter' && tableRef.current.focusRow(0)}
                        placeholder='Buscar por Código o Nombre'
                        autoFocus
                    />
                </FieldGroup>
            )}
            <DataTable
                innerRef={tableRef}
                values={cuentas}
                columns={columns}
                selectionMode='single'
                selectionOn='row'
                selection={cuenta}
                onSelectionChange={(selection) => {
                    setCuenta(selection)
                    if (selection) {
                        setTimeout(() => {
                            buttonSeleccionarRef.current.focus({ focusVisible: true })
                        }, 100)
                    }
                }}
                defaultAlignHeader='center'
                className='mb-5 bg-white'
                style={{ maxHeight: '25rem' }}
                headerSticky
                keyboardNavigation={{ upRowKey: 'w', downRowKey: 's' }}
                onRowKeyUp={(e) => e.key.toLowerCase() === 'q' && searcherInputRef.current.focus()}
            />
            <div className='py-2 text-center'>
                <Button
                    ref={buttonSeleccionarRef}
                    onClick={handleSelectCuenta}
                    onKeyUp={(e) => e.key.toLowerCase() === 'q' && tableRef.current.focusRow(0)}
                    startIcon={<span className='bi-check-lg' />}
                    disabled={!cuenta}
                >
                    Seleccionar
                </Button>
                <Button
                    variant='secondary'
                    className='ms-3'
                    startIcon={<span className='bi-x-lg' />}
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
            </div>
        </div>
    )
}
