/* Detalle de recaudaciones por tipo de estudianets */

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js';

import RobotoFont from '../fonts/Roboto'
import RobotoBoldFont from '../fonts/RobotoBold'

import reportConf from './components/reportConf'
import reportHeader from './components/reportHeader'
import cuadroTotal from './components/cuadroTotal'
import reportFooter from './components/reportFooter'

export default class ReportTiposEstudiante {

    total = 0.0;
    totalCategory = 0.0;

    constructor() {
        this.total = 0.0
        this.numUsuario = 0
    }
    /**
     * método que retorna una tabla con los filtro si estos fueron requeridos.
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     * @returns {Array} filtros selecionados
     */
    selectionFilter(isFilter, filter, isAdmin) {
        if (isFilter) {
            if (isAdmin) {
                return [
                    ['Estructura', ":", filter.estructura],
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];
            } else {
                return [
                    ['Rubro', ":", filter.rubro],
                    ['Unidad', ":", filter.unidad],
                    ['Concepto', ":", filter.concepto],
                    ['Cuenta', ":", filter.cuenta],
                ];
            }
        } else {
            return [];
        }

    }
    /**
     * método que recibe los arreglo de datos, calcula los subtotales por cada recibo y retorna las filas de las tabla por cada items.
     * @param {Array} items
     * @param {int} i
     * @returns {Array} ->fila de las tablas
     */
    getItems(items, i) {
        let auxValue = 0.0;
        this.totalCategory = 0.0;
        if (Array.isArray(items[i].detalle)){

            const list = items[i].detalle.map((item) => {
                let totalAdd = 0.0;
                totalAdd = parseFloat(item.total);
                this.total += totalAdd;
                this.totalCategory += totalAdd
                auxValue = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                }).format(totalAdd);
                return [
                    { text: item.fecha, alignment: 'center' },
                    { text: item.nRec, alignment: 'right' },
                    { text: item.tipo_estudiante, alignment: 'center' },
                    { text: item.plan_estudio },
                    { text: item.codigo },
                    { text: item.documento },
                    { text: item.descripcion },
                    { text: auxValue, alignment: 'right' },
                ];
            });
            return list;
        }
    }

    /**
     * método encargado de generar el reporte
     * @param {Array} itemsReport
     * @param {string} userName
     * @param {string} receivedFrom
     * @param {date} initDate
     * @param {date} endDate
     * @param {boolean} isPrint
     * @param {boolean} isFilter
     * @param {JSON} filter
     * @param {boolean} isAdmin
     */
    generarPdf(itemsReport, usuario, procesado, fechaInicio, fechaFin, isPrint, isFilter, filter, isAdmin,userUnidad, electronico, caja) {
        this.total = 0.0;

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont
        };

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf'
            }
        };

        const headerPage = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                'Detalle de Recaudaciones Tipos de Estudiante',
                userUnidad,
                usuario,
                fechaInicio,
                fechaFin,
                electronico,
                caja
            );
        }

        let tableDetail = [[
            {
                fontSize: reportConf.fontSizeFilters,
                margin: [0, 0, 0, 5],
                table: {
                    body: [
                        ["", "", ""],
                        ...this.selectionFilter(isFilter, filter, isAdmin)

                    ],
                },
                layout: "noBorders",
            },

        ]
        ];
        for (let i = 0; i < itemsReport.length; i++) {
            let auxTable = [
                {
                    fontSize: reportConf.fontSizeTable,
                    table: {
                        headerRows: 2,
                        widths: [35, 32, 25, 35, 45, 45, '*', 58],

                        body: [
                            [
                                { text: 'Cajero:', style: 'tableUser' },
                                { text: `${itemsReport[i].usuario}`, style: 'tableUser', colSpan: 6, }, {}, {}, {}, {}, {}, {}
                            ],
                            [
                                { text: 'Fecha', style: 'tableHeader', alignment: 'center' },
                                { text: 'Nro.Rec.', style: 'tableHeader', alignment: 'right' },
                                { text: 'TE', style: 'tableHeader', alignment: 'center' },
                                { text: 'Plan', style: 'tableHeader', alignment: 'center' },
                                { text: 'Código', style: 'tableHeader', alignment: 'center' },
                                { text: 'Documento', style: 'tableHeader' },
                                { text: 'Descripción', style: 'tableHeader' },
                                { text: 'Total', style: 'tableHeader', alignment: 'right' },
                            ],
                            ...this.getItems(itemsReport, i),
                        ],
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return i === 2 || i === node.table.body.length ? 0.75 : 0;
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    },
                },
                {
                    fontSize: reportConf.fontSizeTable,
                    bold: true,
                    table: {
                        widths: [35, 32, '*', 58],
                        body: [
                            [
                                {},
                                { text: `${itemsReport[i].detalle.length}`, alignment: 'center' },
                                { text: 'Total Usuario:', alignment: 'right' },
                                {
                                    text: new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                    }).format(this.totalCategory),
                                    alignment: "right",
                                },
                            ],
                        ],
                    },
                    layout: "noBorders",
                },
                {text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable]},
            ];
            tableDetail.push(auxTable);
        }

        const totalLabel = [];
        totalLabel.push(cuadroTotal(this.total, 'Total Reporte', [0, 0, 38, 0]));
     
        const report = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header: headerPage,
            content: [tableDetail, totalLabel],
            defaultStyle: {
                font: 'Roboto'
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                tableUser: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: 'black'
                },
            },
        
        }

        if (isPrint) {
            let document = pdfMake.createPdf(report)

            document.getBase64(function (docBlob) {
                printJS({
                    printable: docBlob,
                    type: "pdf",
                    base64: true
                });
            });
        } else {
            pdfMake.createPdf(report).open()
        }
    }
}