import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import printJS from 'print-js'

import RobotoFont from '../../fonts/Roboto'
import RobotoBoldFont from '../../fonts/RobotoBold'

import reportConf from '../components/reportConf'
import reportHeader from '../components/reportHeader'
import cuadroTotal from '../components/cuadroTotal'
import reportFooter from '../components/reportFooter'
import { formatDate } from 'components/modular/utils'

export default class RecaudacionesPorCuentaPDF {
    /**
     * Genera el Reporte de Recaudaciones por Cuenta en PDF.
     * @param {Array} data Datos.
     * @param {object} options Opciones del reporte.
     * @param {boolean} options.modePrint Especifica si el reporte se mostrara en modo de impresión, o no.
     * @param {string} options.unidad Unidad a la que pertenece el usuario que genera el reporte.
     * @param {string} options.usuario Usuario que genera el reporte.
     * @param {boolean} options.showAdvancedFilters Especifica si se mostrara el detalle de los filtros avanzados, o no.
     * @param {boolean} options.isAdmin Especifica si el usuario que genera el reporte es Administrador, o no.
     * @param {object} options.filters Filtros.
     * @param {string} options.filters.fechaInicio Fecha de inicio seleccionado en los filtros.
     * @param {string} options.filters.fechaFin Fecha de fin seleccionado en los filtros.
     * @param {boolean} options.filters.electronico
     * @param {boolean} options.filters.caja
     * @param {string} options.filters.usuario Usuario seleccionado en los filtros.
     * @param {string} options.filters.estructura Estructura seleccionada en los filtros.
     * @param {string} options.filters.rubro Rubro seleccionado en los filtros.
     * @param {string} options.filters.concepto Concepto seleccionado en los filtros.
     * @param {string} options.filters.unidad Unidad seleccionada en los filtros.
     * @param {string} options.filters.cuenta Cuenta seleccionada en los filtros.
     */
    generatePdf(cuentas, options) {
        options = options || {}
        const filters = options.filters || {}

        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            'Roboto-Regular.ttf': RobotoFont,
            'Roboto-Bold.ttf': RobotoBoldFont,
        }

        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Bold.ttf',
            },
        }

        const title = 'Detalle de Recaudaciones UTIs'

        const header = (currentPage, pageCount, pageSize) => {
            return reportHeader(
                currentPage,
                pageCount,
                title,
                options.unidad,
                filters.usuario,
                filters.fechaInicio,
                filters.fechaFin,
                !!filters.electronico,
                !!filters.caja,
            )
        }
        const content = []
        if (options.showAdvancedFilters) {
            content.push({
                fontSize: reportConf.fontSizeFilters,
                margin: [0, 0, 0, 5],
                table: {
                    body: [
                        ...(options.isAdmin ? [['Estructura', ':', filters.estructura ?? '']] : []),
                        ['Rubro', ':', filters.rubro ?? ''],
                        ['Concepto', ':', filters.concepto ?? ''],
                        ['Unidad', ':', filters.unidad ?? ''],
                        ['Cuenta', ':', filters.cuenta ?? ''],
                    ],
                },
                layout: 'noBorders',
            })
        }
        const { total, elements } = this.getContent(cuentas)
        content.push(...elements)
        content.push(cuadroTotal(total, 'Total Reporte'))

        const footer = reportFooter(options.usuario)

        const report = {
            pageSize: 'letter',
            pageMargins: reportConf.pageMargins,
            header,
            content,
            footer,
            defaultStyle: {
                font: 'Roboto',
            },
            styles: {
                tableHeader: {
                    bold: true,
                    color: 'black',
                },
                tableUser: {
                    bold: true,
                    fontSize: reportConf.fontSizeTableHeader,
                    color: 'black',
                },
            },
        }

        if (options.modePrint) {
            let document = pdfMake.createPdf(report)

            document.getBase64(function (docBlob) {
                printJS({
                    printable: docBlob,
                    type: 'pdf',
                    base64: true,
                })
            })
        } else {
            pdfMake.createPdf(report).open()
        }
    }

    getContent(cuentas) {
        let total = 0
        const elements = []

        for (const cuenta of cuentas) {
            const rows = []
            total += parseFloat(cuenta.total)

            for (const detalle of cuenta.detalle) {
                rows.push([
                    { text: formatDate(detalle.fecha_pago, 'd-m-Y'), alignment: 'center' },
                    { text: detalle.recibo, alignment: 'center' },
                    { text: detalle.preimpreso, alignment: 'center' },
                    { text: detalle.persona },
                    {
                        text: `${detalle.persona_documento}${
                            detalle.persona_complemento ? '-' + detalle.persona_complemento : ''
                        }`,
                    },
                    { text: detalle.monto, alignment: 'right' },
                ])
            }
            rows.push([
                {},
                { text: `${cuenta.detalle.length}`, alignment: 'center', bold: true },
                { text: 'Total Cuenta', alignment: 'center', bold: true, colSpan: 3 },
                {},
                {},
                {
                    text: cuenta.total,
                    alignment: 'right',
                    bold: true,
                },
            ])

            elements.push({
                fontSize: reportConf.fontSizeTable,
                table: {
                    headerRows: 2,
                    widths: [42, 35, 45, '*', 50, 58],
                    body: [
                        [
                            { text: 'Cuenta:', style: 'tableUser' },
                            { text: `${cuenta.nombre}`, style: 'tableUser', colSpan: 4 },
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            { text: 'Fecha', style: 'tableHeader', alignment: 'center' },
                            { text: 'Nro.Rec.', style: 'tableHeader', alignment: 'center' },
                            { text: 'Nro.PreImp.', style: 'tableHeader', alignment: 'center' },
                            { text: 'Persona', style: 'tableHeader', alignment: 'center' },
                            { text: 'Documento', style: 'tableHeader', alignment: 'center' },
                            { text: 'Total', style: 'tableHeader', alignment: 'center' },
                        ],
                        ...rows,
                    ],
                },
                layout: {
                    hLineWidth: (i, node) => {
                        return i === 2 || i === node.table.body.length - 1 ? 0.75 : 0
                    },
                    vLineWidth: () => 0,
                },
            })
            elements.push({ text: '', margin: [0, 0, 0, reportConf.spaceBetweenTable] })
        }

        return { total, elements }
    }
}
