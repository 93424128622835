import Header from 'Component/Header'
import Loader from 'components/Loader'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'components/modular/Button'
import { DataTable } from 'components/modular/DataTable'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Confirm } from 'components/modular/Modal'
import { Select } from 'components/modular/Select'
import { clean, formatCurrency, formatDate, pick } from 'components/modular/utils'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import RecaudacionesServices from 'services/recaudaciones.service'
import useRecibo from '../hooks/useRecibo'
import { InputIcon } from 'components/modular/InputIcon'

const selectIconWhite =
    'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e")'
const currentGestion = new Date().getFullYear()
const actions = {
    ANULAR: {
        label: 'Anular',
        icon: 'bi-x-circle',
        option: {
            label: 'Anular',
            value: 'ANULAR',
        },
    },
    REIMPRIMIR: {
        label: 'Imprimir',
        icon: 'bi-printer',
        option: {
            label: 'Imprimir',
            value: 'REIMPRIMIR',
        },
    },
    '': {
        label: '',
        icon: '',
        option: {
            label: '',
            value: '',
        },
    },
}
const initialFilters = {
    estructura_id: '',
    usuario_id: '',
    gestion: currentGestion,
    accion: '',
    numero_recibo: '',
    documento: '',
    codigo: '',
    motivo: '',
}
const initialIsEnable = { fieldEstructura: false, fieldUsuario: false, fieldGestion: true }
const columns = [
    {
        field: 'fecha_pago',
        header: 'Fecha',
        headerClassName: 'bg-secondary-subtle',
        cell: ({ value }) => formatDate(value, 'd/m/Y'),
        cellClassName: 'text-center',
        className: 'py-3',
    },
    {
        field: 'recibo',
        header: 'Número Recibo',
        headerClassName: 'bg-secondary-subtle',
        cellClassName: 'text-center',
        className: 'border-start border-end border-dark py-3',
    },
    {
        field: 'persona',
        header: 'Persona',
        headerClassName: 'bg-secondary-subtle',
        className: 'py-3',
    },
    {
        field: 'monto',
        header: 'Importe (Bs)',
        headerClassName: 'bg-secondary-subtle',
        cell: ({ value }) => formatCurrency(value),
        cellClassName: 'text-end',
        className: 'border-start border-end border-dark py-3',
    },
    {
        field: 'username',
        header: 'Usuario',
        headerClassName: 'bg-secondary-subtle',
        cellClassName: 'text-center',
        className: 'py-3',
    },
]

export default function ReciboActions() {
    const { reprint, cancel } = useRecibo()
    const { showHttpToast } = useHttpToast()
    const tableRef = useRef(null)
    const inputsRef = useRef({})
    const buttonActionRef = useRef(null)
    const authSettings = useRef({})
    const { user, permissions } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(true)
    const [filters, setFilters] = useState(initialFilters)
    const [estructuras, setEstructuras] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const [recibos, setRecibos] = useState([])
    const [selected, setSelected] = useState(null)
    const [isEnabled, setIsEnabled] = useState(initialIsEnable)
    const [actionOptions, setActionOptions] = useState([])
    const [isVisible, setIsVisible] = useState({
        modalConfirmCancel: false,
        iconFieldRecibo: true,
        iconFieldDocumento: true,
        iconFieldCodigo: true,
    })

    useEffect(() => {
        const canCancel = permissions.cancelRecibo
        const canReprint = permissions.reprintRecibo

        const _availableActions = []
        if (canCancel) _availableActions.push(actions['ANULAR'].option)
        if (canReprint) _availableActions.push(actions['REIMPRIMIR'].option)
        setActionOptions(_availableActions)

        const isEnabledToCancel = {
            fieldEstructura: canCancel?.level >= 3,
            fieldUsuario: canCancel?.level >= 2,
        }
        const filtersToCancel = {
            estructura_id: canCancel?.level >= 3 ? '' : user.estructura,
            usuario_id: canCancel?.level >= 2 ? '' : user.id,
        }
        const isEnabledToReprint = {
            fieldEstructura: canReprint?.level >= 3,
            fieldUsuario: canReprint?.level >= 2,
        }
        const filtersToReprint = {
            estructura_id: canReprint?.level >= 3 ? '' : user.estructura,
            usuario_id: canReprint?.level >= 2 ? '' : user.id,
        }
        const cancelSettings = { isEnabled: isEnabledToCancel, filters: filtersToCancel }
        const reprintSettings = { isEnabled: isEnabledToReprint, filters: filtersToReprint }
        const defaultSettings = {
            isEnabled: pick(initialIsEnable, ['fieldEstructura', 'fieldUsuario']),
            filters: pick(initialFilters, ['estructura_id', 'usuario_id']),
        }
        if (canCancel) {
            updateAction('ANULAR', cancelSettings)
        } else if (canReprint) {
            updateAction('REIMPRIMIR', reprintSettings)
        } else {
            updateAction('', defaultSettings)
        }
        authSettings.current = {
            ANULAR: cancelSettings,
            REIMPRIMIR: reprintSettings,
            '': defaultSettings,
        }
    }, [user])

    /** Actualiza la acción y la configuración de los filtros. */
    const updateAction = (action, settings = null) => {
        // Limpiar lista de recibos y recibo seleccionado
        clearRecibos()
        // Actualizar valores de filtros
        const _filters = settings?.filters ?? authSettings.current[action].filters
        setFilters((prev) => ({
            ...prev,
            ..._filters,
            accion: action,
            motivo: '',
            gestion: action === 'ANULAR' ? currentGestion : prev.gestion,
        }))
        // Actualizar filtros habilitados
        const _isEnabled = settings?.isEnabled ?? authSettings.current[action].isEnabled
        setIsEnabled((prev) => ({ ...prev, ..._isEnabled, fieldGestion: action === 'REIMPRIMIR' }))
        // Actualizar datos de los filtros
        if (action) {
            Promise.all([
                loadEstructuras({ action: `${action}_RECIBO` }),
                loadUsuarios({ action: `${action}_RECIBO` }),
            ]).finally(() => {
                loading && setLoading(false)
            })
        } else {
            estructuras && setEstructuras([])
            usuarios && setUsuarios([])
        }
    }

    /** Obtiene la lista de estructuras para los filtros. */
    const loadEstructuras = async (params = {}) => {
        const { status, data } = await RecaudacionesServices.filter.listEstructuras(params)
        if (status === 200) {
            setEstructuras(data.data)
        }
    }
    /** Obtiene la lista de usuarios para los filtros. */
    const loadUsuarios = async (params = {}) => {
        const _params = clean(params)
        const { status, data } = await RecaudacionesServices.filter.listUsuarios(_params)
        if (status === 200) {
            setUsuarios(data.data)
        }
    }
    /** Obtiene la lista de recibos para anular. */
    const listCancellables = async () => {
        const params = pick(filters, ['usuario_id', 'gestion', 'documento', 'codigo'])
        params.recibo = filters.numero_recibo
        const { status, data } = await RecaudacionesServices.ventaValores.cancellables(clean(params))
        if (status === 200) {
            setRecibos(data.data)
            setTimeout(() => {
                data.data.length && tableRef.current.focusRow(0)
            }, 100)
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }
    /** Obtiene la lista de recibos para reimprimir. */
    const listPrintables = async () => {
        const params = pick(filters, ['usuario_id', 'estructura_id', 'gestion', 'documento', 'codigo'])
        params.recibo = filters.numero_recibo
        const { status, data } = await RecaudacionesServices.ventaValores.printables(clean(params))
        if (status === 200) {
            setRecibos(data.data)
            setTimeout(() => {
                data.data.length && tableRef.current.focusRow(0)
            }, 100)
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    /** Maneja el evento change de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        const value = name === 'documento' ? e.target.value.toUpperCase() : e.target.value
        if (['numero_recibo', 'documento', 'codigo'].includes(name)) {
            setFilters((prev) => ({ ...prev, numero_recibo: '', documento: '', codigo: '', [name]: value }))
        } else if (name === 'gestion') {
            if (value.length < 5) {
                // Controlar que la gestión este entre 2000 y el año actual
                const _value = value.length === 4 && (value < 2000 || value > currentGestion) ? currentGestion : value
                setFilters((prev) => ({ ...prev, [name]: _value }))
            }
        } else if (name === 'accion') {
            updateAction(value)
        } else if (name === 'estructura_id') {
            setFilters((prev) => ({ ...prev, [name]: value }))
            loadUsuarios({
                action: filters.accion ? `${filters.accion}_RECIBO` : '',
                estructura_id: value,
            })
        } else {
            setFilters((prev) => ({ ...prev, [name]: value }))
        }
    }
    /**
     * Maneja la navegación entre elementos usando el evento keyup.
     * @param {React.KeyboardEvent} e Evento keyup.
     * @param {string} name Nombre identificativo del elemento que lanza el evento.
     */
    const handleNavigation = (e, name) => {
        if (e.key === 'Enter') {
            if (['numero_recibo', 'documento', 'codigo'].includes(name)) {
                searchRecibos()
            } else if (name === 'motivo') {
                buttonActionRef.current.focus({ focusVisible: true })
            }
        } else if (e.key.toLowerCase() === 'q') {
            if (name === 'button_action') {
                tableRef.current.focusRow(0)
            } else if (name === 'table_row') {
                if (filters.documento) {
                    inputsRef.current['documento']?.focus()
                } else if (filters.codigo) {
                    inputsRef.current['codigo']?.focus()
                } else {
                    inputsRef.current['numero_recibo']?.focus()
                }
            }
        } else if (e.key.toLowerCase() === 'e') {
            if (name === 'button_action') {
                if (filters.accion === 'ANULAR') {
                    inputsRef.current['motivo']?.focus()
                }
            }
        }
    }
    /** Busca los recibos según la acción seleccionada. */
    const searchRecibos = () => {
        selected && setSelected(null)
        if (filters.accion === 'ANULAR') {
            listCancellables()
        } else if (filters.accion === 'REIMPRIMIR') {
            if (filters.numero_recibo || filters.documento || filters.codigo) {
                listPrintables()
            }
        }
    }
    /** Maneja las acciones. */
    const handleAction = async () => {
        if (filters.accion === 'ANULAR') {
            show('modalConfirmCancel')
        } else if (filters.accion === 'REIMPRIMIR') {
            reprint(selected)
        }
    }
    /** Anula el recibo seleccionado. */
    const handleCancel = () => {
        hide('modalConfirmCancel')
        if (selected) {
            cancel(selected, () => clearRecibos())
        }
    }

    /** Limpia los recibos y el recibo seleccionado. */
    const clearRecibos = () => {
        selected && setSelected(null)
        recibos.length && setRecibos([])
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <Header title='ANULAR/RE-IMPRIMIR RECIBOS' />
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <div className='container shadow-sm bg-body p-4 rounded'>
                    <div style={{ padding: '0 6.5rem' }}>
                        <div className='row mb-2'>
                            <FieldGroup label='DA:' labelClassName='fw-medium' className='col mb-0' inline>
                                <Select
                                    options={estructuras}
                                    optionValue='id'
                                    optionTemplate={(option) => `${option.codigo} ${option.descripcion}`}
                                    name='estructura_id'
                                    value={filters.estructura_id}
                                    onChange={handleChange}
                                    placeholder='TODOS'
                                    disabled={!isEnabled.fieldEstructura}
                                />
                            </FieldGroup>
                            <FieldGroup label='Usuario:' labelClassName='fw-medium' className='col mb-0' inline>
                                <Select
                                    options={usuarios}
                                    optionValue='id'
                                    optionTemplate={(option) =>
                                        `${option.apellidos} ${option.nombres}${
                                            option.electronico ? ' - ' + option.estructura : ''
                                        }`
                                    }
                                    name='usuario_id'
                                    value={filters.usuario_id}
                                    onChange={handleChange}
                                    placeholder='TODOS'
                                    disabled={!isEnabled.fieldUsuario}
                                />
                            </FieldGroup>
                            <FieldGroup
                                label='Gestión:'
                                labelClassName='fw-medium'
                                className='mb-0'
                                style={{ width: '10rem' }}
                                inline
                            >
                                <Input
                                    type='integer'
                                    name='gestion'
                                    value={filters.gestion}
                                    onChange={handleChange}
                                    placeholder={currentGestion}
                                    disabled={!isEnabled.fieldGestion}
                                />
                            </FieldGroup>
                        </div>
                        <p className='text-center mb-0'>Se recomienda escoger por lo menos una DA</p>
                        <hr />
                        <div className='row'>
                            <FieldGroup label='Acción' labelClassName='fw-medium text-center mb-0' className='col'>
                                <Select
                                    options={actionOptions}
                                    name='accion'
                                    value={filters.accion}
                                    onChange={handleChange}
                                    placeholder={null}
                                    className='text-center text-white bg-primary'
                                    style={{ '--bs-form-select-bg-img': selectIconWhite }}
                                />
                            </FieldGroup>
                            <FieldGroup
                                label='Número Recibo'
                                labelClassName='fw-medium text-center mb-0'
                                className='col'
                            >
                                <InputIcon
                                    startIcon={
                                        isVisible.iconFieldRecibo && !filters.numero_recibo ? (
                                            <span className='bi-search text-secondary' />
                                        ) : undefined
                                    }
                                    onFocus={() => hide('iconFieldRecibo')}
                                    onBlur={() => show('iconFieldRecibo')}
                                >
                                    <Input
                                        ref={(el) => el && (inputsRef.current[el.name] = el)}
                                        type='integer'
                                        name='numero_recibo'
                                        value={filters.numero_recibo}
                                        onChange={handleChange}
                                        onKeyUp={(e) => handleNavigation(e, e.target.name)}
                                        autoFocus
                                    />
                                </InputIcon>
                            </FieldGroup>
                            <FieldGroup label='Documento' labelClassName='fw-medium text-center mb-0' className='col'>
                                <InputIcon
                                    startIcon={
                                        isVisible.iconFieldDocumento && !filters.documento ? (
                                            <span className='bi-search text-secondary' />
                                        ) : undefined
                                    }
                                    onFocus={() => hide('iconFieldDocumento')}
                                    onBlur={() => show('iconFieldDocumento')}
                                >
                                    <Input
                                        ref={(el) => el && (inputsRef.current[el.name] = el)}
                                        name='documento'
                                        value={filters.documento}
                                        onChange={handleChange}
                                        onKeyUp={(e) => handleNavigation(e, e.target.name)}
                                        onKeyDown={(e) => (e.key === ' ' || e.key === 'Spacebar') && e.preventDefault()}
                                    />
                                </InputIcon>
                            </FieldGroup>
                            <FieldGroup label='Código SIS' labelClassName='fw-medium text-center mb-0' className='col'>
                                <InputIcon
                                    startIcon={
                                        isVisible.iconFieldCodigo && !filters.codigo ? (
                                            <span className='bi-search text-secondary' />
                                        ) : undefined
                                    }
                                    onFocus={() => hide('iconFieldCodigo')}
                                    onBlur={() => show('iconFieldCodigo')}
                                >
                                    <Input
                                        ref={(el) => el && (inputsRef.current[el.name] = el)}
                                        type='integer'
                                        name='codigo'
                                        value={filters.codigo}
                                        onChange={handleChange}
                                        onKeyUp={(e) => handleNavigation(e, e.target.name)}
                                    />
                                </InputIcon>
                            </FieldGroup>
                        </div>
                        <DataTable
                            innerRef={tableRef}
                            values={recibos}
                            columns={columns}
                            selectionMode='single'
                            selectionOn='row'
                            selection={selected}
                            onSelectionChange={(selection) => {
                                setSelected(selection)
                                if (selection) {
                                    setTimeout(() => {
                                        buttonActionRef.current.focus({ focusVisible: true })
                                    }, 100)
                                }
                            }}
                            keyboardNavigation={{ upRowKey: 'w', downRowKey: 's' }}
                            onRowKeyUp={(e) => handleNavigation(e, 'table_row')}
                            className='selected-blue border border-dark mb-3'
                            tableWrapperStyle={{ height: '25rem' }}
                            headerSticky
                            tableClassName='border-dark'
                            defaultAlignHeader='center'
                            emptyMessage={<div className='fw-medium text-center py-2'>Ningún recibo encontrado</div>}
                        />

                        <FieldGroup label='Motivo:' labelClassName='fw-medium' inline>
                            <Input
                                ref={(el) => el && (inputsRef.current[el.name] = el)}
                                name='motivo'
                                value={filters.motivo}
                                onChange={handleChange}
                                onKeyUp={(e) => handleNavigation(e, e.target.name)}
                                placeholder='Motivo de la anulación'
                                disabled={filters.accion !== 'ANULAR'}
                            />
                        </FieldGroup>
                    </div>
                    <div className='py-3 rounded bg-blue text-center mb-3'>
                        <Button
                            ref={buttonActionRef}
                            startIcon={<span className={actions[filters.accion].icon} />}
                            disabled={!selected}
                            style={{ width: '8rem' }}
                            onClick={handleAction}
                            onKeyUp={(e) => handleNavigation(e, 'button_action')}
                        >
                            {actions[filters.accion].label}
                        </Button>
                    </div>
                    <div className='row g-3' style={{ padding: '0 6.5rem' }}>
                        <div className='col-12 text-center fw-medium'>Atajos de teclado</div>
                        <div className='col'>
                            <span className='fw-medium'>Campos de búsqueda:</span>
                            <ul className='mb-0'>
                                <li>
                                    <span className='fw-medium'>ENTER</span> para buscar los recibos.
                                </li>
                            </ul>
                            <span className='fw-medium'>Tabla:</span>
                            <ul className='mb-0'>
                                <li>
                                    <span className='fw-medium'>S/W</span> para navegar entre las filas.
                                </li>
                                <li>
                                    <span className='fw-medium'>ENTER</span> para seleccionar la fila.
                                </li>
                                <li>
                                    <span className='fw-medium'>Q</span> para volver a los campos de búsqueda.
                                </li>
                            </ul>
                        </div>
                        <div className='col'>
                            <span className='fw-medium'>Campo de Motivo:</span>
                            <ul className='mb-0'>
                                <li>
                                    <span className='fw-medium'>ENTER</span> para pasar al botón de Anular/Imprimir.
                                </li>
                            </ul>
                            <span className='fw-medium'>Botón Anular/Imprimir:</span>
                            <ul className='mb-0'>
                                <li>
                                    <span className='fw-medium'>E</span> para ir al campo "Motivo".
                                </li>
                                <li>
                                    <span className='fw-medium'>Q</span> para volver a las filas de la tabla.
                                </li>
                                <li>
                                    <span className='fw-medium'>ENTER</span> para ejecutar la acción.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Confirm
                show={isVisible.modalConfirmCancel}
                onAccept={handleCancel}
                onReject={() => hide('modalConfirmCancel')}
                style={{ width: '35rem' }}
                className='rounded-4 overflow-hidden'
                headerClassName='bg-secondary-subtle'
                footerClassName='bg-secondary-subtle text-center'
            >
                <span className='fw-medium fs-4' style={{ lineHeight: 1.25 }}>
                    Esta seguro de <span className='text-primary'>ANULAR</span> el recibo de: "{selected?.persona}"
                </span>
            </Confirm>
        </div>
    )
}
