import store from 'store'

/**
 * Verifica si el usuario actual tiene todos los roles y/o permisos requeridos.
 * @param {string | Array<string>} required Roles y/o permisos requeridos.
 * @param {'AND' | 'OR'} operator Operador de comprobación.
 * @returns Retorna true si el usuario tiene los roles y/o permisos requeridos.
 */
export const verifyAccess = (required, operator = 'AND') => {
    const roles = store.getState()?.auth?.roles ?? []
    const permissions = Object.keys(store.getState()?.auth?.permissions ?? {})
    const itemNames = Array.isArray(required) ? required : required ? [required] : []
    
    // Si itemNames está vacío, retorna true de inmediato
    if (itemNames.length === 0) {
        return true
    }

    const verifyAll = operator === 'AND'

    // Si verifyAll es true, verificar que el usuario tenga TODOS los roles y permisos.
    if (verifyAll) {
        for (const itemName of itemNames) {
            if (!roles.includes(itemName) && !permissions.includes(itemName)) {
                // Si el usuario no tiene uno de los roles o permisos, retorna false
                return false
            }
        }
        return true // El usuario tiene todos los roles y permisos
    }

    // Si verifyAll es false, verificar que el usuario tenga AL MENOS un rol o permiso de los requeridos.
    for (const itemName of itemNames) {
        if (roles.includes(itemName) || permissions.includes(itemName)) {
            return true // El usuario tiene al menos uno de los roles o permisos
        }
    }

    return false // El usuario no tiene ninguno de los roles ni permisos
}
