import Loader from 'components/Loader'
import { Button } from 'components/modular/Button'
import { Checkbox } from 'components/modular/Checkbox'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Select } from 'components/modular/Select'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const initialCuenta = {
    fecha_inicio: '',
    fecha_limite: '',
    nombre: '',
    tipo_monto: '',
    precio: '0.00',
    concepto_id: '',
    estructura_id: '',
    unidad_id: '',
    codigo: '',
    documento_id: '',
    modalidad_opcion_id: '',
    aplicacion_monto_id: '',
    plan_estudio_id: '',
    activo: true,
    imprime: true,
    valorado: false,
    posgrado: false,
    factura: false,
    caja: true,
    electronico: false,
    potulante: false,
    omega_id: '',
    aclaracion: '',
}

export default function FormCuenta() {
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [values, setValues] = useState(initialCuenta)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (id) {
            loadCuenta()
        } else {
            loading && setLoading(false)
        }
    }, [id])

    /** Carga los datos de la cuenta para edición. */
    const loadCuenta = async () => {
        !loading && setLoading(true)
        setLoading(false)
    }

    const handleChange = (e) => {
        const name = e.target.name
        setValues((prev) => ({ ...prev, [name]: e.target.value }))
        errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div className='d-flex h-100 p-4'>
            <div className='container p-0 mx-auto my-0 h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>{id ? 'Editar Cuenta' : 'Crear Cuenta'}</h2>
                </div>
                <div className='bg-white p-3'>
                    <form>
                        <div className='row g-3 mb-3'>
                            <FieldGroup label='Fecha Inicio' className='col-6 mb-0'>
                                <Input
                                    type='date'
                                    name='fecha_inicio'
                                    value={values.fecha_inicio}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='Fecha Fin' className='col-6 mb-0'>
                                <Input
                                    type='date'
                                    name='fecha_limite'
                                    value={values.fecha_limite}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='Nombre' className='col-12 mb-0'>
                                <Input name='nombre' value={values.nombre} onChange={handleChange} />
                            </FieldGroup>
                            <FieldGroup label='Tipo de Monto' className='col-6 mb-0'>
                                <Select
                                    options={[]}
                                    name='tipo_monto'
                                    value={values.tipo_monto}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='Precio' className='col-6 mb-0'>
                                <Input
                                    type='currency'
                                    name='precio'
                                    value={values.precio}
                                    onChange={handleChange}
                                    className='text-end'
                                />
                            </FieldGroup>
                            <FieldGroup label='Concepto' className='col-4 mb-0'>
                                <Select
                                    options={[]}
                                    name='concepto_id'
                                    value={values.concepto_id}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='DA' className='col-4 mb-0'>
                                <Select
                                    options={[]}
                                    name='estructura_id'
                                    value={values.estructura_id}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='Unidad' className='col-4 mb-0'>
                                <Select
                                    options={[]}
                                    name='unidad_id'
                                    value={values.unidad_id}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='Código' className='col-12 mb-0'>
                                <Input name='codigo' value={values.codigo} onChange={handleChange} />
                            </FieldGroup>
                            <FieldGroup label='Documento' className='col-3 mb-0'>
                                <Select
                                    options={[]}
                                    name='documento_id'
                                    value={values.documento_id}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='Modalidad' className='col-3 mb-0'>
                                <Select
                                    options={[]}
                                    name='modalidad_opcion_id'
                                    value={values.modalidad_opcion_id}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='Aplicación' className='col-3 mb-0'>
                                <Select
                                    options={[]}
                                    name='aplicacion_monto_id'
                                    value={values.aplicacion_monto_id}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup label='Plan de Estudio' className='col-3 mb-0'>
                                <Select
                                    options={[]}
                                    name='plan_estudio_id'
                                    value={values.plan_estudio_id}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <div className='col-3 lh-1'>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='activo'
                                        value={values.activo}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Activo</span>
                                </label>
                            </div>
                            <div className='col-3 lh-1'>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='imprime'
                                        value={values.imprime}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Imprime</span>
                                </label>
                            </div>
                            <div className='col-3 lh-1'>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='valorado'
                                        value={values.valorado}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Valorado</span>
                                </label>
                            </div>
                            <div className='col-3 lh-1'>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='posgrado'
                                        value={values.posgrado}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Posgrado</span>
                                </label>
                            </div>
                            <div className='col-3 lh-1'>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='factura'
                                        value={values.factura}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Factura</span>
                                </label>
                            </div>
                            <div className='col-3 lh-1'>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='caja'
                                        value={values.caja}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Caja</span>
                                </label>
                            </div>
                            <div className='col-3 lh-1'>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='electronico'
                                        value={values.electronico}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Electrónico</span>
                                </label>
                            </div>
                            <div className='col-3 lh-1'>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='postulante'
                                        value={values.postulante}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>Deuda</span>
                                </label>
                            </div>
                            <FieldGroup label='ID omega' className='col-3 mb-0'>
                                <Input name='omega_id' value={values.omega_id} onChange={handleChange} />
                            </FieldGroup>
                            <FieldGroup label='Aclaración' className='col-9 mb-0'>
                                <Input name='aclaracion' value={values.aclaracion} onChange={handleChange} />
                            </FieldGroup>
                            <FieldGroup label='Cuentas asociadas' className='col-12 mb-0'>
                                <div></div>
                            </FieldGroup>
                        </div>
                        <div className='text-center'>
                            <Button
                                variant='secondary'
                                startIcon={<span className='bi-x-lg' />}
                                disabled={submitting}
                                className='me-3'
                            >
                                Cancelar
                            </Button>
                            <Button startIcon={<span className='bi-floppy' />} loading={submitting}>
                                Guardar
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
