import { clean, formatDate, pick } from 'components/modular/utils'
import useHttpToast from 'hooks/useHttpToast'
import SalesReport from 'Reports/SalesReport'
import TuitionReport from 'Reports/TuitionReport'
import RecaudacionesServices from 'services/recaudaciones.service'

export default function useRecibo() {
    const { showHttpToast } = useHttpToast()

    /**
     * Realiza la venta de valores.
     * @param {object} bodyData Datos de la venta.
     * @param {Function} onSuccess Callback que se ejecuta cuando la operación es exitosa.
     */
    const sell = async (bodyData, onSuccess = undefined) => {
        const { status, data } = await RecaudacionesServices.ventaValores.sell(bodyData)
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            print(data.data.recibos)
            onSuccess?.(data.data)
        }
    }

    /**
     * Genera el pdf de un recibo. Del array de recibos genera el primero en varios
     * solo cambiando el número de recibo (número de control).
     * @param {Array<object>} recibos Datos de los recibos.
     */
    const print = (recibos) => {
        if (recibos.length) {
            if (recibos.length === 1 && recibos[0].sistema === 'sis') {
                const report = new TuitionReport()
                const recibo = recibos[0]
                const reportParams = [
                    [recibo.recibo],
                    recibo.persona,
                    recibo.detalles.map((d) => ({
                        concept: d.unidad_cuenta,
                        cost: d.monto,
                    })),
                    recibo.persona_documento,
                    recibo.persona_codigo ?? '',
                    formatDate(recibo.fecha_pago, 'd-m-Y H:i'),
                    {
                        BCI: recibo.periodo,
                        CYR: recibo.gestion,
                        qual: recibo.carrera_codigo,
                        qname: recibo.carrera,
                    },
                    recibo.usuario,
                ]
                report.generateReport(...reportParams)
            } else {
                const report = new SalesReport()
                const recibo = recibos[0]
                const reportParams = [
                    recibos.map((r) => r.recibo, []),
                    recibo.usuario,
                    recibo.persona,
                    recibo.detalles.map((d) => ({
                        concept: d.unidad_cuenta,
                        cost: d.monto,
                    })),
                    recibo.observacion ?? '',
                    recibo.persona_documento,
                    recibo.persona_codigo ?? '',
                    formatDate(recibo.fecha_pago, 'd-m-Y H:i'),
                    recibos.length,
                ]
                report.generateReport(...reportParams)
            }
        }
    }

    /**
     * Busca el recibo y lo genera en pdf.
     * @param {object} bodyData Datos del recibo.
     * @param {number} bodyData.recibo Número de recibo. Requerido.
     * @param {string} bodyData.fecha_pago Fecha de pago del recibo. Requerido.
     * @param {number} bodyData.estructura_id ID de estructura.
     * @param {number} bodyData.user_id ID de usuario.
     */
    const reprint = async (bodyData) => {
        const _params = pick(bodyData, ['recibo', 'fecha_pago', 'estructura_id', 'user_id'])
        const { status, data } = await RecaudacionesServices.ventaValores.print(_params)
        if (status === 200) {
            print([data.data])
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }

    /**
     * Anula un recibo.
     * @param {object} bodyData Datos del recibo.
     * @param {number} bodyData.recibo Número de recibo. Requerido.
     * @param {string} bodyData.fecha_pago Fecha de pago del recibo. Requerido.
     * @param {number} bodyData.estructura_id ID de estructura.
     * @param {number} bodyData.user_id ID de usuario.
     * @param {string} bodyData.motivo Motivo de la anulación.
     * @param {Function} onSuccess Callback que se ejecuta cuando la operación es exitosa.
     */
    const cancel = async (bodyData, onSuccess) => {
        const _params = clean(pick(bodyData, ['recibo', 'fecha_pago', 'estructura_id', 'user_id', 'motivo']))
        const { status, data } = await RecaudacionesServices.ventaValores.cancel(_params)
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            onSuccess?.()
        }
    }

    /**
     * Paga una deuda
     * @param {number} id ID de la deuda.
     * @param {object} bodyData Datos para el pago de la deuda.
     * @param {Function} onSuccess Callback que se ejecuta cuando la operación es exitosa.
     */
    const payDeuda = async (id, bodyData, onSuccess = undefined) => {
        const { status, data } = await RecaudacionesServices.ventaValores.payDeuda(id, bodyData)
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            print([data.data.recibo])
            onSuccess?.(data.data)
        }
    }

    /**
     * Caduca una deuda.
     * @param {number} id ID de la deuda.
     * @param {Function} onSuccess Callback que se ejecuta cuando la operación es exitosa.
     */
    const expireDeuda = async (id, onSuccess = undefined) => {
        const { status, data } = await RecaudacionesServices.ventaValores.expireDeuda(id)
        showHttpToast(status, { detail: data.message })
        if (status === 200) onSuccess?.()
    }

    return { sell, print, reprint, cancel, payDeuda, expireDeuda }
}
