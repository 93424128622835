import Header from 'Component/Header'
import { useRef, useState } from 'react'
import { Button } from 'components/modular/Button'
import RecaudacionesServices from 'services/recaudaciones.service'
import useHttpToast from 'hooks/useHttpToast'
import DatosCuenta from './components/DatosCuenta'
import ControlCierre from 'modules/recaudaciones/components/ControlCierre'

export default function VentaPreValorados() {
    const { showHttpToast } = useHttpToast()
    const inputRefs = useRef({})
    const datosCuentaRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)
        await handleSell()
        setSubmitting(false)
    }
    const handleSell = async () => {
        if (!datosCuentaRef.current || datosCuentaRef.current.validateCuenta()) {
            return
        }
        const cuenta = datosCuentaRef.current.getCuenta()
        console.log(cuenta)

        const bodyData = {
            cuenta_id: cuenta.id,
            cantidad: cuenta.cantidad,
            precio: cuenta.precio,
        }
        console.log(bodyData)
        const { status, data } = await RecaudacionesServices.ventaPreValorados.sell(bodyData)
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            resetForm()
        }
    }

    const resetForm = () => {
        datosCuentaRef.current?.resetCuenta()
        inputRefs.current['cuenta.codigo']?.focus()
    }

    const saveRef = (el, name) => {
        inputRefs.current[name] = el
    }

    return (
        <div className='h-100 d-flex flex-column align-items-center'>
            <Header title='VENTA DE PRE VALORADOS' />
            <ControlCierre>
                <div className='container shadow-sm p-4 bg-body rounded'>
                    <form
                        onSubmit={handleSubmit}
                        onKeyDown={(e) => {
                            if (e.target.tagName !== 'BUTTON') {
                                e.key === 'Enter' && e.preventDefault()
                            }
                        }}
                        noValidate
                    >
                        <DatosCuenta
                            innerRef={datosCuentaRef}
                            inputRefs={inputRefs}
                            saveRef={saveRef}
                            className='mb-3'
                        />
                        <div className='bg-blue py-3 text-center rounded'>
                            <Button
                                ref={(el) => saveRef(el, 'buttonTerminar')}
                                type='submit'
                                startIcon={<span className='bi-floppy' />}
                                loading={submitting}
                            >
                                Grabar
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={resetForm}
                                className='ms-3'
                                startIcon={<span className='bi-x-lg' />}
                                disabled={submitting}
                            >
                                Limpiar
                            </Button>
                        </div>
                    </form>
                </div>
            </ControlCierre>
        </div>
    )
}
