import useHttpToast from 'hooks/useHttpToast'
import { AdvancedSelect } from 'components/modular/AdvancedSelect'
import { Button } from 'components/modular/Button'
import { Checkbox } from 'components/modular/Checkbox'
import { EditableSelect } from 'components/modular/EditableSelect'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Select } from 'components/modular/Select'
import { classNames, empty } from 'components/modular/utils'
import { useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import ContabilidadServices from 'services/contabilidad.service'
import { omit, pick } from 'utils'
import { verifyAccess } from 'utils/verifyAccess'

const actividadOptionTemplate = (actividad) => (
    <span title={actividad.descripcion}>
        {`${actividad.codigo_actividad} / ${actividad.abreviacion_da} -  ${actividad.descripcion}`}
    </span>
)
const popoverCorreo = (
    <Popover>
        <Popover.Header as='h3'>Correo / CI</Popover.Header>
        <Popover.Body className='py-2'>
            Ejemplos de valores aceptados:
            <br />
            <span className='bi-envelope-at-fill' /> Correo Institucional
            <br />
            <span className='bi-check-lg text-success' /> p.perez@umss.edu
            <br />
            <span className='bi-check-lg text-success' /> p.perez@umss.edu.bo
            <br />
            <span className='bi-person-vcard-fill' /> Carnet de Identidad
            <br />
            <span className='bi-check-lg text-success' /> 9876543
            <br />
            <span className='bi-check-lg text-success' /> 9876543-2A
        </Popover.Body>
    </Popover>
)

const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/

/** Valida los datos de un usuario para creación. */
const validateCreate = (values) => {
    const _errors = {}
    if (!values.email) _errors.email = 'Requerido'
    if (!values.nombres) _errors.nombres = 'Requerido'
    if (!values.apellidos) _errors.apellidos = 'Requerido'
    if (values.beneficiario_fondo_rotatorio || values.beneficiario_fondo_avance) {
        if (!values.actividad_id && !values.actividad?.id) {
            _errors.actividad = 'Requerido'
        }
    }
    if (['AdministradorContabilidadDA', 'ReportesPresupuestosDA'].includes(values.rol) && values.das.length === 0) {
        _errors.das = 'Requerido'
    }
    if (!values.password) _errors.password = 'Requerido'
    if (!values.confirmar) _errors.confirmar = 'Requerido'
    if (values.password && values.confirmar) {
        if (values.password === values.confirmar) {
            if (!pwdRegex.test(values.password)) {
                _errors.password = 'Formato incorrecto'
            }
        } else {
            _errors.confirmar = 'Las contraseñas deben coincidir'
        }
    }
    return empty(_errors) ? null : _errors
}
/** Valida los datos de un usuario para edición. */
const validateUpdate = (values) => {
    const _errors = {}
    if (values.beneficiario_fondo_rotatorio || values.beneficiario_fondo_avance) {
        if (!values.actividad_id && !values.actividad?.id) {
            _errors.actividad = 'Requerido'
        }
    }
    if (['AdministradorContabilidadDA', 'ReportesPresupuestosDA'].includes(values.rol) && values.das.length === 0) {
        _errors.das = 'Requerido'
    }
    return empty(_errors) ? null : _errors
}
const availableRoles = [
    { label: 'Administrador Contabilidad DA', value: 'AdministradorContabilidadDA' },
    { label: 'Administrador Presupuestos', value: 'AdministradorPresupuestos' },
    { label: 'Presupuestos DA', value: 'ReportesPresupuestosDA' },
]
const initialValues = {
    id: '',
    email: '',
    ci: '',
    nombres: '',
    apellidos: '',
    username: '',
    activo: true,
    actividad_id: '',
    actividad: '',
    beneficiario_fondo_rotatorio: false,
    beneficiario_fondo_avance: false,
    rol: '',
    das: [],
    password: '',
    confirmar: '',
    info: '',
    send_account: true,
    default_password: false,
}
export default function FormUsuario({ id, onCancel, onSuccessUpdate, onSuccessCreate }) {
    const { showToast, showHttpToast } = useHttpToast()
    const [searchValue, setSearchValue] = useState('')
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [searching, setSearching] = useState(false)
    const [actividades, setActividades] = useState([])
    const [direcciones, setDirecciones] = useState([])
    const [isVisible, setIsVisible] = useState({
        fieldPassword: false,
        fieldConfirmar: false,
    })

    useEffect(() => {
        const loadDirecciones = async () => {
            const { status, data } = await ContabilidadServices.allDa()
            if (status === 200) {
                setDirecciones(data.data)
            }
        }
        loadDirecciones()
    }, [])
    useEffect(() => {
        if (id) {
            const loadUsuario = async () => {
                const { status, data } = await ContabilidadServices.usuario.view(id)
                if (status === 200) {
                    setUsuario(data.data)
                }
            }
            loadUsuario()
        }
    }, [id])

    const setUsuario = (data) => {
        const { das, roles } = data
        let rol = ''
        if (roles.includes('AdministradorContabilidadDA')) {
            rol = 'AdministradorContabilidadDA'
        } else if (roles.includes('AdministradorPresupuestos')) {
            rol = 'AdministradorPresupuestos'
        } else if (roles.includes('ReportesPresupuestosDA')) {
            rol = 'ReportesPresupuestosDA'
        }
        const password = `${data.nombres.trim().charAt(0)}${data.apellidos.trim().split(' ')[0].toLowerCase()}-${
            data.ci
        }`
        setValues({
            ...initialValues,
            ...omit(data, ['created_at', 'fuente_fondo', 'monto_asignado', 'das']),
            das: das.map((da) => da.id),
            beneficiario_fondo_rotatorio: roles.includes('BeneficiarioFondoRotatorio'),
            beneficiario_fondo_avance: roles.includes('BeneficiarioFondoAvance'),
            rol,
            password,
            confirmar: password,
        })
    }

    const toggleVisibility = (name) => setIsVisible((prev) => ({ ...prev, [name]: !prev[name] }))

    const handleChange = (e) => {
        const name = e.target.name
        setValues((prev) => ({ ...prev, [name]: e.target.value }))
        errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)
        const userRoles = []
        if (values.beneficiario_fondo_rotatorio) userRoles.push('BeneficiarioFondoRotatorio')
        if (values.beneficiario_fondo_avance) userRoles.push('BeneficiarioFondoAvance')
        if (values.rol) userRoles.push(values.rol)

        const data = {
            actividad_id:
                values.actividad?.id ??
                (typeof values.actividad === 'string' && values.actividad.trim() === '' ? null : values.actividad_id),
            roles: userRoles,
            activo: values.activo,
            das: values.das,
        }
        if (values.id) {
            await handleUpdate(values.id, data)
        } else {
            await handleCreate({
                ...data,
                ...pick(values, ['password', 'info', 'send_account', 'default_password']),
                username: `${values.nombres.trim().charAt(0)}${values.apellidos.trim().split(' ')[0]}`,
            })
        }
        setSubmitting(false)
    }
    const handleCreate = async (bodyParams) => {
        const _errors = validateCreate(values)
        if (_errors) {
            setErrors(_errors)
        } else {
            const { status, data } = await ContabilidadServices.usuario.create(bodyParams)
            showHttpToast(status, { detail: data.message })
            if (status === 201) {
                if (!data.data.successAssignRoles) {
                    showHttpToast(400, {
                        detail: 'Algunos roles no se asignaron correctamente. Por favor, revisar los roles.',
                        sticky: true,
                    })
                }
                if (!data.data.successSendAccount) {
                    showHttpToast(500, {
                        detail: 'No se pudo enviar el correo con los datos de la cuenta.',
                        sticky: true,
                    })
                }
                onSuccessCreate?.()
            } else if (status === 422) {
                const _errors = Object.keys(data.errors).reduce((carry, field) => {
                    carry[field] = data.errors[field][0]
                    return carry
                }, {})
                setErrors(_errors)
            }
        }
    }
    const handleUpdate = async (id, bodyParams) => {
        const _errors = validateUpdate(values)
        if (_errors) {
            setErrors(_errors)
        } else {
            const { status, data } = await ContabilidadServices.usuario.update(id, bodyParams)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                onSuccessUpdate?.()
            }
        }
    }

    const searchFuncionario = async () => {
        setSearching(true)
        if (searchValue) {
            const { status, data } = await ContabilidadServices.usuario.buscarFuncionario(searchValue)
            if (status === 200) {
                setUsuario(data.data)
                setErrors({})
            } else if (status === 404) {
                showToast({ title: 'Información', detail: data.message })
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        setSearching(false)
    }
    /**
     * Busca las actividades de la estructura programática que coinciden con el código pasado como parametro.
     * @param {string} codigo Código de la actividad con el siguiente formato:
     * - 01-02-000-000-10 -> DAF, 2 dígitos DA + 2 dígitos UE + 3 dígitos Programa + 3 dígitos Proyecto + 2 dígitos Actividad
     */
    const searchActividades = async (codigo) => {
        if (codigo.length > 0) {
            if (codigo.length < 17) {
                const { status, data } = await ContabilidadServices.usuario.searchActividades(codigo)
                if (status === 200) {
                    setActividades(data.data)
                }
            }
        } else {
            setActividades([])
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='p-3'>
                <div className={classNames('row gy-2 gx-3', values.id && 'mb-2')}>
                    {!values.id && (
                        <FieldGroup
                            label={
                                <span className='text-nowrap'>
                                    Buscar
                                    <OverlayTrigger placement='top' overlay={popoverCorreo}>
                                        <span className='bi-info-circle-fill mx-1 text-primary' />
                                    </OverlayTrigger>
                                    :
                                </span>
                            }
                            feedback={errors.funcionario}
                            showFeedback={!!errors.funcionario}
                            inline
                            className='col-12 mb-0'
                        >
                            <div className='d-flex gap-3'>
                                <Input
                                    name='funcionario'
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                    onKeyUp={(e) => e.key === 'Enter' && searchFuncionario()}
                                    placeholder='Correo Institucional o CI'
                                />
                                <Button
                                    onClick={searchFuncionario}
                                    className='py-0 px-1 lh-1'
                                    style={{ fontSize: '1.25rem', width: '4rem' }}
                                >
                                    {searching ? (
                                        <div className='spinner-border spinner-border-sm' role='status'>
                                            <span className='visually-hidden'>Loading...</span>
                                        </div>
                                    ) : (
                                        <div>
                                            <span className='bi-person-fill' />
                                            <span className='bi-list' />
                                        </div>
                                    )}
                                </Button>
                            </div>
                        </FieldGroup>
                    )}
                    <FieldGroup label='Correo' className='col-sm-6 mb-0'>
                        <div
                            className={classNames('form-control', errors.nombres && 'is-invalid')}
                            style={{ backgroundColor: '#e9ecef' }}
                        >
                            {values.email || <span className='text-transparent'>_</span>}
                        </div>
                    </FieldGroup>
                    <FieldGroup label='CI' className='col-sm-6 mb-0'>
                        <div
                            className={classNames('form-control', errors.ci && 'is-invalid')}
                            style={{ backgroundColor: '#e9ecef' }}
                        >
                            {values.ci || <span className='text-transparent'>_</span>}
                        </div>
                    </FieldGroup>
                    <FieldGroup label='Nombres' className='col-sm-6 mb-0'>
                        <div
                            className={classNames('form-control', errors.nombres && 'is-invalid')}
                            style={{ backgroundColor: '#e9ecef' }}
                        >
                            {values.nombres || <span className='text-transparent'>_</span>}
                        </div>
                    </FieldGroup>
                    <FieldGroup label='Apellidos' className='col-sm-6 mb-0'>
                        <div
                            className={classNames('form-control', errors.nombres && 'is-invalid')}
                            style={{ backgroundColor: '#e9ecef' }}
                        >
                            {values.apellidos || <span className='text-transparent'>_</span>}
                        </div>
                    </FieldGroup>
                    {verifyAccess('AdministradorContabilidad') && (
                        <>
                            <div className='col-12 border-bottom border-secondary-subtle'></div>
                            <FieldGroup label='Rol' className='col-sm-6 mb-0'>
                                <Select
                                    options={availableRoles}
                                    name='rol'
                                    value={values.rol}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                            <FieldGroup
                                label="DA's"
                                className='col-sm-6 mb-0'
                                feedback={errors.das}
                                showFeedback={!!errors.das}
                            >
                                <AdvancedSelect
                                    options={direcciones}
                                    optionValue='id'
                                    optionLabel='descripcion'
                                    optionTemplate={(option) => `${option.codigo} - ${option.descripcion}`}
                                    className='w-100'
                                    selectClassName={errors.das ? 'is-invalid' : undefined}
                                    menuClassName='w-100'
                                    multiSelection
                                    name='das'
                                    value={values.das}
                                    onChange={handleChange}
                                />
                            </FieldGroup>
                        </>
                    )}
                    <div className='col-12 border-bottom border-secondary-subtle'></div>
                    <FieldGroup
                        label='Actividad'
                        className='col-sm-6 mb-0'
                        feedback={errors.actividad}
                        showFeedback={!!errors.actividad}
                    >
                        <EditableSelect
                            options={actividades}
                            optionValue='codigo_actividad'
                            optionTemplate={actividadOptionTemplate}
                            valueAsOption
                            name='actividad'
                            value={values.actividad}
                            onChange={handleChange}
                            isInvalid={!!errors.actividad}
                            placeholder='Buscar'
                            className='w-100'
                            menuStyle={{ minWidth: '100%' }}
                            lazy={true}
                            onSearch={searchActividades}
                        />
                    </FieldGroup>
                    <div className='col-sm-6'>
                        <div className='h-100 d-flex flex-column justify-content-evenly'>
                            <label className='d-flex align-items-center'>
                                <Checkbox
                                    name='beneficiario_fondo_rotatorio'
                                    value={values.beneficiario_fondo_rotatorio}
                                    onChange={handleChange}
                                    style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                    className='me-2'
                                />
                                Beneficiario de Fondo Rotatorio
                            </label>
                            <label className='d-flex align-items-center'>
                                <Checkbox
                                    name='beneficiario_fondo_avance'
                                    value={values.beneficiario_fondo_avance}
                                    onChange={handleChange}
                                    style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                    className='me-2'
                                />
                                Beneficiario de Fondo en Avance
                            </label>
                        </div>
                    </div>
                    {!values.id && (
                        <>
                            <div className='col-12 border-bottom border-secondary-subtle'></div>
                            <FieldGroup
                                label='Contraseña'
                                className='col-sm-6 mb-0'
                                feedback={errors.password}
                                showFeedback={!!errors.password}
                            >
                                <div className='position-relative'>
                                    <Input
                                        name='password'
                                        type={isVisible.fieldPassword ? 'text' : 'password'}
                                        value={values.password}
                                        onChange={handleChange}
                                        isInvalid={errors.password}
                                        className='bg-img-none'
                                        style={{ paddingRight: '2.5rem' }}
                                    />
                                    <div
                                        className='position-absolute cursor-pointer'
                                        style={{
                                            fontSize: '1.25rem',
                                            lineHeight: '1.5rem',
                                            right: '.75rem',
                                            top: '.375rem',
                                        }}
                                        onClick={() => toggleVisibility('fieldPassword')}
                                    >
                                        <span className={isVisible.fieldPassword ? 'bi-eye-slash' : 'bi-eye'} />
                                    </div>
                                </div>
                            </FieldGroup>
                            <FieldGroup
                                label='Confirmar Contraseña'
                                className='col-sm-6 mb-0'
                                feedback={errors.confirmar}
                                showFeedback={!!errors.confirmar}
                            >
                                <div className='position-relative'>
                                    <Input
                                        name='confirmar'
                                        type={isVisible.fieldConfirmar ? 'text' : 'password'}
                                        value={values.confirmar}
                                        onChange={handleChange}
                                        isInvalid={errors.confirmar}
                                        className='bg-img-none'
                                        style={{ paddingRight: '2.5rem' }}
                                    />
                                    <div
                                        className='position-absolute cursor-pointer'
                                        style={{
                                            fontSize: '1.25rem',
                                            lineHeight: '1.5rem',
                                            right: '.75rem',
                                            top: '.375rem',
                                        }}
                                        onClick={() => toggleVisibility('fieldConfirmar')}
                                    >
                                        <span className={isVisible.fieldConfirmar ? 'bi-eye-slash' : 'bi-eye'} />
                                    </div>
                                </div>
                            </FieldGroup>
                            <div className='col-12'>
                                <div className='text-primary fst-italic mb-2' style={{ lineHeight: '1.25' }}>
                                    <span className='bi-info-circle-fill' /> La contraseña debe contener al menos una
                                    mayúscula, una minúscula, un número y un carácter especial.
                                </div>
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='send_account'
                                        value={values.send_account}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>
                                        Enviar datos de la cuenta de usuario por correo.
                                    </span>
                                </label>
                                <br />
                                <label className='d-inline-flex align-items-center'>
                                    <Checkbox
                                        name='default_password'
                                        value={values.default_password}
                                        onChange={handleChange}
                                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                                        className='me-2'
                                    />
                                    <span className='fw-medium select-none'>
                                        Solicitar cambio de contraseña al iniciar sesión por primera vez.
                                    </span>
                                </label>
                            </div>
                        </>
                    )}
                </div>
                <label className='d-inline-flex align-items-center'>
                    <Checkbox
                        name='activo'
                        value={values.activo}
                        onChange={handleChange}
                        style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                        className='me-2'
                    />
                    <span className='fw-medium select-none'>Activo</span>
                </label>
            </div>
            <div className='px-3 py-2 border-top text-end'>
                <Button
                    variant='outline-primary'
                    className='me-2'
                    startIcon={<span className='bi-x-lg' />}
                    onClick={onCancel}
                    disabled={submitting}
                >
                    Cancelar
                </Button>
                <Button type='submit' startIcon={<span className='bi-floppy' />} loading={submitting}>
                    Guardar
                </Button>
            </div>
        </form>
    )
}
