/**
 * Elimina propiedades de un objeto por valor o clave.
 * @param obj Objecto a limpiar.
 * @param values Valores a eliminar.
 * @returns Objeto limpio.
 */
export function clean(obj, values) {
    const _values = values ?? [undefined, null, '']
    return Object.fromEntries(Object.entries(obj).filter((prop) => !_values.includes(prop[1])))
}
