import { clean, formatDate, pick } from 'components/modular/utils'
import ReportByAccountUnitDiezmo from 'Reports/ReportByAccountUnitDiezmo'
import ReportConCuentaUnid from 'Reports/ReportConCuentaUnid'
import RecaudacionesServices from 'services/recaudaciones.service'
import ExcelConCuentaUnidad from 'ReportsExcel/ExcelConCuentaUnidad'
import ExcelReportDiezmoAccountUnit from 'ReportsExcel/ExcelReportDiezmoAccountUnit'
import ReportConsolidadoConcepto from 'Reports/ReportConsolidadoConcepto'
import ExcelConcepto from 'ReportsExcel/ExcelConcepto'
import ReportConsolidadoUnidad from 'Reports/ReportConsolidadoUnidad'
import ExcelUnidad from 'ReportsExcel/ExcelUnidad'
import ReportConsolidadoInstitucional from 'Reports/ReportConsolidadoInstitucional'
import ReportCobroComisionBanco from 'Reports/ReportCobroComisionBanco'
import ReportDistribucionAportes from 'Reports/ReportDistribucionAportes'
import ReportRecaudacionRecibos from 'Reports/ReportRecaudacionesRecibos'
import ExcelRecRecibos from 'ReportsExcel/ExcelRecRecibos'
import RecaudacionesPorCuentaPDF from 'Reports/recaudaciones/RecaudacionesPorCuentaPDF'
import RecaudacionesPorCuentaExcel from 'ReportsExcel/recaudaciones/RecaudacionesPorCuentaExcel'
import ReportTiposEstudiante from 'Reports/ReportTiposEstudiante'
import ExcelTiposEstudiante from 'ReportsExcel/ExcelTiposEstudiante'
import ReportAnulados from 'Reports/ReportAnulados'
import ExcelAnulados from 'ReportsExcel/ExcelAnulados'
import KardexValores from 'Reports/KardexValores'
import ExcelValores from 'ReportsExcel/ExcelValores'
import ReportResumenRecibos from 'Reports/ReportResumenRecibos'
import useHttpToast from 'hooks/useHttpToast'
import ReportLibroVentas from 'Reports/Facturacion/ReportLibroVentas'
import ReportDetalleFacturas from 'Reports/Facturacion/ReportDetalleFacturas'
import ReportFacturas from 'Reports/Facturacion/ReportFacturas'
import ReportConceptoFacturas from 'Reports/Facturacion/ReportConceptoFacturas'
import ReportUnidadCuentaFacturas from 'Reports/Facturacion/ReportUnidadCuentaFacturas'
import ReportInstitucionalFacturas from 'Reports/Facturacion/ReportInstitucionalFacturas'

export default function useReportes() {
    const { showHttpToast } = useHttpToast()

    const getOptions = (options = {}) => {
        const type = options?.type ?? 'pdf'
        const reportParams = options?.reportParams ?? []
        const diezmo = options?.diezmo ?? false
        const fileName = options?.fileName ?? `${formatDate(new Date(), 'YmdHis')}_Report`
        return { type, reportParams, diezmo, fileName }
    }

    const consolidadoPorUnidadCuenta = async (filters, options = {}) => {
        const { type, reportParams, diezmo, fileName } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.consolidado.porUnidadCuenta(params)
        if (status === 200) {
            if (type === 'excel') {
                if (diezmo) {
                    const report = new ExcelReportDiezmoAccountUnit()
                    report.generateExcelFile(data.data, ...reportParams, fileName)
                } else {
                    const report = new ExcelConCuentaUnidad()
                    report.excelReport(data.data, ...reportParams, fileName)
                }
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.usuario,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = params.facturas
                    ? new ReportUnidadCuentaFacturas()
                    : diezmo
                    ? new ReportByAccountUnitDiezmo()
                    : new ReportConCuentaUnid()
                report.generateReport(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const consolidadoPorConcepto = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.consolidado.porConcepto(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelConcepto()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = params.facturas ? new ReportConceptoFacturas() : new ReportConsolidadoConcepto()
                report.generateReport(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const consolidadoPorUnidad = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.consolidado.porUnidad(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelUnidad()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportConsolidadoUnidad()
                report.generateReport(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const consolidadoInstitucional = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = clean(filters)
        const { status, data } = await RecaudacionesServices.reportes.consolidado.institucional(params)
        if (status === 200) {
            const _reportParams = [
                'Todos',
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
                reportParams.unidad,
                reportParams.filters.electronico,
                reportParams.filters.caja,
            ]
            const report = params.facturas ? new ReportInstitucionalFacturas() : new ReportConsolidadoInstitucional()
            report.generateReport(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }

    const recibosPorCajero = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.recibos.porCajero(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelRecRecibos()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportRecaudacionRecibos()
                report.generarPdf(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const recibosPorCuenta = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.recibos.porCuenta(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new RecaudacionesPorCuentaExcel()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const report = new RecaudacionesPorCuentaPDF()
                report.generatePdf(data.data, reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const recibosDetallados = async (filters, options = {}) => {
        const { type, reportParams } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.recibos.detallados(params)
        if (status === 200) {
            if (type === 'excel') {
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportResumenRecibos()
                report.generarPdf(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const recibosAnulados = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.recibos.anulados(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelAnulados()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.usuario,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportAnulados()
                report.generatePDF(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const recibosDeTiposEstudiante = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.recibos.deTiposEstudiante(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelTiposEstudiante()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportTiposEstudiante()
                report.generarPdf(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }

    const cobroComisionBancaria = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = pick(filters, ['fecha_inicio', 'fecha_fin'])
        const { status, data } = await RecaudacionesServices.reportes.cobroComisionBancaria(params)
        if (status === 200) {
            const _reportParams = [
                'Todos',
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
                reportParams.unidad,
                reportParams.filters.electronico,
            ]
            const report = new ReportCobroComisionBanco()
            report.generateReport(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const distribucionAportes = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = pick(filters, ['fecha_inicio', 'fecha_fin'])
        const { status, data } = await RecaudacionesServices.reportes.distribucionAportes(params)
        if (status === 200) {
            const _reportParams = [
                'Todos',
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
            ]
            const report = new ReportDistribucionAportes()
            report.generateReport(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const preValorados = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.valores(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelValores()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.usuario,
                    reportParams.modePrint,
                    reportParams.unidad,
                ]
                const report = new KardexValores()
                report.generatePDF(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }

    const libroVentas = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.libroVentas(params)
        if (status === 200) {
            const report = new ReportLibroVentas()
            const _reportParams = [
                reportParams.filters.usuario,
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
                reportParams.showAdvancedFilters,
                reportParams.filters,
                reportParams.isAdmin,
                reportParams.unidad,
                reportParams.filters.electronico,
                reportParams.filters.caja,
            ]
            report.generarPdf(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }

    const facturasPorCajero = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.facturas.porCajero(params)
        if (status === 200) {
            const report = new ReportDetalleFacturas()
            const _reportParams = [
                reportParams.filters.usuario,
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
                reportParams.showAdvancedFilters,
                reportParams.filters,
                reportParams.isAdmin,
                reportParams.unidad,
                reportParams.filters.electronico,
                reportParams.filters.caja,
            ]
            report.generarPdf(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const facturasDetallados = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = clean(filters)

        const { status, data } = await RecaudacionesServices.reportes.facturas.detallados(params)
        if (status === 200) {
            const report = new ReportFacturas()
            const _reportParams = [
                reportParams.filters.usuario,
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
                reportParams.showAdvancedFilters,
                reportParams.filters,
                reportParams.isAdmin,
                reportParams.unidad,
                reportParams.filters.electronico,
                reportParams.filters.caja,
            ]
            report.generarPdf(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }

    return {
        consolidado: {
            porUnidadCuenta: consolidadoPorUnidadCuenta,
            porConcepto: consolidadoPorConcepto,
            porUnidad: consolidadoPorUnidad,
            institucional: consolidadoInstitucional,
        },
        recibos: {
            porCajero: recibosPorCajero,
            porCuenta: recibosPorCuenta,
            anulados: recibosAnulados,
            detallados: recibosDetallados,
            deTiposEstudiante: recibosDeTiposEstudiante,
        },
        facturas: {
            porCajero: facturasPorCajero,
            detallados: facturasDetallados,
        },
        cobroComisionBancaria,
        distribucionAportes,
        preValorados,
        libroVentas,
    }
}
