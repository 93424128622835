import Header from 'Component/Header'
import { Button } from 'components/modular/Button'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Confirm } from 'components/modular/Modal'
import { Select } from 'components/modular/Select'
import useHttpToast from 'hooks/useHttpToast'
import { useState } from 'react'
import RecaudacionesServices from 'services/recaudaciones.service'

const tipos = {
    D: 'Cierre del Día',
    G: 'Cierre de Gestión',
    '': '',
}
const tipoOptions = [
    {
        label: tipos['D'],
        value: 'D',
    },
    // {
    //     label: tipos['G'],
    //     value: 'G',
    // },
]

export default function Cierre() {
    const { showHttpToast } = useHttpToast()
    const [tipo, setTipo] = useState('D')
    const [isVisible, setIsVisible] = useState({ modalConfirmClose: false })

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleClose = async ({ setLoading }) => {
        setLoading(true)
        const { status, data } = await RecaudacionesServices.ventaValores.close(tipo)
        showHttpToast(status, { detail: data.message })
        hide('modalConfirmClose')
        setLoading(false)
    }

    return (
        <div>
            <Header title='VENTA DE VALORADOS' />
            <div className='d-flex flex-column align-items-center'>
                <div className='container shadow-sm p-4 bg-body rounded'>
                    <div style={{ padding: '2rem 8rem' }}>
                        <FieldGroup label='Tipo de Cierre:' labelClassName='fw-medium' inline>
                            <Select
                                options={tipoOptions}
                                value={tipo}
                                onChange={(e) => setTipo(e.target.value)}
                                placeholder={null}
                            />
                        </FieldGroup>
                        <div className='border rounded px-5 py-3 fw-medium fs-3'>
                            PRECAUCIÓN:
                            <div className='text-center'>
                                Una vez que se realiza el "{tipos[tipo]}", ya no se puede realizar ninguna venta.
                            </div>
                        </div>
                    </div>
                    <div className='bg-blue py-3 text-center rounded'>
                        <Button startIcon={<span className='bi-x-circle' />} onClick={() => show('modalConfirmClose')}>
                            {tipos[tipo]}
                        </Button>
                    </div>
                </div>
            </div>
            <Confirm
                show={isVisible.modalConfirmClose}
                onAccept={handleClose}
                onReject={() => hide('modalConfirmClose')}
                style={{ width: '35rem' }}
                className='rounded-4 overflow-hidden'
                headerClassName='bg-secondary-subtle'
                footerClassName='bg-secondary-subtle text-center'
            >
                <span className='fw-medium lh-1 fs-4'>
                    Esta seguro de realizar el <span className='text-primary'>{tipos[tipo]}</span>?
                </span>
            </Confirm>
        </div>
    )
}
