import { GenericRequest } from './request/request'

const RecaudacionesServices = {
    getTarifarioCuentas: (params = {}) => GenericRequest.get('recaudaciones/tarifario-cuentas', params),
    persona: {
        list: (data, params) => GenericRequest.post('recaudaciones/list-persona', data, params),
        update: (id, data) => GenericRequest.post('recaudaciones/update-persona', data, { id }),
    },
    ventaValores: {
        listCuentas: (params = {}) => GenericRequest.get('venta-valores/list-cuentas-valores', params),
        listCuentasAsociadas: (id) => GenericRequest.get('venta-valores/list-cuentas-asociadas', { id }),
        lastValidas: () => GenericRequest.get('venta-valores/last-validas'),
        nextRecibo: () => GenericRequest.get('venta-valores/next-recibo'),
        searchPersona: (data) => GenericRequest.post('venta-valores/search-persona', data),
        createPersona: (data) => GenericRequest.post('venta-valores/create-persona', data),
        sell: (data) => GenericRequest.post('venta-valores/sell', data),
        cancel: (data) => GenericRequest.post('venta-valores/cancel', data),
        print: (data) => GenericRequest.post('venta-valores/print', data),
        printables: (params = {}) => GenericRequest.get('venta-valores/printables', params),
        cancellables: (params = {}) => GenericRequest.get('venta-valores/cancellables', params),
        isClose: () => GenericRequest.get('venta-valores/is-close'),
        close: (tipo) => GenericRequest.post('venta-valores/close', { tipo }),
        listDeudas: (persona_id) => GenericRequest.post('venta-valores/list-deudas', { persona_id }),
        payDeuda: (movimiento_id, data) => GenericRequest.post('venta-valores/pay-deuda', { movimiento_id, ...data }),
        expireDeuda: (movimiento_id) => GenericRequest.post('venta-valores/expire', { movimiento_id }),
    },
    ventaPreValorados: {
        listCuentas: (params = {}) => GenericRequest.get('venta-valores/list-cuentas-pre-valorados', params),
        sell: (data) => GenericRequest.post('venta-valores/sell-pre-valorado', data),
    },
    facturacion: {
        listTiposDocumento: () => GenericRequest.get('facturacion/list-tipos-documento'),
        listCuentas: (params = {}) => GenericRequest.get('facturacion/list-cuentas', params),
        cliente: {
            search: (nit) => GenericRequest.post('facturacion/search-cliente', { nit }),
            create: (data) => GenericRequest.post('facturacion/create-cliente', data),
        },
        issue: (data) => GenericRequest.post('facturacion/issue', data),
        cancel: (movimiento_id, data) => GenericRequest.post('facturacion/cancel', { movimiento_id, ...data }),
        lastValidas: () => GenericRequest.get('facturacion/last-validas'),
        printables: (params = {}) => GenericRequest.get('facturacion/printables', params),
        cancellables: (params = {}) => GenericRequest.get('facturacion/cancellables', params),
        listDeudas: (params = {}) => GenericRequest.post('facturacion/list-deudas', params),
        payDeuda: (movimiento_id) => GenericRequest.post('facturacion/pay-deuda', { movimiento_id }),
    },
    reportes: {
        consolidado: {
            porUnidadCuenta: (params) =>
                GenericRequest.post('reporte-movimientos/consolidado-por-unidad-cuenta', params),
            porConcepto: (params) => GenericRequest.post('reporte-movimientos/consolidado-por-concepto', params),
            porUnidad: (params) => GenericRequest.post('reporte-movimientos/consolidado-por-unidad', params),
            institucional: (params) => GenericRequest.post('reporte-movimientos/consolidado-institucional', params),
        },
        recibos: {
            porCajero: (params) => GenericRequest.post('reporte-movimientos/recibos-por-cajero', params),
            porCuenta: (params) => GenericRequest.post('reporte-movimientos/recibos-por-cuenta', params),
            anulados: (params) => GenericRequest.post('reporte-movimientos/recibos-anulados', params),
            detallados: (params) => GenericRequest.post('reporte-movimientos/recibos-detallados', params),
            deTiposEstudiante: (params) =>
                GenericRequest.post('reporte-movimientos/recibos-de-tipos-estudiante', params),
        },
        cobroComisionBancaria: (params) => GenericRequest.post('reporte-movimientos/cobro-comision-bancaria', params),
        distribucionAportes: (params) => GenericRequest.post('reporte-movimientos/distribucion-aportes', params),
        preValores: (params) => GenericRequest.post('reporte-movimientos/pre-valorados', params),

        libroVentas: (params) => GenericRequest.post('reporte-movimientos/libro-ventas', params),
        facturas: {
            porCajero: (params) => GenericRequest.post('reporte-movimientos/facturas-por-cajero', params),
            detallados: (params) => GenericRequest.post('reporte-movimientos/facturas-detallados', params),
        },

        // Servicios para filtros de reportes
        listRubros: (params = {}) => GenericRequest.get('clasificador/list-rubros', params),
        listConceptos: (params = {}) => GenericRequest.get('clasificador/list-conceptos', params),
    },
    filter: {
        listEstructuras: (params = {}) => GenericRequest.get('recaudaciones/list-estructuras', params),
        listUsuarios: (params = {}) => GenericRequest.get('recaudaciones/list-usuarios', params),
        listUnidades: (params = {}) => GenericRequest.get('recaudaciones/list-unidades', params),
        listCuentas: (params = {}) => GenericRequest.get('recaudaciones/list-cuentas', params),
    },
}

export default RecaudacionesServices
