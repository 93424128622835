import Header from 'Component/Header'
import DatosPersona from './components/DatosPersona/DatosPersona'
import { Button } from 'components/modular/Button'
import { useEffect, useRef, useState } from 'react'
import DatosRecibo from './components/DatosRecibo'
import RecaudacionesServices from 'services/recaudaciones.service'
import useHttpToast from 'hooks/useHttpToast'
import LastVenta from './components/LastVenta'
import { clean, pick } from 'components/modular/utils'
import CobroDeudas from './components/CobroDeudas'
import DatosCuenta from './components/DatosCuenta'
import { useSelector } from 'react-redux'
import useRecibo from 'modules/recaudaciones/hooks/useRecibo'
import ControlCierre from 'modules/recaudaciones/components/ControlCierre'

export default function VentaValores() {
    const { showHttpToast } = useHttpToast()
    const { sell } = useRecibo()
    const inputRefs = useRef({})
    const datosReciboRef = useRef(null)
    const datosPersonaRef = useRef(null)
    const datosCuentaRef = useRef(null)
    const { user, permissions } = useSelector((state) => state.auth)
    const [totales, setTotales] = useState({ cuenta: 0, cuentas_adicionales: 0 })
    const [lastVentas, setLastVentas] = useState([])
    const [lastPersona, setLastPersona] = useState(null)
    const [persona, setPersona] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [preimpreso, setPreimpreso] = useState('1000000')
    const [isEnable, setIsEnable] = useState({ sellValores: false, collectDeudas: false })

    useEffect(() => {
        loadNextRecibo()
        loadLastVentas()
    }, [])
    useEffect(() => {
        const canSellValores = permissions.sellValores
        const canCollectDeudas = permissions.collectDeudasValores
        setIsEnable((prev) => ({ ...prev, sellValores: !!canSellValores, collectDeudas: !!canCollectDeudas }))
    }, [user])

    /** Obtiene los datos del siguiente recibo. */
    const loadNextRecibo = async () => {
        const { status, data } = await RecaudacionesServices.ventaValores.nextRecibo()
        if (status === 200) {
            setLastPersona(data.data.ultima_persona)
            datosReciboRef.current?.setRecibo({ numero_recibo: data.data.numero_recibo })
            setPreimpreso(data.data.preimpreso)
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }
    /** Obtiene las ultimas ventas validas. */
    const loadLastVentas = async () => {
        const { status, data } = await RecaudacionesServices.ventaValores.lastValidas()
        if (status === 200) {
            setLastVentas(data.data)
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }

    /** Maneja la selección de una persona existente o no. */
    const handleSelectPersona = async (personaId) => {
        setPersona(personaId ? { id: personaId } : null)
    }
    /** Maneja el envío de datos del formulario. */
    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)
        await handleSell()
        setSubmitting(false)
    }
    /** Maneja la venta de valores. */
    const handleSell = async () => {
        if (!datosPersonaRef.current || !datosReciboRef.current || !datosCuentaRef.current) {
            return
        }
        const hasErrorsPersona = datosPersonaRef.current.validatePersona()
        const hasErrorsRecibo = datosReciboRef.current.validateRecibo()
        const hasErrorsCuenta = datosCuentaRef.current.validateCuenta()
        if (hasErrorsPersona || hasErrorsRecibo || hasErrorsCuenta) {
            return
        }
        const persona = datosPersonaRef.current.getPersona()
        const cuenta = datosCuentaRef.current.getCuenta()
        const recibo = datosReciboRef.current.getRecibo()
        console.log(persona)
        console.log(cuenta)
        console.log(recibo)

        const bodyData = {
            cuenta_id: cuenta.id,
            cantidad: cuenta.cantidad,
            precio: cuenta.precio,
            cuentas_adicionales: cuenta.cuentas_adicionales.map((c) =>
                pick(c, ['id', 'precio', 'cantidad', 'padre_id']),
            ),
            preimpreso,
            observacion: recibo.observacion,
            cantidad_recibos: recibo.cantidad,
        }
        if (persona.id) {
            // Si la persona existe solo se envia su ID
            bodyData.persona_id = persona.id
        } else {
            // Si la persona no existe se envia todos los datos para registrarlo
            const personaData = clean(
                pick(persona, [
                    'apellido_1',
                    'apellido_2',
                    'nombre_1',
                    'nombre_2',
                    'documento',
                    'complemento_documento',
                    'fecha_nacimiento',
                ]),
            )
            const { status, data } = await RecaudacionesServices.ventaValores.createPersona(personaData)
            if (status === 200) {
                bodyData.persona_id = data.data.id
            } else {
                showHttpToast(status, { detail: data.message })
                return
            }
        }
        console.log(bodyData)
        await sell(bodyData, (data) => {
            setLastPersona(persona)
            datosReciboRef.current.setRecibo({ numero_recibo: data.numero_recibo })
            setPreimpreso(data.preimpreso)
            loadLastVentas()
            resetForm()
        })
    }
    /**
     * Maneja el pago exitoso de una deuda.
     * @param {object} data Datos del resultado del pago de la deuda.
     */
    const handleSuccessPayDeuda = async (data) => {
        setLastPersona(persona)
        datosReciboRef.current.setRecibo({ numero_recibo: data.numero_recibo })
        setPreimpreso(data.preimpreso)
        loadLastVentas()
        inputRefs.current['persona.documento']?.focus()
    }
    /**
     * Maneja el caducar exitoso de una deuda.
     * @param {object} data Datos del resultado de caducar la deuda.
     */
    const handleSuccessExpireDeuda = async () => {
        inputRefs.current['persona.documento']?.focus()
    }

    /** Restablece el formulario a sus valores iniciales. */
    const resetForm = () => {
        datosPersonaRef.current?.resetPersona()
        datosCuentaRef.current?.resetCuenta()
        datosReciboRef.current?.setRecibo({
            cantidad: '1',
            observacion: '',
        })
        inputRefs.current['persona.documento']?.focus()
    }
    /** Función que almacena las referencias de los inputs. */
    const saveRef = (el, name) => {
        inputRefs.current[name] = el
    }

    return (
        <div className='h-100 d-flex flex-column align-items-center'>
            <Header title='VENTA DE VALORES' />
            <ControlCierre>
                <div className='container shadow-sm p-4 bg-body rounded'>
                    <DatosPersona
                        innerRef={datosPersonaRef}
                        inputRefs={inputRefs}
                        saveRef={saveRef}
                        lastPersona={lastPersona}
                        onSelectPersona={handleSelectPersona}
                        className='mb-3'
                    />
                    {isEnable.collectDeudas && persona && (
                        <CobroDeudas
                            persona={persona}
                            preimpreso={preimpreso}
                            setPreimpreso={setPreimpreso}
                            onSuccessPay={handleSuccessPayDeuda}
                            onSuccessExpire={handleSuccessExpireDeuda}
                            className='mb-3'
                        />
                    )}
                    {isEnable.sellValores && (
                        <form
                            onSubmit={handleSubmit}
                            onKeyDown={(e) => {
                                if (e.target.tagName !== 'BUTTON') {
                                    e.key === 'Enter' && e.preventDefault()
                                }
                            }}
                            noValidate
                        >
                            <DatosCuenta
                                innerRef={datosCuentaRef}
                                inputRefs={inputRefs}
                                saveRef={saveRef}
                                updateTotales={setTotales}
                                className='mb-3'
                            />
                            <DatosRecibo
                                innerRef={datosReciboRef}
                                inputRefs={inputRefs}
                                totales={totales}
                                preimpreso={preimpreso}
                                setPreimpreso={setPreimpreso}
                                className='mb-3'
                            />
                            <div className='bg-blue py-3 text-center rounded'>
                                <Button
                                    ref={(el) => saveRef(el, 'buttonTerminar')}
                                    type='submit'
                                    startIcon={<span className='bi-floppy' />}
                                    loading={submitting}
                                >
                                    Grabar
                                </Button>
                                <Button
                                    variant='secondary'
                                    onClick={resetForm}
                                    className='ms-3'
                                    startIcon={<span className='bi-x-lg' />}
                                    disabled={submitting}
                                >
                                    Limpiar
                                </Button>
                            </div>
                        </form>
                    )}
                </div>
                <LastVenta recibos={lastVentas} reloadRecibos={loadLastVentas} />
            </ControlCierre>
        </div>
    )
}
