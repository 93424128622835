import { fuenteFondoOptions, initialPagination } from 'data/constants'
import useFilters, { buildFilters, objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'components/modular/Button'
import { Checkbox } from 'components/modular/Checkbox'
import { DataTable } from 'components/modular/DataTable'
import { FieldGroup } from 'components/modular/FieldGroup'
import { Input } from 'components/modular/Input'
import { Confirm } from 'components/modular/Modal'
import { Select } from 'components/modular/Select'
import { useEffect, useMemo, useRef, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContabilidadServices from 'services/contabilidad.service'
import PresupuestosServices from 'services/presupuestos.service'
import { formatCurrency, formatDate } from 'utils'
import { verifyAccess } from 'utils/verifyAccess'

const tiposFondo = {
    '': '',
    R: 'ROTATORIO',
    A: 'AVANCE',
}
const filterOptions = {
    equal: ['tipo', 'fuente_fondo', 'numero'],
    contain: ['beneficiario', 'actividad'],
    bool: ['activo'],
    betweenDate: ['fecha_creacion'],
}
const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const formattedCurrentDate = formatDate(currentDate, 'Y-m-d')
const initialExtraFilters = { fecha_inicio: `${currentYear}-01-01`, fecha_fin: formattedCurrentDate }

const popoverFinalizado = (
    <Popover>
        <Popover.Header as='h3'>Verificado</Popover.Header>
        <Popover.Body className='py-2'>Indica si el beneficiario terminó de modificar el fondo.</Popover.Body>
    </Popover>
)
const popoverActivo = (
    <Popover>
        <Popover.Header as='h3'>Activo</Popover.Header>
        <Popover.Body className='py-2'>Indica si el registro está habilitado para ser modificado.</Popover.Body>
    </Popover>
)
const tipoFilterElement = ({ value, filterCallback }) => (
    <Select
        options={[
            { label: 'ROTATORIO', value: 'R' },
            { label: 'AVANCE', value: 'A' },
        ]}
        value={value}
        onChange={(e) => filterCallback(e.target.value)}
        style={{ minWidth: '8.5rem' }}
        placeholder='TODOS'
    />
)
const numeroFilterElement = ({ value, filterCallback }) => (
    <Input value={value} onChange={(e) => filterCallback(e.target.value)} type='integer' />
)
const fuenteFondoFilterElement = ({ value, filterCallback }) => (
    <Select
        options={fuenteFondoOptions}
        value={value}
        onChange={(e) => filterCallback(e.target.value)}
        style={{ minWidth: '10rem' }}
        placeholder='TODOS'
    />
)
const checkboxFilterElement = ({ value, filterCallback }) => (
    <div className='d-flex justify-content-center'>
        <Checkbox
            value={value}
            onChange={(e) => filterCallback(e.target.value)}
            triState
            style={{ fontSize: '1.5rem' }}
        />
    </div>
)
const baseColumns = [
    {
        field: 'tipo',
        header: 'Tipo',
        cell: ({ value }) => tiposFondo[value],
        cellClassName: 'text-center',
        filter: true,
        filterElement: tipoFilterElement,
        sortable: true,
    },
    {
        field: 'numero',
        header: 'Nro.',
        cell: ({ value }) => value ?? '-',
        cellClassName: 'text-center',
        filter: true,
        filterElement: numeroFilterElement,
        style: { width: '5rem' },
    },
    { field: 'beneficiario', header: 'Beneficiario', filter: true, sortable: true },
    { field: 'actividad', header: 'Unidad', filter: true },
    {
        field: 'monto_asignado',
        header: 'Monto Asignado',
        headerClassName: 'text-nowrap',
        cell: ({ value }) => formatCurrency(value),
        cellClassName: 'text-end',
    },
    {
        field: 'fuente_fondo',
        header: 'Fondos',
        cellClassName: 'text-center',
        filter: true,
        filterElement: fuenteFondoFilterElement,
        sortable: true,
    },
    {
        field: 'fecha_creacion',
        header: 'Fecha Registro',
        headerClassName: 'text-nowrap',
        cell: ({ value }) => formatDate(value, 'd/m/Y'),
        cellClassName: 'text-center',
        sortable: true,
    },
    {
        field: 'fecha_finalizado',
        header: (
            <span className='text-nowrap'>
                Verificado
                <OverlayTrigger placement='top' overlay={popoverFinalizado}>
                    <span className='bi-info-circle-fill ms-1 text-primary lh-1' style={{ fontSize: '1.1rem' }} />
                </OverlayTrigger>
            </span>
        ),
        cell: ({ value }) => (value ? formatDate(value, 'd/m/Y') : 'No'),
        cellClassName: 'text-center',
        filter: true,
        filterElement: checkboxFilterElement,
    },
    {
        field: 'activo',
        header: (
            <span className='text-nowrap'>
                Activo
                <OverlayTrigger placement='top' overlay={popoverActivo}>
                    <span className='bi-info-circle-fill ms-1 text-primary lh-1' style={{ fontSize: '1.1rem' }} />
                </OverlayTrigger>
            </span>
        ),
        cell: ({ value }) => (value ? 'Si' : 'No'),
        cellClassName: 'text-center',
        filter: true,
        filterElement: checkboxFilterElement,
    },
]

export default function ListAllFondo({ tipo = 'CONTABILIDAD' }) {
    const tableRef = useRef(null)
    const { showHttpToast } = useHttpToast()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const { filters, handlePage, handlePageSize, setFilters, reloadFilters, resetFilters, handleSort } = useFilters({
        initialFilters: { filter: { fecha_finalizado: { isnt: 'NULL' } } },
    })
    const [extraFilters, setExtraFilters] = useState(initialExtraFilters)
    const [show, setShow] = useState({ increase: false })
    const [fondos, setFondos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState({ generatePdf: false, generateXlsx: false })
    const [selection, setSelection] = useState([])

    useEffect(() => {
        const loadList = async () => {
            const _filters =
                ((tipo === 'CONTABILIDAD' && !verifyAccess('AdministradorContabilidad')) ||
                    (tipo === 'PRESUPUESTOS' && !verifyAccess('AdministradorPresupuestos'))) &&
                user?.das.length
                    ? { ...filters, filter: { ...filters.filter, da_id: { in: user.das.map((da) => da.id) } } }
                    : filters
            const params = objectToQueryString(_filters)
            const { status, data } = await ContabilidadServices.fondo.list(params)
            if (status === 200) {
                setFondos(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadList()
    }, [filters, tipo])
    useEffect(() => {
        selection.length && setSelection([])
        resetFilters()
        tableRef.current?.resetFilters()
    }, [tipo])

    const openModal = (key) => setShow((prevShow) => ({ ...prevShow, [key]: true }))
    const closeModal = (key) => setShow((prevShow) => ({ ...prevShow, [key]: false }))

    const handleAction = (action, rowData) => {
        if (action === 'view') {
            navigate(`/${tipo.toLowerCase()}/fondo/${rowData.id}`)
        } else if (action === 'increase') {
            setSelected(rowData)
            openModal('increase')
        }
    }

    const optionsCellTemplate = ({ data }) => {
        return (
            <div className='text-nowrap'>
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        handleAction('view', data)
                    }}
                    size='sm'
                    className='me-2'
                    title='Información'
                >
                    <span className='bi-eye' />
                </Button>
                {verifyAccess(['AdministradorContabilidad', 'AdministradorContabilidadDA'], 'OR') && (
                    <>
                        <Button
                            variant='success'
                            size='sm'
                            title='Generar Excel'
                            className='me-2'
                            onClick={() => generateFondoXlsx(data.id)}
                        >
                            <span className='bi-file-earmark-excel' />
                        </Button>
                        <Button
                            variant='secondary'
                            size='sm'
                            title='Revertir verificación'
                            disabled={!(data.activo && data.fecha_finalizado)}
                            onClick={() => handleAction('increase', data)}
                        >
                            <span className='bi-unlock' />
                        </Button>
                    </>
                )}
            </div>
        )
    }
    const columns = useMemo(() => {
        if (tipo === 'PRESUPUESTOS') {
            return [
                { id: 'selector', selector: true },
                ...baseColumns,
                {
                    field: 'id',
                    header: 'Opciones',
                    cell: optionsCellTemplate,
                    cellClassName: 'text-center',
                },
            ]
        } else {
            return [
                ...baseColumns,
                {
                    field: 'id',
                    header: 'Opciones',
                    cell: optionsCellTemplate,
                    cellClassName: 'text-center',
                },
            ]
        }
    }, [tipo])

    const clearSelected = () => setSelected(null)

    const reverseEndFondo = async () => {
        if (selected) {
            const { status, data } = await ContabilidadServices.fondo.reverseEnd(selected.id)
            showHttpToast(status, { detail: data.message })
            if (status === 200) reloadFilters()
            closeModal('increase')
        }
    }
    const deactivateFondos = async () => {
        if (selection.length) {
            const { status, data } = await ContabilidadServices.fondo.deactivate(selection.map((s) => s.id))
            showHttpToast(status, { detail: data.message })
            if (status === 200) reloadFilters()
            closeModal('deactivate')
        }
    }
    const generatePdf = () => {
        if (tipo === 'PRESUPUESTOS') {
            generatePrevisionPdf()
        } else {
            generateFondosPdf()
        }
    }
    const generateFondosPdf = async () => {
        setLoading((prev) => ({ ...prev, generatePdf: true }))
        const _filters =
            !verifyAccess('AdministradorContabilidad') && user?.das.length
                ? { ...filters, filter: { ...filters.filter, da_id: { in: user.das.map((da) => da.id) } } }
                : filters
        const fechaInicio = extraFilters.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = extraFilters.fecha_fin || formattedCurrentDate
        const params = objectToQueryString({ ..._filters, fechas: { fecha_inicio: fechaInicio, fecha_fin: fechaFin } })
        const { status, data } = await ContabilidadServices.fondo.reportPdf(params)
        if (status === 200) {
            const pdfContent = data
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_blank')
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al obtener el PDF' })
        }
        setLoading((prev) => ({ ...prev, generatePdf: false }))
    }
    const generatePrevisionPdf = async () => {
        setLoading((prev) => ({ ...prev, generatePdf: true }))
        const bodyParams = { fondo_id: selection.map((s) => s.id) }
        const { status, data } = await PresupuestosServices.fondo.previsionPdf(bodyParams)
        if (status === 200) {
            const pdfContent = data
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_blank')
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al obtener el PDF' })
        }
        setLoading((prev) => ({ ...prev, generatePdf: false }))
    }
    const generatePrevisionXlsx = async () => {
        setLoading((prev) => ({ ...prev, generateXlsx: true }))
        const id = selection.map((s) => s.id)
        const { status, data } = await PresupuestosServices.fondo.previsionXlsx({ fondo_id: id })

        if (status === 200) {
            // Crear un enlace temporal para realizar la descarga
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')

            // Crear una URL para el blob
            link.href = URL.createObjectURL(blob)

            // Nombre del archivo de descarga
            link.download = 'reporte.xlsx'

            // Simular un clic en el enlace para descargar el archivo
            link.click()

            // Liberar el objeto URL
            URL.revokeObjectURL(link.href)
        } else {
            showHttpToast(status, { detail: data.message || 'Ocurrió un error al obtener el archivo' })
        }
        setLoading((prev) => ({ ...prev, generateXlsx: false }))
    }
    const generateFondoXlsx = async (id) => {
        const { status, data } = await ContabilidadServices.fondo.xlsx(id)

        if (status === 200) {
            // Crear un enlace temporal para realizar la descarga
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')

            // Crear una URL para el blob
            link.href = URL.createObjectURL(blob)

            // Nombre del archivo de descarga
            link.download = 'fondo.xlsx'

            // Simular un clic en el enlace para descargar el archivo
            link.click()

            // Liberar el objeto URL
            URL.revokeObjectURL(link.href)
        } else {
            showHttpToast(status, { detail: data.message || 'Ocurrió un error al obtener el archivo' })
        }
    }

    const handleChangeExtraFilters = (e) => {
        const _extra = { ...extraFilters, [e.target.name]: e.target.value }
        setExtraFilters(_extra)
        const fechaInicio = _extra.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = _extra.fecha_fin || formattedCurrentDate
        setFilters({
            ...filters,
            filter: {
                ...filters.filter,
                fecha_creacion: { '>': `${fechaInicio} 00:00:00`, '<': `${fechaFin} 23:59:59` },
            },
        })
    }
    const handleFilter = (filterValues) => {
        const fechaInicio = extraFilters.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = extraFilters.fecha_fin || formattedCurrentDate
        const fecha_creacion = [fechaInicio, fechaFin]
        const _filters = { filter: buildFilters({ ...filterValues, fecha_creacion }, filterOptions) }
        if (typeof filterValues.fecha_finalizado === 'boolean') {
            if (filterValues.fecha_finalizado) {
                _filters.filter.fecha_finalizado = { isnt: 'NULL' }
            } else {
                _filters.filter.fecha_finalizado = { is: 'NULL' }
            }
        }
        if (filters) {
            if (filters.sort) _filters.sort = filters.sort
            if (filters.pageSize) _filters.pageSize = filters.pageSize
        }
        setFilters(_filters)
    }

    const handleSelectionChange = (s) => setSelection(s)

    const propsDataTable = (() => {
        if (tipo === 'PRESUPUESTOS') {
            return {
                selectionMode: 'multiple',
                selectionOn: 'row',
                selection,
                onSelectionChange: handleSelectionChange,
                isRowSelectable: (rowData) => !!rowData.fecha_finalizado,
            }
        }
        return {}
    })()

    return (
        <div className='h-100 p-4'>
            <div className='h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Lista de Fondos</h2>
                </div>
                <div className='p-3'>
                    <div className='d-flex align-items-center gap-3 px-3 py-2 bg-body-tertiary'>
                        <span className='fw-bold'>Fecha:</span>
                        <FieldGroup label='desde' inline className='mb-0'>
                            <Input
                                type='date'
                                name='fecha_inicio'
                                value={extraFilters.fecha_inicio}
                                onChange={handleChangeExtraFilters}
                            />
                        </FieldGroup>
                        -
                        <FieldGroup label='hasta' inline className='mb-0'>
                            <Input
                                type='date'
                                name='fecha_fin'
                                value={extraFilters.fecha_fin}
                                onChange={handleChangeExtraFilters}
                            />
                        </FieldGroup>
                        <span className='ms-auto' />
                        {tipo === 'PRESUPUESTOS' && (
                            <>
                                <Button
                                    variant='danger'
                                    onClick={() => openModal('deactivate')}
                                    loading={loading.generateXlsx}
                                    disabled={selection.length === 0 || loading.generatePdf || loading.generateXlsx}
                                    startIcon={<span className='bi-list-ul' />}
                                >
                                    Desactivar Seleccionados
                                </Button>
                                <Button
                                    variant='success'
                                    onClick={generatePrevisionXlsx}
                                    loading={loading.generateXlsx}
                                    disabled={selection.length === 0 || loading.generatePdf}
                                    startIcon={<span className='bi-file-earmark-excel' />}
                                >
                                    Generar EXCEL
                                </Button>
                            </>
                        )}
                        <Button
                            onClick={generatePdf}
                            loading={loading.generatePdf}
                            disabled={tipo === 'PRESUPUESTOS' && (selection.length === 0 || loading.generateXlsx)}
                            startIcon={<span className='bi-file-earmark-pdf' />}
                        >
                            Generar PDF
                        </Button>
                    </div>
                    <DataTable
                        innerRef={tableRef}
                        columns={columns}
                        values={fondos}
                        lazy
                        pagination
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        totalItems={pagination.totalRecords}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        pageSizeOptions={[10, 25, 50]}
                        filterable
                        onFilter={handleFilter}
                        filterDelay={1000}
                        defaultAlignHeader='center'
                        onSort={handleSort}
                        rowKeyField='id'
                        defaultFilters={{ fecha_finalizado: true }}
                        {...propsDataTable}
                    />
                </div>
            </div>
            <Confirm
                show={show.increase}
                onHide={() => closeModal('increase')}
                onReject={() => closeModal('increase')}
                onAccept={reverseEndFondo}
                onExited={clearSelected}
                closeOnBackdrop={false}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    ¿Esta seguro de <span className='text-primary'>REVERTIR LA VERIFICACION</span> del Fondo
                    {selected?.tipo === 'R' ? ` Rotatorio N° ${selected.numero}` : ' en Avance'}?
                </span>
            </Confirm>
            <Confirm
                show={show.deactivate}
                onHide={() => closeModal('deactivate')}
                onReject={() => closeModal('deactivate')}
                onAccept={deactivateFondos}
                closeOnBackdrop={false}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    ¿Esta seguro de <span className='text-primary'>DESACTIVAR</span> los fondos seleccionados?
                </span>
            </Confirm>
        </div>
    )
}
