import { Button } from 'components/modular/Button'
import { DataTable } from 'components/modular/DataTable'
import { Input } from 'components/modular/Input'
import { formatCurrency } from 'components/modular/utils'
import { useMemo } from 'react'

export default function CuentasAdicionales({ cuentas, setCuentas, onClickAdd, loading, ...props }) {
    const handleChange = (e, rowIndex) => {
        setCuentas((prev) =>
            prev.map((c, index) => (rowIndex === index ? { ...c, [e.target.name]: e.target.value } : c)),
        )
    }
    const removeRow = (id) => {
        setCuentas((prev) => prev.filter((c) => c.id !== id))
    }
    const cantidadCellTemplate = ({ value, rowIndex }) => (
        <Input
            type='number'
            name='cantidad'
            value={value}
            onChange={(e) => handleChange(e, rowIndex)}
            onFocus={(e) => e.target.select()}
            className='text-end'
            size='sm'
            isInvalid={!(value > 0)}
        />
    )
    const precioCellTemplate = ({ value, rowIndex }) => (
        <Input
            type='currency'
            name='precio'
            value={value}
            onChange={(e) => handleChange(e, rowIndex)}
            onFocus={(e) => e.target.select()}
            className='text-end'
            size='sm'
            isInvalid={!(value > 0)}
        />
    )
    const descuentoCellTemplate = ({ data, value, rowIndex }) => (
        <Input
            type='currency'
            name='descuento'
            value={value}
            onChange={(e) => handleChange(e, rowIndex)}
            onFocus={(e) => e.target.select()}
            className='text-end'
            size='sm'
            isInvalid={value >= data.precio * data.cantidad}
        />
    )
    const optionsCellTemplate = ({ data }) => (
        <div className='d-flex justify-content-center'>
            <Button variant='danger' size='sm' onClick={() => removeRow(data.id)}>
                <span className='bi-trash' />
            </Button>
        </div>
    )

    const columns = useMemo(
        () => [
            {
                id: 'options',
                cell: optionsCellTemplate,
                style: { width: '3.5rem' },
            },
            {
                field: 'codigo',
                header: 'Código',
                headerClassName: 'fw-medium',
                style: { width: '10rem' },
            },
            {
                field: 'nombre',
                header: 'Nombre',
                headerClassName: 'fw-medium',
            },
            {
                field: 'unidad',
                header: 'Unidad',
                headerClassName: 'fw-medium',
            },
            {
                field: 'cantidad',
                header: 'Cantidad',
                headerClassName: 'fw-medium',
                cell: cantidadCellTemplate,
                cellClassName: 'text-center',
                style: { width: '7rem' },
            },
            {
                field: 'precio',
                header: 'Precio Unit. (Bs)',
                headerClassName: 'fw-medium',
                cell: (props) => (props.data.tipo_monto === 'V' ? precioCellTemplate(props) : props.value),
                cellClassName: 'text-end',
                style: { width: '9rem' },
            },
            {
                field: 'descuento',
                header: 'Descuento (Bs)',
                headerClassName: 'fw-medium',
                cell: descuentoCellTemplate,
                cellClassName: 'text-end',
                style: { width: '8rem' },
            },
            {
                id: 'precio_total',
                header: 'Importe (Bs)',
                headerClassName: 'fw-medium',
                cell: ({ data }) => formatCurrency((data.precio * data.cantidad) - data.descuento),
                cellClassName: 'text-end',
                style: { width: '10rem' },
            },
        ],
        [],
    )

    return (
        <div {...props}>
            <div
                className='d-flex align-items-center bg-blue rounded text-white select-none mb-3'
                style={{ padding: '.75rem 1rem' }}
            >
                <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                    CUENTAS ADICIONALES
                </span>
                <Button
                    variant='secondary'
                    startIcon={<span className='bi-plus-lg' />}
                    onClick={onClickAdd}
                    size='sm'
                    className='ms-auto'
                    loading={loading}
                >
                    Adicionar
                </Button>
            </div>
            <div className='mx-3'>
                {loading ? (
                    <div className='text-center'>
                        <div className='spinner-border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </div>
                    </div>
                ) : cuentas.length ? (
                    <DataTable
                        values={cuentas}
                        columns={columns}
                        defaultAlignHeader='center'
                        tableBodyStyle={{ fontSize: '.875rem' }}
                        emptyMessage={<div className='fw-medium text-center'>Sin cuentas adicionales</div>}
                    />
                ) : (
                    <div className='fw-medium text-center'>Sin cuentas adicionales</div>
                )}
            </div>
        </div>
    )
}
